export type ModalsStoreType = Record<ModalKeysEnum, boolean>;

export enum ModalKeysEnum {
  DELETE_COMPONENT = 'deleteComponent',
  DELETE_PROJECT = 'deleteProject',
  DELETE_TEAM = 'deleteTeam',
  CREATE_PROJECT = 'createProject',
  UPGRADE_PLAN = 'upgradePlan',
  INVITE_CONTRIBUTOR = 'inviteContributor',
  SUBSCRIPTION = 'subscription',
  CHOOSE_SUBSCRIPTION = 'chooseSubscription',
  CANCEL_SUBSCRIPTION = 'cancelSubscription',
  UPDATE_BILLING_INFO = 'updateBillingInfo',
  UPDATE_PAYMENT_METHOD = 'updatePaymentMethod',
  USER_QUALIFICATION = 'userQualification',
  CREATE_TEAM_NAMING = 'createTeamNaming',
  CREATE_TEAM_CONTRIBUTORS = 'createTeamContributors',
  ADD_TEAM_CONTRIBUTORS = 'addTeamContributors',
  REMOVE_TEAM_CONTRIBUTORS = 'removeTeamContributors',
  CHOOSE_TEAM_PLAN = 'chooseTeamPlan',
  CHOOSE_TEAM_SEAT_NUMBER = 'chooseTeamSeatNumber',
  TEAM_PLAN_PAYMENT = 'teamPlanPayment',
}

import React, { useEffect } from 'react';
import { match } from 'react-router';
import classnames from 'classnames';
import noStylesheetIcon from 'assets/no-stylesheet.png';
import StylesheetViewer from 'components/Molecules/StylesheetViewer';
import StylesheetSection from 'components/Molecules/StylesheetSection';
import styles from './Stylesheet.module.scss';
import ProjectDetailLayout from 'components/Layout/ProjectDetailLayout';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useParams } from 'react-router-dom';
import { ProjectType } from 'modules/projects/types';
import { StylesheetType } from 'modules/stylesheet/types';
import { ProjectUuidMatchParams } from 'src/routes';
import { getProjectStylesheetRequestCreator } from 'modules/stylesheet';

export interface IStylesheetProps {
  project: ProjectType;
  stylesheet: StylesheetType | null;
  isUpdateStylesheetLoading: boolean;
  isStylesheetEmpty: boolean;
  isGetStylesheetLoading: boolean;
  fetchStylesheet: typeof getProjectStylesheetRequestCreator;
  match: match<ProjectUuidMatchParams>;
}

const Stylesheet: React.FunctionComponent<IStylesheetProps> = ({
  project,
  stylesheet,
  fetchStylesheet,
  isStylesheetEmpty,
  isUpdateStylesheetLoading,
  isGetStylesheetLoading,
}) => {
  const { id: projectId } = useParams();

  useEffect(() => {
    fetchStylesheet({ projectUuid: projectId });
  }, []);

  return (
    <ProjectDetailLayout projectId={projectId}>
      <div className={styles.stylesheetSection}>
        {isGetStylesheetLoading && (
          <div
            className={classnames(
              isStylesheetEmpty && styles.middleLoader,
              !isStylesheetEmpty && styles.littleLoader,
            )}
          >
            <CircularProgress size={30} />
          </div>
        )}
        {!isStylesheetEmpty && <StylesheetSection />}
        {!isGetStylesheetLoading && isStylesheetEmpty && (
          <div className={styles.emptyStateStylesheet}>
            <img alt="" src={noStylesheetIcon} className={styles.imageExportComponent} />
            <p className={styles.description}>
              Your stylesheet is empty yet. You can use it to add colors, text-colors, typos to your
              design system. They will be used in your components code. For example, you will use a
              variable <strong className={styles.descriptionEmphasis}>$blue-primary </strong>
              instead of a hardcoded{' '}
              <strong className={styles.descriptionEmphasis}>rgba(31,34,88,1)</strong>
            </p>
          </div>
        )}
      </div>
      <StylesheetViewer
        project={project}
        stylesheet={stylesheet}
        isLoading={isGetStylesheetLoading || isUpdateStylesheetLoading}
      />
    </ProjectDetailLayout>
  );
};

export default Stylesheet;

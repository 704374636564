import { takeLatest, call, put, select, fork } from 'redux-saga/effects';
import { withLoader } from 'modules/loading';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import { selectToken, withAuthentication } from 'modules/authentication';
import { getComponentFonts, updateComponentProps } from 'services/api';
import {
  ComponentsStoreType,
  ComponentType,
  NormalizedComponentType,
} from 'modules/components/types';
import { OverlayStoreType } from 'redux/types';
import { denormalizeComponent, normalizeComponent } from 'services/apiNormalizer';
import { getType, createAction, ActionType, Reducer } from 'typesafe-actions';
import * as Sentry from '@sentry/react';
import { displayErrorToaster } from 'modules/apiError';
import { getAssetsSuccessCreator } from 'modules/assets';
import { push } from 'connected-react-router';
import { NormalizedComponentInstanceType } from 'modules/componentInstances/types';
import { AssetType } from 'modules/assets/types';
import {
  fontChecker,
  isAGoogleFont,
  loadGoogleFont,
  loadGoogleMaterialIcons,
} from 'services/fontService';
import { mergeComponent } from 'services/factory/componentFactory';
import {
  getComponentSetComponentSuccessCreator,
  getComponentSetSuccessCreator,
} from 'modules/componentSets';
import { delay } from 'redux-saga';
import { NormalizedLayerType } from 'modules/layers/types';
import { ComponentPropsType, NormalizedComponentPropsType } from 'modules/props/types';
import { compileComponentFragmentRequestCreator } from 'modules/layers';

const initialState: ComponentsStoreType = {
  map: {},
};

// Actions Creators
export const updateComponentPropsRequestCreator = createAction(
  'COMPONENTS/UPDATE_COMPONENT_PROPS.REQUEST',
)<{
  componentUuid: string;
  props: ComponentPropsType;
  layerToRecompile: string | null;
}>();

export const getComponentsSuccessCreator = createAction('COMPONENTS/GET_COMPONENTS.SUCCESS')<{
  components: Record<string, NormalizedComponentType>;
  componentInstances: Record<string, NormalizedComponentInstanceType>;
  assets: Record<string, AssetType>;
  layer: Record<string, NormalizedLayerType>;
  props: Record<string, NormalizedComponentPropsType>;
}>();

export const getComponentRequestCreator = createAction('COMPONENTS/GET_COMPONENT.REQUEST')<{
  componentUuid: string;
  projectUuid: string;
}>();

export const getComponentFontsRequestCreator = createAction(
  'COMPONENTS/GET_COMPONENT_FONTS.REQUEST',
)<{
  componentUuid: string;
  projectUuid: string;
}>();

export const getComponentFontsSuccessCreator = createAction(
  'COMPONENTS/GET_COMPONENT_FONTS.SUCCESS',
)<{
  componentUuid: string;
  missingFonts: string[];
}>();

type ComponentActions = ActionType<
  | typeof getComponentsSuccessCreator
  | typeof getComponentSetSuccessCreator
  | typeof getComponentSetComponentSuccessCreator
  | typeof getAssetsSuccessCreator
  | typeof getComponentFontsSuccessCreator
>;

// Selectors
export const selectComponentByUuid = (state: OverlayStoreType, ComponentUuid: string) => {
  return denormalizeComponent(
    state.components.map[ComponentUuid],
    state.components.map,
    state.componentInstances.map,
    state.assets.map,
    state.props.map,
    state.layers.map,
  );
};

// Reducer
export const componentsReducer: Reducer<any, ComponentActions> = (state = initialState, action) => {
  switch (action.type) {
    case getType(getComponentsSuccessCreator):
    case getType(getComponentSetSuccessCreator):
    case getType(getComponentSetComponentSuccessCreator):
      const newState = { ...state };
      for (const componentId in action.payload.components) {
        if (!action.payload.components.hasOwnProperty(componentId)) continue;
        if (newState.map.hasOwnProperty(componentId)) {
          newState.map[componentId] = mergeComponent(
            newState.map[componentId],
            action.payload.components[componentId],
          );
        } else {
          newState.map[componentId] = action.payload.components[componentId];
        }
      }
      return newState;
    case getType(getComponentFontsSuccessCreator):
      const stateWithMissingFonts = { ...state };
      const componentUuid = action.payload.componentUuid;
      if (stateWithMissingFonts.map.hasOwnProperty(componentUuid)) {
        stateWithMissingFonts.map[componentUuid] = {
          ...stateWithMissingFonts.map[componentUuid],
          missingFonts: action.payload.missingFonts,
        };
      }
      return stateWithMissingFonts;
    default:
      return state;
  }
};

// Sagas
export function* getComponentFontSaga(action: ReturnType<typeof getComponentFontsRequestCreator>) {
  try {
    const token = yield select(selectToken);
    const componentUuid = action.payload.componentUuid;
    const {
      body: fonts,
    }: {
      body: {
        fontFamily: string;
        fontWeight: number;
      }[];
    } = yield call(getComponentFonts, token, componentUuid, action.payload.projectUuid);

    const googleFontsToLoad = fonts.filter(font => {
      return isAGoogleFont(font.fontFamily) || font.fontFamily === 'Material Icons';
    });

    googleFontsToLoad.forEach(googleFont => {
      if (googleFont.fontFamily === 'Material Icons') {
        loadGoogleMaterialIcons();
      } else {
        loadGoogleFont(googleFont);
      }
    });

    yield delay(5000);
    const missingFontFamilies = fonts
      .filter(font => {
        return !fontChecker.isFontAvailable(font.fontFamily);
      })
      .map(font => font.fontFamily);

    yield put(
      getComponentFontsSuccessCreator({ componentUuid, missingFonts: missingFontFamilies }),
    );
  } catch (error) {
    if (
      401 === error.response.status ||
      403 === error.response.status ||
      404 === error.response.status
    ) {
      yield put(push(`/404`));
      return;
    }
    yield put(
      displayErrorToaster({ errorMessage: 'An error occurred while getting this component.' }),
    );
    Sentry.captureException(error);
  }
}

function* updateComponentPropsSaga(action: ReturnType<typeof updateComponentPropsRequestCreator>) {
  try {
    const propsUuid = action.payload.props.uuid;
    const token = yield select(selectToken);
    const selectedComponent: ComponentType = yield select(
      selectComponentByUuid,
      action.payload.componentUuid,
    );

    selectedComponent.props = selectedComponent.props.map(prop => {
      if (prop.uuid === propsUuid) {
        prop.active = action.payload.props.active;
      }
      return prop;
    });

    // Optimistic rendering
    const selectedComponentNormalized = normalizeComponent(selectedComponent);
    yield put(
      getComponentsSuccessCreator({
        components: selectedComponentNormalized.entities.component,
        componentInstances: selectedComponentNormalized.entities.componentInstance,
        assets: selectedComponentNormalized.entities.asset,
        layer: selectedComponentNormalized.entities.layer,
        props: selectedComponentNormalized.entities.props,
      }),
    );

    const { body: component } = yield call(
      updateComponentProps,
      token,
      action.payload.componentUuid,
      selectedComponent.props,
    );
    const { entities } = normalizeComponent(component);
    yield put(
      getComponentsSuccessCreator({
        components: entities.component,
        componentInstances: entities.componentInstance,
        assets: entities.asset,
        layer: entities.layer,
        props: entities.props,
      }),
    );

    if (action.payload.layerToRecompile) {
      yield put(
        compileComponentFragmentRequestCreator({
          componentUuid: action.payload.componentUuid,
          layerUuid: action.payload.layerToRecompile,
        }),
      );
    }
  } catch (e) {
    yield put(
      displayErrorToaster({
        errorMessage: 'An error occurred while updating this component props.',
      }),
    );
    Sentry.captureException(e);
  }
}

// Saga Watchers
function* watchUpdateComponentProps() {
  yield takeLatest(
    getType(updateComponentPropsRequestCreator),
    withLoader(
      withAuthentication(updateComponentPropsSaga),
      SimpleLoadingKeysEnum.updateComponentProps,
    ),
  );
}

function* watchGetComponentFonts() {
  yield takeLatest(
    getType(getComponentFontsRequestCreator),
    withAuthentication(getComponentFontSaga),
  );
}

// Saga export
export function* watchComponentSagas() {
  yield fork(watchUpdateComponentProps);
  yield fork(watchGetComponentFonts);
}

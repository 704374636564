import React, { useState, useEffect, useRef } from 'react';
import styles from './ColorCard.module.scss';
import { ReactComponent as TextShowcase } from 'assets/text-showcase.svg';
import TransparentBackground from 'assets/transparent-background.png';
import TransparentText from 'assets/transparent-text.png';
import classnames from 'classnames';
import RemoveButton from 'components/Atoms/RemoveButton';
import { ClassValue } from 'classnames/types';
import {
  FrontVariableStateEnum,
  FrontVariableChangeTypeEnum,
  FrontVariableInputType,
  VariableColorType,
} from 'modules/stylesheet/types';
import { isShowcaseApp } from 'services/featureToggling';

interface IColorCardProps {
  color: VariableColorType;
  classNames: ClassValue;
  onColorChange: (colorId: number, newName: string) => void;
  onColorRemove: () => void;
  colorChange: FrontVariableInputType;
}

const ColorCard: React.FunctionComponent<IColorCardProps> = ({
  color,
  classNames,
  onColorChange,
  onColorRemove,
  colorChange,
}) => {
  const [colorNameInput, setColorNameInput] = useState('');
  const textInput = useRef(null);

  useEffect(() => {
    if (color.name) {
      setColorNameInput(color.name);
    }
  }, [color.name]);

  const rgbaRegex = /^rgba?\((\d+),(\d+),(\d+),(1|0?\.\d+)\)/;

  const rgba2hex = (rgba: string) => {
    const rgbaMatch = rgba.match(rgbaRegex);
    return rgbaMatch && rgbaMatch.length === 5
      ? '#' +
          ('0' + parseInt(rgbaMatch[1], 10).toString(16)).slice(-2).toUpperCase() +
          ('0' + parseInt(rgbaMatch[2], 10).toString(16)).slice(-2).toUpperCase() +
          ('0' + parseInt(rgbaMatch[3], 10).toString(16)).slice(-2).toUpperCase()
      : '';
  };

  const handleColorNameChange = (event: any) => {
    const newName = event.target.value;
    setColorNameInput(newName);

    onColorChange(color.id, newName);
  };

  const handleRemoveColor = () => {
    onColorRemove();
  };

  const getAlphaPercentageFromRgbaColor = (rgba: string): number => {
    const rgbaMatch = rgba.match(rgbaRegex);
    return rgbaMatch && rgbaMatch.length === 5 ? Math.round(parseFloat(rgbaMatch[4]) * 100) : 100;
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    if (null !== textInput && null !== textInput.current) {
      // @ts-ignore
      textInput.current.blur();
    }
  };

  const alphaPercentage = getAlphaPercentageFromRgbaColor(color.value);
  const colorHasAlpha = alphaPercentage < 100;
  const validationState = colorChange && colorChange.validationState;
  const willBeDeleted = !!colorChange && colorChange.type === FrontVariableChangeTypeEnum.DELETE;

  return (
    <div className={classnames(classNames, styles.colorItem)}>
      <div className={styles.colorSection}>
        {color.type === 'other' ? (
          <div className={styles.transparentContainer}>
            <img alt="" className={styles.transparentColorBackground} src={TransparentBackground} />
            <div className={styles.colorPatch} style={{ backgroundColor: color.value }} />
          </div>
        ) : (
          <div className={styles.textColorContainer}>
            <div className={styles.transparentColorBackground}>
              {colorHasAlpha && <img alt="" src={TransparentText} />}
            </div>
            <TextShowcase className={styles.textColorPreview} style={{ color: color.value }} />
          </div>
        )}
        {!isShowcaseApp() && (
          <RemoveButton onClick={handleRemoveColor} className={styles.removeButton} />
        )}
        {colorHasAlpha && (
          <div className={styles.opacityLabel}>
            <p className={styles.opacityValue}>{alphaPercentage}%</p>
          </div>
        )}
      </div>
      {isShowcaseApp() ? (
        <p className={styles.colorName}>{colorNameInput}</p>
      ) : (
        <form className={styles.nameSection} onSubmit={onSubmit}>
          <input
            ref={textInput}
            className={classnames(styles.colorName, willBeDeleted && styles.deletable)}
            value={colorNameInput}
            onChange={handleColorNameChange}
            disabled={willBeDeleted}
          />
        </form>
      )}
      <p className={styles.errorName}>
        {validationState === FrontVariableStateEnum.INVALID_NAME_ERROR && 'Invalid color name'}
        {validationState === FrontVariableStateEnum.DUPLICATE_NAME_ERROR && 'Name already used'}
      </p>
      <div className={classnames(styles.nameSection, willBeDeleted && styles.deletable)}>
        <p className={styles.value}>{rgba2hex(color.value)}</p>
      </div>
      <div className={classnames(styles.nameSection, willBeDeleted && styles.deletable)}>
        <p className={styles.value}>{color.value}</p>
      </div>
    </div>
  );
};

export default ColorCard;

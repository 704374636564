import React, { ChangeEventHandler } from 'react';
import cx from 'classnames';
import styles from './InputField.module.scss';
import { ClassValue } from 'classnames/types';

interface IProps {
  onChange?: ChangeEventHandler;
  className?: ClassValue;
  type?: string;
  hasError?: boolean;
  placeholder?: string;
  label?: string;
  value?: string;
  theme?: string;
  required?: boolean;
  maxlength?: number;
}

const InputField: React.FunctionComponent<IProps> = ({
  onChange,
  type,
  placeholder,
  label,
  value,
  required,
  className,
  theme,
  maxlength,
  hasError,
  ...rest
}) => {
  return (
    <div
      className={cx(
        styles.inputContainer,
        theme === 'white' && styles.white,
        hasError && styles.error,
        className,
      )}
    >
      {label && <p className={styles.label}>{label}</p>}
      <input
        className={styles.input}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        value={value}
        required={required}
        maxLength={maxlength}
        {...rest}
      />
    </div>
  );
};

export default InputField;

import React, { createRef, MutableRefObject, SyntheticEvent, useEffect, useState } from 'react';
import styles from './CategoryList.module.scss';
import { CategoryType } from 'modules/categories/types';
import ComponentList from 'components/Molecules/ComponentList';
import { ReactComponent as RemoveIcon } from 'assets/cross.svg';
import { ReactComponent as ArrowUp } from 'assets/arrow-up.svg';
import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg';
import classnames from 'classnames';
import {
  changeLastCategoryCreatedRequestCreator,
  changeLastCategoryUpdatedRequestCreator,
  createCategoryRequestCreator,
  deleteCategoryRequestCreator,
  updateCategoryRequestCreator,
} from 'modules/categories';
import Toaster from 'components/Atoms/Toaster';
import Button from 'components/Atoms/Button';
import { changeProjectCategoryOrderRequestCreator } from 'modules/projects';
import { isShowcaseApp } from 'services/featureToggling';
import { ComponentSetType } from 'modules/componentSets/types';
import { updateComponentSetNameRequestCreator } from 'modules/componentSets';

type Props = {
  projectUuid: string;
  lastCreatedCategory: string | null;
  lastUpdatedCategory: string | null;
  isCreatingCategory: boolean;
  categories: CategoryType[];
  components: ComponentSetType[];
  changeLastCategoryCreated: typeof changeLastCategoryCreatedRequestCreator;
  changeLastCategoryUpdated: typeof changeLastCategoryUpdatedRequestCreator;
  changeProjectCategoryOrder: typeof changeProjectCategoryOrderRequestCreator;
  deleteCategory: typeof deleteCategoryRequestCreator;
  createCategory: typeof createCategoryRequestCreator;
  updateCategory: typeof updateCategoryRequestCreator;
  updateComponentName: typeof updateComponentSetNameRequestCreator;
  onComponentDelete?: (component: ComponentSetType) => void;
};

const WITHOUT_CATEGORY_KEY = 'without_categories';

const CategoryList: React.FunctionComponent<Props> = ({
  categories,
  createCategory,
  isCreatingCategory,
  lastCreatedCategory,
  lastUpdatedCategory,
  changeLastCategoryCreated,
  changeLastCategoryUpdated,
  changeProjectCategoryOrder,
  onComponentDelete,
  deleteCategory,
  updateCategory,
  projectUuid,
  components,
  updateComponentName,
}) => {
  const componentsGroupByCategory = components.reduce(
    (result: Record<string, ComponentSetType[]>, component: ComponentSetType) => {
      const key = component.category ? component.category.uuid : WITHOUT_CATEGORY_KEY;
      result[key] = result.hasOwnProperty(key) ? [...result[key], component] : [component];

      return result;
    },
    { [WITHOUT_CATEGORY_KEY]: [] },
  );

  const [selectedComponents, changeSelectedComponents] = useState<string[]>([]);
  const [categoryNamesState, changeCategoryNames] = useState<Record<string, string>>({});
  const [categoryRefsState, changeCategoryRefs] = useState<Record<string, MutableRefObject<any>>>(
    {},
  );
  const [isSelectOpen, changeSelectCategoryOpen] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener('keydown', onKeyPress, false);
  }, []);

  useEffect(() => {
    if (!lastCreatedCategory) {
      return;
    }

    categoryRefsState[lastCreatedCategory].current.select();
    categoryRefsState[lastCreatedCategory].current.scrollIntoView({ behavior: 'smooth' });
    changeSelectedComponents([]);
    changeSelectCategoryOpen(false);
    changeLastCategoryCreated({ categoryUuid: null });
  }, [lastCreatedCategory]);

  useEffect(() => {
    if (!lastUpdatedCategory) {
      return;
    }

    categoryRefsState[lastUpdatedCategory].current.scrollIntoView({ behavior: 'smooth' });
    changeSelectedComponents([]);
    changeSelectCategoryOpen(false);
    changeLastCategoryUpdated({ categoryUuid: null });
  }, [lastUpdatedCategory]);

  useEffect(() => {
    let categoryMap: Record<string, string> = { ...categoryNamesState };
    let categoryRefs: Record<string, MutableRefObject<any>> = { ...categoryRefsState };
    categories.map(category => {
      if (!categoryRefsState.hasOwnProperty(category.uuid)) {
        categoryRefs[category.uuid] = createRef();
      }
      if (!categoryNamesState.hasOwnProperty(category.uuid)) {
        categoryMap[category.uuid] = category.name;
      }
    });
    changeCategoryNames(categoryMap);
    changeCategoryRefs(categoryRefs);
  }, [categories]);

  const onKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      changeSelectedComponents([]);
      changeSelectCategoryOpen(false);
    }
  };

  const handleChangeCategoryName = (categoryUuid: string) => (
    event: React.FormEvent<HTMLInputElement>,
  ) => {
    const newCategoryNamesMap = {
      ...categoryNamesState,
      [categoryUuid]: event.currentTarget.value,
    };
    changeCategoryNames(newCategoryNamesMap);
  };

  const onSubmitCategoryName = (categoryUuid: string) => (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    categoryRefsState[categoryUuid].current.blur();
    updateCategory({ categoryUuid, name: categoryNamesState[categoryUuid] });
  };

  const onCreateNewCategory = () => {
    createCategory({ projectUuid, components: selectedComponents });
  };

  const onMoveToNewCategory = (categoryUuid: string) => () => {
    const currentCategoryComponentUuid = componentsGroupByCategory[categoryUuid].map(
      component => component.uuid,
    );
    updateCategory({
      categoryUuid,
      components: [...currentCategoryComponentUuid, ...selectedComponents],
    });
  };

  const selectComponent = (component: ComponentSetType) => {
    let newSelectedComponents = [...selectedComponents];
    if (-1 === newSelectedComponents.indexOf(component.uuid)) {
      newSelectedComponents.push(component.uuid);
    } else {
      newSelectedComponents = newSelectedComponents.filter(
        componentUuid => componentUuid !== component.uuid,
      );
      if (newSelectedComponents.length === 0) changeSelectCategoryOpen(false);
    }

    changeSelectedComponents(newSelectedComponents);
  };

  const onRemoveCategory = (categoryUuid: string) => () => {
    deleteCategory({ categoryUuid, projectUuid });
  };

  const changeOrder = (categoryOrder: number, up: boolean) => () => {
    const categoriesWithUpdatedOrder = [...categories.map(category => category.uuid)];
    if (up) {
      [categoriesWithUpdatedOrder[categoryOrder], categoriesWithUpdatedOrder[categoryOrder + 1]] = [
        categoriesWithUpdatedOrder[categoryOrder + 1],
        categoriesWithUpdatedOrder[categoryOrder],
      ];
    } else {
      [categoriesWithUpdatedOrder[categoryOrder], categoriesWithUpdatedOrder[categoryOrder - 1]] = [
        categoriesWithUpdatedOrder[categoryOrder - 1],
        categoriesWithUpdatedOrder[categoryOrder],
      ];
    }
    changeProjectCategoryOrder({ categories: categoriesWithUpdatedOrder, projectUuid });
  };

  const renderCategoryHeader = (category: CategoryType, categoryOrder: number) => {
    const categoryName = categoryNamesState.hasOwnProperty(category.uuid)
      ? categoryNamesState[category.uuid]
      : '';
    return (
      <div className={styles.sectionTitle} key={category.uuid}>
        <form className={styles.nameSection} onSubmit={onSubmitCategoryName(category.uuid)}>
          <input
            className={styles.colorName}
            ref={categoryRefsState[category.uuid]}
            value={categoryName}
            onBlur={onSubmitCategoryName(category.uuid)}
            onChange={handleChangeCategoryName(category.uuid)}
            placeholder={'New category'}
          />
        </form>
        {!isShowcaseApp() && (
          <div className={styles.iconContainer}>
            {categoryOrder + 1 !== categories.length && (
              <ArrowDown className={styles.icon} onClick={changeOrder(categoryOrder, true)} />
            )}
            {categoryOrder > 0 && (
              <ArrowUp className={styles.icon} onClick={changeOrder(categoryOrder, false)} />
            )}
            <RemoveIcon className={styles.icon} onClick={onRemoveCategory(category.uuid)} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.componentListContainer}>
      {componentsGroupByCategory[WITHOUT_CATEGORY_KEY].length > 0 && (
        <section className={styles.categorySection}>
          <ComponentList
            components={componentsGroupByCategory[WITHOUT_CATEGORY_KEY]}
            selectedComponents={selectedComponents}
            onDelete={onComponentDelete}
            onSelect={isShowcaseApp() ? undefined : selectComponent}
            projectUuid={projectUuid}
            onUpdateComponentName={updateComponentName}
          />
        </section>
      )}
      {categories.length > 0 &&
        categories.map((category, index) => {
          return (
            <section key={category.uuid} className={styles.categorySection}>
              {renderCategoryHeader(category, index)}
              <ComponentList
                components={
                  componentsGroupByCategory.hasOwnProperty(category.uuid)
                    ? componentsGroupByCategory[category.uuid]
                    : []
                }
                selectedComponents={selectedComponents}
                onSelect={isShowcaseApp() ? undefined : selectComponent}
                onDelete={onComponentDelete}
                onUpdateComponentName={updateComponentName}
                projectUuid={projectUuid}
              />
            </section>
          );
        })}
      <Toaster isVisible={selectedComponents.length > 0}>
        <div className={styles.componentsSelectionToaster}>
          <div className={styles.leftContainer}>
            <p className={styles.label}>
              <strong className={styles.labelEmphasis}>{selectedComponents.length}</strong>{' '}
              component(s) selected
            </p>
            <Button
              isLoading={isCreatingCategory}
              onClick={() => changeSelectCategoryOpen(!isSelectOpen)}
            >
              Move to
            </Button>
            <div
              className={classnames(styles.sectionSelection, isSelectOpen && styles.isOpen)}
              style={{
                height: isSelectOpen
                  ? categories.length > 4
                    ? 230
                    : 46 * (categories.length + 1)
                  : 0,
              }}
            >
              <p className={styles.labelNewCategory} onClick={onCreateNewCategory}>
                New category
              </p>
              {categories.map(category => (
                <p
                  key={category.uuid}
                  className={styles.categoryItem}
                  onClick={onMoveToNewCategory(category.uuid)}
                >
                  {category.name}
                </p>
              ))}
            </div>
          </div>
          <p className={styles.deselectLabel}>
            Press “<strong className={styles.deselectLabelEmphasis}>Esc</strong>” to deselect all
          </p>
        </div>
      </Toaster>
    </div>
  );
};

export default CategoryList;

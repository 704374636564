import { connect } from 'react-redux';
import OverlayHeader from './OverlayWideLoader.component';
import { OverlayStoreType } from 'redux/types';
import { isLoading } from 'modules/loading';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';

const mapStateToProps = (state: OverlayStoreType) => ({
  isLoading: isLoading(state, SimpleLoadingKeysEnum.wideLoader),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OverlayHeader);

import { connect } from 'react-redux';
import ProjectSettings, { IProjectSettingsProps } from './ProjectSettings.component';
import {
  updateSettingsRequestCreator,
  selectStylesheetPath,
  selectTargetTechnology,
  selectUIFramework,
} from 'modules/projects';
import { isLoading } from 'modules/loading';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import { OverlayStoreType } from 'redux/types';

const mapStateToProps = (state: OverlayStoreType, ownProps: IProjectSettingsProps) => ({
  isLoading: isLoading(state, SimpleLoadingKeysEnum.updateProjectSettings),
  projectId: ownProps.projectId,
  formerStylesheetPath: selectStylesheetPath(state, ownProps.projectId),
  targetTechnology: selectTargetTechnology(state, ownProps.projectId),
  UIFramework: selectUIFramework(state, ownProps.projectId),
});

const mapDispatchToProps = {
  updateSettings: updateSettingsRequestCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSettings);

import React from 'react';
import * as Sentry from '@sentry/react';
import InternalError from 'pages/500';

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<{}, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return <InternalError />;
    }

    return this.props.children;
  }
}

import { fork } from 'redux-saga/effects';

import { watchComponentSagas } from 'modules/components/components';
import { watchProjectSagas } from 'modules/projects';
import { watchAuthenticationSagas } from 'modules/authentication';
import { watchResetPasswordSagas } from 'modules/resetPassword/resetPassword';
import { watchStylesheetSagas } from 'modules/stylesheet';
import { watchValidateAccountSagas } from 'modules/validateAccount/validateAccount';
import { watchCategoriesSagas } from 'modules/categories';
import { watchUserSagas } from 'modules/user';
import { watchSubscriptionSagas } from 'modules/subscription';
import { watchComponentInstanceSagas } from 'modules/componentInstances';
import { watchReviewSagas } from 'modules/reviews';
import { watchComponentSetSagas } from 'modules/componentSets';
import { watchTeamSagas } from 'modules/teams';
import { watchLayerSagas } from 'modules/layers';

// Single entry point to start all sagas at once
export default function* rootSaga() {
  yield fork(watchProjectSagas);
  yield fork(watchUserSagas);
  yield fork(watchResetPasswordSagas);
  yield fork(watchComponentSagas);
  yield fork(watchComponentSetSagas);
  yield fork(watchAuthenticationSagas);
  yield fork(watchStylesheetSagas);
  yield fork(watchValidateAccountSagas);
  yield fork(watchCategoriesSagas);
  yield fork(watchLayerSagas);
  yield fork(watchTeamSagas);
  yield fork(watchSubscriptionSagas);
  yield fork(watchComponentInstanceSagas);
  yield fork(watchReviewSagas);
}

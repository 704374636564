import superagent from 'superagent';
import { BackUpdateVariable } from 'modules/stylesheet/types';
import { DesignToolEnum, TargetTechnologyEnum, UIFrameworkEnum } from 'modules/projects/types';
import { CompanyEnum, JobEnum } from 'modules/user';
import { ReviewTypeEnum } from 'modules/reviews/types';
import { TeamType } from 'modules/teams/types';
import { ComponentPropsType } from 'modules/props/types';

let urlBase = 'http://localhost:8080/api';

if (process.env.NODE_ENV === 'production') {
  urlBase = process.env.REACT_APP_API_URL || urlBase;
}

export const getDownloadAssetUrl = (projectUuid: string, assetUuid: string) =>
  `${urlBase}/assets/${projectUuid}/download-asset/${assetUuid}`;

export const getProjectDetail = (token: string, id: string) =>
  superagent
    .get(`${urlBase}/projects/${id}`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const getUserInfo = (token: string) =>
  superagent
    .get(`${urlBase}/me`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const qualifyUser = (token: string, job: JobEnum, company: CompanyEnum) =>
  superagent
    .put(`${urlBase}/me/qualify`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({
      job,
      company,
    });

export const dismissReview = (token: string) =>
  superagent
    .post(`${urlBase}/me/dismiss-review`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send();

export const deleteCategory = (token: string, categoryUuid: string) =>
  superagent
    .delete(`${urlBase}/categories/${categoryUuid}`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

// ******************* //
//   Component Set     //
// ******************* //

export const getComponentSet = (token: string, componentSetUuid: string, projectUuid: string) =>
  superagent
    .get(`${urlBase}/projects/${projectUuid}/component-sets/${componentSetUuid}`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const deleteComponentSet = (token: string, componentUuid: string, projectUuid: string) =>
  superagent
    .delete(`${urlBase}/projects/${projectUuid}/component-sets/${componentUuid}`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const getComponentSetComponent = (
  token: string,
  componentSetUuid: string,
  projectUuid: string,
  variantKey?: string,
  lastPropertyChosen?: string,
) => {
  let url = `${urlBase}/projects/${projectUuid}/component-sets/${componentSetUuid}/components`;

  const request = superagent
    .get(url)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

  if (variantKey && lastPropertyChosen) {
    request.query(
      `variant-key=${encodeURIComponent(variantKey)}&last-property-chosen=${encodeURIComponent(
        lastPropertyChosen,
      )}`,
    );
  }

  return request;
};

export const updateComponentSetName = (
  token: string,
  componentSetUuid: string,
  componentUuid: string | null,
  projectUuid: string,
  newName: string,
) =>
  superagent
    .put(`${urlBase}/projects/${projectUuid}/component-sets/${componentSetUuid}/rename`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({
      name: newName,
      componentUuid,
    });

// ******************* //
//      Component      //
// ******************* //

export const getComponent = (token: string, componentUuid: string, projectUuid: string) =>
  superagent
    .get(`${urlBase}/projects/${projectUuid}/components/${componentUuid}`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const getComponentFonts = (token: string, componentUuid: string, projectUuid: string) =>
  superagent
    .get(`${urlBase}/projects/${projectUuid}/components/${componentUuid}/fonts`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const getComponentAssets = (token: string, componentUuid: string) =>
  superagent
    .get(`${urlBase}/components/${componentUuid}/assets`)
    .set('Authorization', `Bearer ${token}`)
    .set('Accept', 'application/json');

export const updateComponentProps = (
  token: string,
  componentUuid: string,
  props: ComponentPropsType[],
) =>
  superagent
    .put(`${urlBase}/components/${componentUuid}/props`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send(props);

// ******************* //
// Component Instances //
// ******************* //

export const getComponentInstance = (
  token: string,
  componentInstanceUuid: string,
  projectUuid: string,
) =>
  superagent
    .get(`${urlBase}/projects/${projectUuid}/component_instances/${componentInstanceUuid}`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const getComponentInstanceFonts = (
  token: string,
  componentInstanceUuid: string,
  projectUuid: string,
) =>
  superagent
    .get(`${urlBase}/projects/${projectUuid}/component_instances/${componentInstanceUuid}/fonts`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const updateComponentInstanceName = (
  token: string,
  componentInstanceUuid: string,
  projectUuid: string,
  newName: string,
) =>
  superagent
    .put(`${urlBase}/projects/${projectUuid}/component_instances/${componentInstanceUuid}/rename`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({
      name: newName,
    });

export const getComponentInstanceAssets = (token: string, componentInstanceUuid: string) =>
  superagent
    .get(`${urlBase}/component_instances/${componentInstanceUuid}/assets`)
    .set('Authorization', `Bearer ${token}`)
    .set('Accept', 'application/json');

export const updateComponentInstanceProps = (
  token: string,
  componentInstanceUuid: string,
  props: ComponentPropsType[],
) =>
  superagent
    .put(`${urlBase}/component_instances/${componentInstanceUuid}/props`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send(props);

// ******************* //
//        Layer        //
// ******************* //

export const compileComponentFragment = (token: string, componentUuid: string, layerUuid: string) =>
  superagent
    .post(`${urlBase}/fragment/components/${componentUuid}/layer/${layerUuid}`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send();

export const compileComponentInstanceFragment = (
  token: string,
  componentInstanceUuid: string,
  layerUuid: string,
) =>
  superagent
    .post(`${urlBase}/fragment/component_instances/${componentInstanceUuid}/layer/${layerUuid}`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send();

// ******************* //
//        Team         //
// ******************* //

export const getMyTeams = (token: string) =>
  superagent
    .get(`${urlBase}/teams`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const getMyTeam = (token: string, teamUuid: string) =>
  superagent
    .get(`${urlBase}/teams/${teamUuid}`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const getTeamInvoices = (token: string, teamUuid: string) =>
  superagent
    .get(`${urlBase}/teams/${teamUuid}/invoices`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const createTeam = (token: string, name: string) =>
  superagent
    .post(`${urlBase}/teams`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({
      name,
    });

export const addTeamContributor = (token: string, teamUuid: string, email: string) =>
  superagent
    .post(`${urlBase}/teams/${teamUuid}/contributors`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({
      email,
    });

export const removeTeamContributor = (token: string, teamUuid: string, contributorUuid: string) =>
  superagent
    .delete(`${urlBase}/teams/${teamUuid}/contributors/${contributorUuid}`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const deleteTeam = (token: string, teamUuid: string) =>
  superagent
    .delete(`${urlBase}/teams/${teamUuid}`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const updateTeam = (token: string, team: TeamType) =>
  superagent
    .put(`${urlBase}/teams/${team.uuid}`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send(team);

export const createTeamSubscription = (
  token: string,
  paymentMethodId: string,
  companyName: string,
  billingEmail: string,
  priceId: string,
  teamId: string,
  seatNumber: number,
  extraInfo: string | null,
  promotionCodeId: string | null,
) =>
  superagent
    .post(`${urlBase}/teams/${teamId}/subscription`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({
      paymentMethodId,
      companyName,
      billingEmail,
      extraInfo,
      seatNumber,
      promotionCodeId,
      priceId,
    });

export const updateTeamSubscription = (
  token: string,
  teamUuid: string,
  priceId: string,
  seatNumber: number,
) =>
  superagent
    .put(`${urlBase}/teams/${teamUuid}/subscription`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({
      priceId,
      seatNumber,
    });

export const previewUpdateSubscription = (
  token: string,
  priceId: string,
  teamUuid: string,
  seatNumber: number,
  promotionCode: string | null,
) =>
  superagent
    .post(`${urlBase}/teams/${teamUuid}/retrieve-upcoming-invoice`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({
      priceId,
      seatNumber,
      promotionCode,
    });

export const cancelTeamSubscription = (token: string, teamUuid: string, subscriptionId: string) =>
  superagent
    .delete(`${urlBase}/teams/${teamUuid}/subscription`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({ subscriptionId });

// ******************* //
//        Review       //
// ******************* //

export const createReview = (
  token: string,
  type: ReviewTypeEnum,
  compilableUuid: string,
  mark: number,
) =>
  superagent
    .post(`${urlBase}/review`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({
      type,
      compilableUuid,
      mark,
    });

export const updateReview = (token: string, componentReviewUuid: string, comment: string) =>
  superagent
    .put(`${urlBase}/review/${componentReviewUuid}`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({
      comment,
    });

// ******************* //
//      Category      //
// ******************* //

export const createCategory = (token: string, projectUuid: string, components: string[]) =>
  superagent
    .post(`${urlBase}/projects/${projectUuid}/categories`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({
      projectUuid,
      components,
      name: 'New category',
    });

export const updateCategory = (
  token: string,
  categoryUuid: string,
  components?: string[],
  name?: string,
) =>
  superagent
    .put(`${urlBase}/categories/${categoryUuid}`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({
      components,
      name,
    });

export const changeCategoryOrder = (token: string, projectUuid: string, categories?: string[]) =>
  superagent
    .put(`${urlBase}/projects/${projectUuid}/change-category-order`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({
      categories,
    });

export const getProjectStylesheet = (token: string, projectUuid: string) =>
  superagent
    .get(`${urlBase}/projects/${projectUuid}/stylesheet`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const updateProjectStylesheet = (
  projectId: number,
  token: string,
  { colors, typos }: { colors: BackUpdateVariable[]; typos: BackUpdateVariable[] },
) =>
  superagent
    .put(`${urlBase}/projects/${projectId}/stylesheet`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({
      stylesheet: {
        typographies: typos.map(typo => ({
          ...typo,
        })),
        colors: colors.map(color => ({
          ...color,
        })),
      },
      compile: true,
    });

export const deleteProjectStylesheetVariables = (
  projectId: string,
  token: string,
  { colors, typos }: { colors: { id: number }[]; typos: { id: number }[] },
) =>
  superagent
    .delete(`${urlBase}/projects/${projectId}/stylesheet`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({
      typographies: typos.map(typo => ({
        ...typo,
      })),
      colors: colors.map(color => ({
        ...color,
      })),
    });

export const createProject = (
  token: string,
  name: string,
  targetTechnology: TargetTechnologyEnum,
  uiFramework: UIFrameworkEnum,
  designTool: DesignToolEnum,
  teamUuid: string,
) =>
  superagent
    .post(`${urlBase}/projects`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({
      name,
      targetTechnology,
      uiFramework,
      designTool,
      teamUuid,
    });

export const deleteProject = (token: string, projectUuid: string) =>
  superagent
    .delete(`${urlBase}/projects/${projectUuid}`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const getUserProjects = (token: string) =>
  superagent
    .get(`${urlBase}/projects`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const validatePromotionCode = (token: string, promotionCode: string) =>
  superagent
    .post(`${urlBase}/validate-promotion`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({
      promotionCode,
    });

export const updatePaymentInfo = (token: string, teamUuid: string, paymentMethodId: string) =>
  superagent
    .put(`${urlBase}/teams/${teamUuid}/subscription/payment`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({
      paymentMethodId,
    });

export const login = (email: string, password: string) =>
  superagent
    .post(`${urlBase}/login`)
    .set('Accept', 'application/json')
    .send({
      email,
      password,
    });

export const signUp = (
  email: string,
  password: string,
  hasAcceptedCGU: boolean,
  hasAcceptedProtectionOfPersonalData: boolean,
  needNewsletter: boolean,
) =>
  superagent
    .post(`${urlBase}/signup`)
    .set('Accept', 'application/json')
    .send({
      email,
      password,
      needNewsletter,
      protection: hasAcceptedProtectionOfPersonalData,
      cgu: hasAcceptedCGU,
    });

export const appSumoSignUp = (
  email: string,
  password: string,
  appSumoCode: string,
  hasAcceptedCGU: boolean,
  hasAcceptedProtectionOfPersonalData: boolean,
  needNewsletter: boolean,
) =>
  superagent
    .post(`${urlBase}/signup-appsumo`)
    .set('Accept', 'application/json')
    .send({
      email,
      password,
      needNewsletter,
      appSumoCode,
      protection: hasAcceptedProtectionOfPersonalData,
      cgu: hasAcceptedCGU,
    });

export const refreshToken = (refreshToken: string) =>
  superagent.post(`${urlBase}/token/refresh`).send(`refresh_token=${refreshToken}`);

export const updateProjectSettings = (
  token: string,
  projectUuId: string,
  stylesheetPath: string,
  targetTechnology: TargetTechnologyEnum,
  uiFramework: UIFrameworkEnum,
) =>
  superagent
    .put(`${urlBase}/projects/${projectUuId}`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({ stylesheetPath, targetTechnology, uiFramework });

export const updateProjectBetaUser = (token: string, projectUuId: string, betaUser: boolean) =>
  superagent
    .put(`${urlBase}/projects/${projectUuId}`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({ betaUser });

export const forgotPassword = (email: string) =>
  superagent
    .post(`${urlBase}/forgot-password`)
    .type('form')
    .send({ email });

export const checkToken = (token: string) =>
  superagent.get(`${urlBase}/reset-password`).query({ token });

export const resetPassword = (token: string, password: string) =>
  superagent
    .post(`${urlBase}/reset-password`)
    .type('form')
    .query({ token })
    .send({ password });

export const addProjectContributor = (
  token: string,
  projectUuid: string,
  teamContributorUuid: string,
) =>
  superagent
    .post(`${urlBase}/projects/${projectUuid}/contributors`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({ teamContributorUuid });

export const oldValidateAccount = (token: string) =>
  superagent
    .post(`${urlBase}/old-validate-account`)
    .set('Accept', 'application/json')
    .send({ token });

export const validateAccount = (code: string, email: string) =>
  superagent
    .post(`${urlBase}/validate-account`)
    .set('Accept', 'application/json')
    .send({ code, email });

export const resendValidationEmail = (email: string) =>
  superagent
    .post(`${urlBase}/resend-validation`)
    .set('Accept', 'application/json')
    .send({ email });

export const removeProjectContributor = (
  token: string,
  projectUuid: string,
  projectContributorUuid: string,
) =>
  superagent
    .delete(`${urlBase}/projects/${projectUuid}/contributors/${projectContributorUuid}`)
    .type('form')
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const logout = (token: string) =>
  superagent
    .get(`${urlBase}/logout`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const validatePairing = (token: string, pairingToken: string) =>
  superagent
    .post(`${urlBase}/pair/${pairingToken}/validate`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

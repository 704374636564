import React from 'react';
import styles from './ComponentList.module.scss';
import ComponentCard from 'components/Molecules/ComponentList/ComponentCard.component';
import { ComponentSetType } from 'modules/componentSets/types';
import { updateComponentSetNameRequestCreator } from 'modules/componentSets';

type Props = {
  components: ComponentSetType[];
  selectedComponents?: string[];
  componentLinksMustBeOpenInANewTab?: boolean;
  onDelete?: (component: ComponentSetType) => void;
  onSelect?: (component: ComponentSetType) => void;
  onUpdateComponentName?: typeof updateComponentSetNameRequestCreator;
  projectUuid: string;
};

const ComponentList: React.FunctionComponent<Props> = ({
  components,
  selectedComponents,
  onUpdateComponentName,
  onDelete,
  onSelect,
  projectUuid,
}) => {
  const handleRemoveComponentSet = (component: ComponentSetType) => (event: any) => {
    event.preventDefault();
    if (!onDelete) {
      return;
    }
    onDelete(component);
  };

  const handleSelectComponentSet = (component: ComponentSetType) => (event: any) => {
    event.preventDefault();
    if (!onSelect) {
      return;
    }
    onSelect(component);
  };

  return (
    <div className={styles.componentListContainer}>
      {components.length > 0 &&
        components.map(component => (
          <ComponentCard
            key={component.uuid}
            component={component}
            selectedComponents={selectedComponents}
            handleSelectComponent={handleSelectComponentSet(component)}
            handleRemoveComponent={handleRemoveComponentSet(component)}
            onUpdateComponentName={onUpdateComponentName}
            projectUuid={projectUuid}
            onDelete={onDelete}
            onSelect={onSelect}
          />
        ))}
    </div>
  );
};

export default ComponentList;

import { call, takeLatest, put, select, fork } from 'redux-saga/effects';
import { withLoader } from 'modules/loading';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import { errorsMapper, authenticationErrorCleaning } from 'modules/authenticationError';
import {
  login,
  signUp,
  refreshToken as refreshTokenRequest,
  resendValidationEmail,
  logout,
  validatePairing,
  appSumoSignUp,
} from 'services/api';
import { parseJwt } from 'services/jwtParser';
import { AuthenticationStoreType, LoginResponse } from 'modules/authentication/types';
import { OverlayStoreType } from 'redux/types';
import { ResponseError } from 'superagent';
import { createAction, getType } from 'typesafe-actions';
import * as Sentry from '@sentry/react';
import { push } from 'connected-react-router';

const initialState: AuthenticationStoreType = {
  token: null,
  refreshToken: null,
  email: null,
  expire: null,
  signUpSuccess: false,
};

export const actionTypes = {
  LOGIN: {
    REQUEST: 'LOGIN.REQUEST',
    SUCCESS: 'LOGIN.SUCCESS',
    FAILURE: 'LOGIN.FAILURE',
  },
  SIGNUP: {
    FAILURE: 'SIGNUP.FAILURE',
  },
  RESEND_VALIDATION: {
    REQUEST: 'RESEND_VALIDATION.REQUEST',
    SUCCESS: 'RESEND_VALIDATION.SUCCESS',
    FAILURE: 'RESEND_VALIDATION_FAILURE',
  },
  ERROR_CLEANING: 'ERROR_CLEANING',
};

// Actions Creators
export const loginRequestCreator = (email: string, password: string) => ({
  type: actionTypes.LOGIN.REQUEST,
  email,
  password,
});

export const signUpRequestCreator = createAction('AUTHENTICATION/SIGNUP.REQUEST')<{
  email: string;
  password: string;
  hasAcceptedCGU: boolean;
  hasAcceptedProtectionOfPersonalData: boolean;
  needNewsletter: boolean;
}>();

export const appSumoSignUpRequestCreator = createAction('AUTHENTICATION/APPSUMO_SIGNUP.REQUEST')<{
  email: string;
  password: string;
  appSumoCode: string;
  needNewsletter: boolean;
}>();

export const validatePairingRequestCreator = createAction(
  'AUTHENTICATION/VALIDATE_PAIRING.REQUEST',
)<{
  pairingToken: string;
}>();

export const resendValidationRequestCreator = (email: string) => ({
  type: actionTypes.RESEND_VALIDATION.REQUEST,
  email,
});

export const resendValidationSuccessCreator = () => ({
  type: actionTypes.RESEND_VALIDATION.SUCCESS,
});

export const resendValidationFailureCreator = (error: string) => ({
  type: actionTypes.RESEND_VALIDATION.FAILURE,
  error,
});

export const logoutRequestCreator = createAction('AUTHENTICATION/LOGOUT.REQUEST')();

export const logoutSuccessCreator = createAction('AUTHENTICATION/LOGOUT.SUCCESS')();

export const authenticationFailureCreator = (actionType: string, error: string) => ({
  type: actionType,
  error,
});

export const loginSuccessCreator = (
  token: string,
  refreshToken: string,
  email: string,
  expire: number,
  actionType: string,
) => ({
  type: actionType,
  token,
  refreshToken,
  email,
  expire,
});

export const signUpSuccessCreator = createAction('AUTHENTICATION/SIGNUP.SUCCESS')();

export const signUpRedirectedCreator = createAction('AUTHENTICATION/SIGNUP.REDIRECTED')();

// Selectors
export const isAuthenticated = (store: OverlayStoreType) => !!store.authentication.token;
export const selectToken = (store: OverlayStoreType) => store.authentication.token;
export const selectRefreshToken = (store: OverlayStoreType) => store.authentication.refreshToken;
export const selectIsTokenExpired = (store: OverlayStoreType) =>
  store.authentication.expire ? store.authentication.expire < Date.now() + 60000 : false;
export const selectSignUpSuccess = (store: OverlayStoreType) => store.authentication.signUpSuccess;

// Reducer
export const authenticationReducer = (
  state: AuthenticationStoreType = initialState,
  action: any,
) => {
  switch (action.type) {
    case actionTypes.LOGIN.SUCCESS:
      return {
        ...state,
        token: action.token,
        refreshToken: action.refreshToken,
        email: action.email,
        expire: action.expire,
      };
    case getType(signUpSuccessCreator):
      return {
        ...state,
        signUpSuccess: true,
      };
    case getType(signUpRedirectedCreator):
      return {
        ...state,
        signUpSuccess: false,
      };
    case getType(logoutSuccessCreator):
      return initialState;
    default:
      return state;
  }
};

// Sagas
export function bodyParser(body: LoginResponse) {
  const token = body.token;
  const refreshTokenKey = 'refresh_token';
  const refreshToken = body[refreshTokenKey];
  let { email, exp } = parseJwt(token);
  exp = exp * 1000; // Convert Unix timestamps in milliseconds timestamp
  return { token, refreshToken, email, exp };
}

export function* loginSaga(loginAction: any) {
  try {
    yield put(authenticationErrorCleaning());
    const { body } = yield call(login, loginAction.email, loginAction.password);
    const { token, refreshToken, email, exp } = bodyParser(body);
    yield put(loginSuccessCreator(token, refreshToken, email, exp, actionTypes.LOGIN.SUCCESS));
  } catch (error) {
    Sentry.captureException(error);
    let exception: ResponseError = error;
    let errorMessage = null;
    if (null == exception.status) {
      errorMessage = errorsMapper.login.default;
    } else {
      errorMessage = errorsMapper.login[exception.status] || errorsMapper.login.default;
    }
    yield put(authenticationFailureCreator(actionTypes.LOGIN.FAILURE, errorMessage));
  }
}

export function* signUpSaga(signUpAction: ReturnType<typeof signUpRequestCreator>) {
  try {
    yield put(authenticationErrorCleaning());
    yield call(
      signUp,
      signUpAction.payload.email,
      signUpAction.payload.password,
      signUpAction.payload.hasAcceptedCGU,
      signUpAction.payload.hasAcceptedProtectionOfPersonalData,
      signUpAction.payload.needNewsletter,
    );
    yield put(signUpSuccessCreator());
    yield put(push(`/signup-success`));
  } catch (error) {
    Sentry.captureException(error);
    yield put(
      authenticationFailureCreator(
        actionTypes.SIGNUP.FAILURE,
        errorsMapper.signUp[error.status] || errorsMapper.signUp.default,
      ),
    );
  }
}

export function* appSumoSignUpSaga(signUpAction: ReturnType<typeof appSumoSignUpRequestCreator>) {
  try {
    yield put(authenticationErrorCleaning());
    yield call(
      appSumoSignUp,
      signUpAction.payload.email,
      signUpAction.payload.password,
      signUpAction.payload.appSumoCode,
      true,
      true,
      signUpAction.payload.needNewsletter,
    );
    yield put(signUpSuccessCreator());
    yield put(push(`/signup-success`));
  } catch (error) {
    Sentry.captureException(error);
    yield put(
      authenticationFailureCreator(
        actionTypes.SIGNUP.FAILURE,
        errorsMapper.signUp[error.status] || errorsMapper.signUp.default,
      ),
    );
  }
}

export function* resendValidationSaga(resendValidationAction: any) {
  try {
    yield call(resendValidationEmail, resendValidationAction.email);
    yield put(resendValidationSuccessCreator());
  } catch (error) {
    Sentry.captureException(error);
    yield put(
      resendValidationFailureCreator(
        errorsMapper.resendValidation[error.status] || errorsMapper.resendValidation.default,
      ),
    );
  }
}

export function* refreshTokenSaga() {
  try {
    const refreshToken = yield select(selectRefreshToken);
    const { body } = yield call(refreshTokenRequest, refreshToken);
    const token = body.token;
    let { email, exp } = parseJwt(token);
    exp = exp * 1000; // Convert Unix timestamps in milliseconds timestamp
    yield put(loginSuccessCreator(token, refreshToken, email, exp, actionTypes.LOGIN.SUCCESS));
  } catch (e) {
    Sentry.captureException(e);
    yield put(logoutSuccessCreator());
  }
}

export function* logoutSaga() {
  const token = yield select(selectToken);
  try {
    yield call(logout, token);
    yield put(logoutSuccessCreator());
  } catch (e) {
    Sentry.captureException(e);
    yield put(logoutSuccessCreator());
  }
}

export function* validatePairingSaga(action: ReturnType<typeof validatePairingRequestCreator>) {
  const token = yield select(selectToken);
  try {
    yield call(validatePairing, token, action.payload.pairingToken);
  } catch (e) {
    Sentry.captureException(e);
  }
}

// Sagas Decorator

export const withAuthentication = (saga: any) =>
  function*(...args: any[]) {
    const isTokenExpired = yield select(selectIsTokenExpired);
    if (isTokenExpired) {
      try {
        yield call(refreshTokenSaga);
        yield call(saga, args[0], args[1], args[2], args[3], args[4], args[5], args[6]);
      } catch {
        yield put(logoutRequestCreator());
      }
    } else {
      yield call(saga, args[0], args[1], args[2], args[3], args[4], args[5], args[6]);
    }
  };

// Saga Watchers
function* watchAuth() {
  yield takeLatest(
    actionTypes.LOGIN.REQUEST,
    withLoader(loginSaga, SimpleLoadingKeysEnum.loggingIn),
  );
  yield takeLatest(
    getType(signUpRequestCreator),
    withLoader(signUpSaga, SimpleLoadingKeysEnum.signingIn),
  );
  yield takeLatest(
    getType(appSumoSignUpRequestCreator),
    withLoader(appSumoSignUpSaga, SimpleLoadingKeysEnum.signingIn),
  );
  yield takeLatest(
    actionTypes.RESEND_VALIDATION.REQUEST,
    withLoader(resendValidationSaga, SimpleLoadingKeysEnum.resendValidation),
  );
  yield takeLatest(getType(logoutRequestCreator), logoutSaga);
  yield takeLatest(getType(validatePairingRequestCreator), withAuthentication(validatePairingSaga));
}

// Saga export
export function* watchAuthenticationSagas() {
  yield fork(watchAuth);
}

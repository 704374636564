import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import overlayLogo from 'assets/logo.png';
import { ReactComponent as AccountIcon } from 'assets/account.svg';
import { ReactComponent as GoBackIcon } from 'assets/go-back.svg';
import { Link } from 'react-router-dom';
import Button from 'components/Atoms/Button';

import style from './OverlayHeader.module.scss';
import { isShowcaseApp } from 'services/featureToggling';

interface IHeaderProps {
  logout: () => void;
  isAuthenticated: boolean;
  goBackLinkRoute?: string | null;
  goBackLinkLabel?: string | null;
}

const OverlayHeader: React.FunctionComponent<IHeaderProps> = ({
  isAuthenticated,
  logout,
  goBackLinkRoute,
  goBackLinkLabel,
}) => {
  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  const [docAnchorEl, setDocAnchorEl] = useState(null);

  const openAccountMenu = (event: any) => {
    setAccountAnchorEl(event.currentTarget);
  };

  const openDocMenu = (event: any) => {
    setDocAnchorEl(event.currentTarget);
  };

  const onLogout = () => {
    logout();
    handleAccountPopoverClose();
  };

  const handleAccountPopoverClose = () => {
    setAccountAnchorEl(null);
  };

  const handleDocPopoverClose = () => {
    setDocAnchorEl(null);
  };

  return (
    <div className={style.headerContainer}>
      <div className={style.headerBottom}>
        <div className={style.leftContainer}>
          {goBackLinkRoute && (
            <Link to={goBackLinkRoute} className={style.projectListLink}>
              <div className={style.goBackContainer}>
                <GoBackIcon className={style.goBackIcon} />
                <p className={style.projectListLinkName}>{goBackLinkLabel}</p>
              </div>
            </Link>
          )}
          {!goBackLinkRoute && (
            <Link to={'/'}>
              <img height="35" src={overlayLogo} alt="overlay logo" />
            </Link>
          )}
        </div>
        {isAuthenticated && (
          <div className={style.rightContainer}>
            <div className={style.linkContainer}>
              <p className={style.projectListLink}>
                <p className={style.projectListLinkName} onClick={openDocMenu}>
                  Resources
                </p>
              </p>
              <Link to="/" className={style.projectListLink}>
                <p className={style.projectListLinkName}>Projects</p>
              </Link>
              <AccountIcon className={style.projectListLink} onClick={openAccountMenu} />
            </div>
          </div>
        )}
        {!isAuthenticated && isShowcaseApp() && (
          <div className={style.rightContainer}>
            <a
              href="https://web.overlay-tech.com/?ref=showcase"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button>Get started for free</Button>
            </a>
          </div>
        )}
        <Popover
          id="simple-menu"
          anchorEl={accountAnchorEl}
          anchorOrigin={{ vertical: 40, horizontal: 'left' }}
          open={Boolean(accountAnchorEl)}
          onClose={handleAccountPopoverClose}
          className={style.popoverContainer}
        >
          <div onClick={onLogout} className={style.dropDownItem}>
            <p className={style.dropdownItem}>Log out</p>
          </div>
        </Popover>
        <Popover
          id="account-menu"
          anchorEl={docAnchorEl}
          anchorOrigin={{ vertical: 40, horizontal: 'left' }}
          open={Boolean(docAnchorEl)}
          onClose={handleDocPopoverClose}
          className={style.popoverContainer}
        >
          <a
            href="https://faq.overlay-tech.com"
            target="_blank"
            rel="noopener noreferrer"
            className={style.dropDownItem}
          >
            <p className={style.dropdownItem}>Documentation</p>
          </a>
          <a
            href="https://www.figma.com/community/file/921066411285098081/Overlay-playground"
            target="_blank"
            rel="noopener noreferrer"
            className={style.dropDownItem}
          >
            <p className={style.dropdownItem}>Figma playground file</p>
          </a>
          <a
            href="https://www.figma.com/community/file/976875540221745337/Bushlife-design-system---by-Overlay-(Made-with-Auto-Layout-And-Variants)"
            target="_blank"
            rel="noopener noreferrer"
            className={style.dropDownItem}
          >
            <p className={style.dropdownItem}>Figma UI Kit</p>
          </a>
          <a
            href="https://www.youtube.com/watch?v=I6MiogRZudo"
            target="_blank"
            rel="noopener noreferrer"
            className={style.dropDownItem}
          >
            <p className={style.dropdownItem}>Tutorial video</p>
          </a>
        </Popover>
      </div>
    </div>
  );
};

export default OverlayHeader;

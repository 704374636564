import {
  ComponentInstancesStoreType,
  ComponentInstanceType,
  NormalizedComponentInstanceType,
} from './types';
import { ActionType, createAction, getType, Reducer } from 'typesafe-actions';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { withLoader } from 'modules/loading';
import { selectToken, withAuthentication } from 'modules/authentication';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import {
  getComponentInstance,
  getComponentInstanceFonts,
  updateComponentInstanceName,
  updateComponentInstanceProps,
} from 'services/api';
import { denormalizeComponentInstance, normalizeComponentInstance } from 'services/apiNormalizer';
import { displayErrorToaster } from 'modules/apiError';
import * as Sentry from '@sentry/react';
import { OverlayStoreType } from 'redux/types';
import { push } from 'connected-react-router';
import { AssetType } from 'modules/assets/types';
import {
  getComponentSetComponentSuccessCreator,
  getComponentSetSuccessCreator,
} from 'modules/componentSets';
import {
  fontChecker,
  isAGoogleFont,
  loadGoogleFont,
  loadGoogleMaterialIcons,
} from 'services/fontService';
import { delay } from 'redux-saga';
import { selectHasDismissReview } from 'modules/user';
import {
  closeReviewSectionRequestCreator,
  openReviewSectionWithTimeoutRequestCreator,
} from 'modules/reviews';
import { mergeComponentInstance } from 'services/factory/componentInstanceFactory';
import { ComponentPropsType, NormalizedComponentPropsType } from 'modules/props/types';
import { NormalizedLayerType } from 'modules/layers/types';

const initialState: ComponentInstancesStoreType = {
  map: {},
};

// Actions Creators
export const getComponentInstancesSuccessCreator = createAction(
  'COMPONENT_INSTANCES/GET_COMPONENT_INSTANCES.SUCCESS',
)<{
  componentInstances: Record<string, NormalizedComponentInstanceType>;
  assets: Record<string, AssetType>;
  layer: Record<string, NormalizedLayerType>;
  props: Record<string, NormalizedComponentPropsType>;
}>();

export const getComponentInstanceFontsRequestCreator = createAction(
  'COMPONENTS/GET_COMPONENT_INSTANCES_FONTS.REQUEST',
)<{
  componentInstanceUuid: string;
  projectUuid: string;
}>();

export const getComponentInstanceFontsSuccessCreator = createAction(
  'COMPONENTS/GET_COMPONENT_INSTANCES_FONTS.SUCCESS',
)<{
  componentInstanceUuid: string;
  missingFonts: string[];
}>();

export const getComponentInstanceRequestCreator = createAction(
  'COMPONENT_INSTANCES/GET_COMPONENT_INSTANCE.REQUEST',
)<{
  componentInstanceUuid: string;
  projectUuid: string;
}>();

export const updateComponentInstanceNameRequestCreator = createAction(
  'COMPONENT_INSTANCES/UPDATE_COMPONENT_INSTANCE_NAME.REQUEST',
)<{
  componentInstanceUuid: string;
  projectUuid: string;
  newName: string;
}>();

export const updateComponentInstancePropsRequestCreator = createAction(
  'COMPONENT_INSTANCES/UPDATE_COMPONENT_PROPS.REQUEST',
)<{
  componentInstanceUuid: string;
  props: ComponentPropsType;
  layerToRecompile: string | null;
}>();

type ComponentInstanceActions =
  | ActionType<typeof getComponentInstancesSuccessCreator>
  | ActionType<typeof getComponentInstanceFontsSuccessCreator>
  | ActionType<typeof getComponentSetComponentSuccessCreator>
  | ActionType<typeof getComponentSetSuccessCreator>;

// Selectors
export const selectComponentInstanceByUuid = (
  state: OverlayStoreType,
  ComponentInstanceUuid: string,
) => {
  return denormalizeComponentInstance(
    state.componentInstances.map[ComponentInstanceUuid],
    state.componentInstances.map,
    state.assets.map,
    state.props.map,
    state.layers.map,
  );
};

// Reducer
export const componentInstancesReducer: Reducer<any, ComponentInstanceActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getType(getComponentInstanceFontsSuccessCreator):
      const stateWithMissingFonts = { ...state };
      const componentInstanceUuid = action.payload.componentInstanceUuid;
      if (stateWithMissingFonts.map.hasOwnProperty(componentInstanceUuid)) {
        stateWithMissingFonts.map[componentInstanceUuid] = {
          ...stateWithMissingFonts.map[componentInstanceUuid],
          missingFonts: action.payload.missingFonts,
        };
      }
      return stateWithMissingFonts;
    case getType(getComponentInstancesSuccessCreator):
    case getType(getComponentSetSuccessCreator):
    case getType(getComponentSetComponentSuccessCreator):
      const newState = { ...state };
      for (const componentInstanceId in action.payload.componentInstances) {
        if (!action.payload.componentInstances.hasOwnProperty(componentInstanceId)) continue;
        if (newState.map.hasOwnProperty(componentInstanceId)) {
          newState.map[componentInstanceId] = mergeComponentInstance(
            newState.map[componentInstanceId],
            action.payload.componentInstances[componentInstanceId],
          );
        } else {
          newState.map[componentInstanceId] =
            action.payload.componentInstances[componentInstanceId];
        }
      }
      return newState;
    default:
      return state;
  }
};

// Sagas
function* getComponentInstanceSaga(action: ReturnType<typeof getComponentInstanceRequestCreator>) {
  try {
    yield put(closeReviewSectionRequestCreator());
    const token = yield select(selectToken);
    const componentInstanceUuid = action.payload.componentInstanceUuid;
    const { body: componentInstance } = yield call(
      getComponentInstance,
      token,
      componentInstanceUuid,
      action.payload.projectUuid,
    );
    const { entities } = normalizeComponentInstance(componentInstance);
    yield put(
      getComponentInstancesSuccessCreator({
        componentInstances: entities.componentInstance,
        assets: entities.asset,
        layer: entities.layer,
        props: entities.props,
      }),
    );

    yield put(getComponentInstanceFontsRequestCreator(action.payload));
    const hasDismissReview: boolean = yield select(selectHasDismissReview);
    if (!componentInstance.reviewed && !hasDismissReview) {
      yield put(openReviewSectionWithTimeoutRequestCreator());
    }
  } catch (error) {
    if (
      401 === error.response.status ||
      403 === error.response.status ||
      404 === error.response.status
    ) {
      yield put(push(`/404`));
    }
    yield put(
      displayErrorToaster({
        errorMessage: 'An error occurred while getting this component instance.',
      }),
    );
    Sentry.captureException(error);
  }
}

function* getComponentInstanceFontSaga(
  action: ReturnType<typeof getComponentInstanceFontsRequestCreator>,
) {
  try {
    const token = yield select(selectToken);
    const componentInstanceUuid = action.payload.componentInstanceUuid;
    const {
      body: fonts,
    }: {
      body: {
        fontFamily: string;
        fontWeight: number;
      }[];
    } = yield call(
      getComponentInstanceFonts,
      token,
      componentInstanceUuid,
      action.payload.projectUuid,
    );

    const googleFontsToLoad = fonts.filter(font => {
      return isAGoogleFont(font.fontFamily) || font.fontFamily === 'Material Icons';
    });

    googleFontsToLoad.forEach(googleFont => {
      if (googleFont.fontFamily === 'Material Icons') {
        loadGoogleMaterialIcons();
      } else {
        loadGoogleFont(googleFont);
      }
    });

    yield delay(5000);
    const missingFontFamilies = fonts
      .filter(font => {
        return !fontChecker.isFontAvailable(font.fontFamily);
      })
      .map(font => font.fontFamily);

    yield put(
      getComponentInstanceFontsSuccessCreator({
        componentInstanceUuid,
        missingFonts: missingFontFamilies,
      }),
    );
  } catch (error) {
    if (
      401 === error.response.status ||
      403 === error.response.status ||
      404 === error.response.status
    ) {
      yield put(push(`/404`));
      return;
    }
    yield put(
      displayErrorToaster({ errorMessage: 'An error occurred while getting this component.' }),
    );
    Sentry.captureException(error);
  }
}

function* updateComponentInstanceNameSaga(
  action: ReturnType<typeof updateComponentInstanceNameRequestCreator>,
) {
  try {
    const token = yield select(selectToken);
    const { body: component } = yield call(
      updateComponentInstanceName,
      token,
      action.payload.componentInstanceUuid,
      action.payload.projectUuid,
      action.payload.newName,
    );
    const { entities } = normalizeComponentInstance(component);
    yield put(
      getComponentInstancesSuccessCreator({
        componentInstances: entities.componentInstance,
        assets: entities.asset,
        layer: entities.layer,
        props: entities.props,
      }),
    );
  } catch (e) {
    yield put(
      displayErrorToaster({
        errorMessage: 'An error occurred while updating this component instance name.',
      }),
    );
    Sentry.captureException(e);
  }
}

function* updateComponentInstancePropsSaga(
  action: ReturnType<typeof updateComponentInstancePropsRequestCreator>,
) {
  try {
    const propsUuid = action.payload.props.uuid;
    const token = yield select(selectToken);
    const selectedComponent: ComponentInstanceType = yield select(
      selectComponentInstanceByUuid,
      action.payload.componentInstanceUuid,
    );

    selectedComponent.props = selectedComponent.props.map(prop => {
      if (prop.uuid === propsUuid) {
        prop.active = action.payload.props.active;
      }
      return prop;
    });

    // Optimistic rendering
    const selectedComponentInstanceNormalized = normalizeComponentInstance(selectedComponent);
    yield put(
      getComponentInstancesSuccessCreator({
        componentInstances: selectedComponentInstanceNormalized.entities.componentInstance,
        assets: selectedComponentInstanceNormalized.entities.asset,
        layer: selectedComponentInstanceNormalized.entities.layer,
        props: selectedComponentInstanceNormalized.entities.props,
      }),
    );

    const { body: component } = yield call(
      updateComponentInstanceProps,
      token,
      action.payload.componentInstanceUuid,
      selectedComponent.props,
    );
    const { entities } = normalizeComponentInstance(component);
    yield put(
      getComponentInstancesSuccessCreator({
        componentInstances: entities.componentInstance,
        assets: entities.asset,
        layer: entities.layer,
        props: entities.props,
      }),
    );
  } catch (e) {
    yield put(
      displayErrorToaster({
        errorMessage: 'An error occurred while updating this component instance props.',
      }),
    );
    Sentry.captureException(e);
  }
}

// Saga Watchers

function* watchGetComponentInstance() {
  yield takeLatest(
    getType(getComponentInstanceRequestCreator),
    withLoader(
      withAuthentication(getComponentInstanceSaga),
      SimpleLoadingKeysEnum.getComponentInstance,
    ),
  );
}

function* watchUpdateComponentInstanceName() {
  yield takeLatest(
    getType(updateComponentInstanceNameRequestCreator),
    withLoader(
      withAuthentication(updateComponentInstanceNameSaga),
      SimpleLoadingKeysEnum.updateComponentInstanceName,
    ),
  );
}

function* watchUpdateComponentInstanceProps() {
  yield takeLatest(
    getType(updateComponentInstancePropsRequestCreator),
    withLoader(
      withAuthentication(updateComponentInstancePropsSaga),
      SimpleLoadingKeysEnum.updateComponentInstanceProps,
    ),
  );
}

function* watchGetComponentInstanceFonts() {
  yield takeLatest(
    getType(getComponentInstanceFontsRequestCreator),
    withAuthentication(getComponentInstanceFontSaga),
  );
}

// Saga export
export function* watchComponentInstanceSagas() {
  yield fork(watchGetComponentInstance);
  yield fork(watchGetComponentInstanceFonts);
  yield fork(watchUpdateComponentInstanceName);
  yield fork(watchUpdateComponentInstanceProps);
}

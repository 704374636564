/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux';
import { loadingReducer } from 'modules/loading';
import { componentsReducer } from 'modules/components';
import { componentSetsReducer } from 'modules/componentSets';
import { componentInstancesReducer } from 'modules/componentInstances';
import { authenticationReducer } from 'modules/authentication';
import { authenticationErrorReducer } from 'modules/authenticationError';
import { modalsReducer } from 'modules/modals';
import { assetsReducer } from 'modules/assets';
import { stylesheetReducer } from 'modules/stylesheet';
import { projectsReducer } from 'modules/projects';
import { resetPasswordReducer } from 'modules/resetPassword';
import { validateAccountReducer } from 'modules/validateAccount';
import { categoriesReducer } from 'modules/categories';
import { userReducer } from 'modules/user';
import { subscriptionReducer } from 'modules/subscription';
import { apiErrorReducer } from 'modules/apiError';
import { reviewsReducer } from 'modules/reviews';
import { connectRouter } from 'connected-react-router';
import { teamsReducer } from 'modules/teams';
import { layersReducer } from 'modules/layers/layers';
import { propsReducer } from 'modules/props';
import { History } from 'history';

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer(history: History) {
  return combineReducers({
    router: connectRouter(history),
    authentication: authenticationReducer,
    user: userReducer,
    loading: loadingReducer,
    componentSets: componentSetsReducer,
    components: componentsReducer,
    componentInstances: componentInstancesReducer,
    layers: layersReducer,
    reviews: reviewsReducer,
    categories: categoriesReducer,
    stylesheets: stylesheetReducer,
    assets: assetsReducer,
    props: propsReducer,
    teams: teamsReducer,
    projects: projectsReducer,
    authenticationError: authenticationErrorReducer,
    apiError: apiErrorReducer,
    modals: modalsReducer,
    subscription: subscriptionReducer,
    resetPassword: resetPasswordReducer,
    validateAccount: validateAccountReducer,
  } as any);
}

import React, { MouseEventHandler, ReactNode } from 'react';
import classnames from 'classnames';
import styles from './Tab.module.scss';
import { ClassValue } from 'classnames/types';

interface IProps {
  children: ReactNode;
  isSelected?: boolean;
  isActive?: boolean;
  onClick?: MouseEventHandler;
  className?: ClassValue;
}

const Tab: React.FunctionComponent<IProps> = ({
  children,
  onClick,
  isSelected,
  isActive,
  className,
}) => {
  return (
    <div
      className={classnames(
        styles.tab,
        isSelected && styles.selected,
        className,
        isActive && styles.isActive,
      )}
      onClick={onClick}
    >
      <span className={styles.tabLabel}>{children}</span>
    </div>
  );
};

export default Tab;

import { normalize, denormalize, schema, NormalizedSchema } from 'normalizr';
import { NormalizedProjectType, ProjectType } from 'modules/projects/types';
import { AssetType } from 'modules/assets/types';
import { StylesheetType } from 'modules/stylesheet/types';
import { ComponentType, NormalizedComponentType } from 'modules/components/types';
import { CategoryType } from 'modules/categories/types';
import {
  ComponentInstanceType,
  NormalizedComponentInstanceType,
} from 'modules/componentInstances/types';
import { create as createComponent } from './factory/componentFactory';
import { create as createComponentInstance } from './factory/componentInstanceFactory';
import { create as createComponentSet } from './factory/componentSetFactory';
import { create as createStylesheet } from './factory/stylesheetFactory';
import { create as createTeam } from './factory/teamFactory';
import { create as createLayer } from './factory/layerFactory';
import { ComponentSetType, NormalizedComponentSetType } from 'modules/componentSets/types';
import { NormalizedTeamType, TeamType } from 'modules/teams/types';
import { LayerType, NormalizedLayerType } from 'modules/layers/types';
import { ComponentPropsType, NormalizedComponentPropsType } from 'modules/props/types';

const categorySchema = new schema.Entity(
  'category',
  {},
  {
    idAttribute: 'uuid',
  },
);

const assetSchema = new schema.Entity(
  'asset',
  {},
  {
    idAttribute: 'uuid',
  },
);

const propsSchema = new schema.Entity(
  'props',
  {},
  {
    idAttribute: 'uuid',
  },
);

const layerSchema = new schema.Entity(
  'layer',
  {
    assets: [assetSchema],
    props: propsSchema,
  },
  {
    idAttribute: 'uuid',
    processStrategy: entity => createLayer(entity),
  },
);

const layersSchema = new schema.Array(layerSchema);
layerSchema.define({ children: layersSchema });

const componentInstanceSchema = new schema.Entity(
  'componentInstance',
  {
    assets: [assetSchema],
    props: [propsSchema],
    rootLayer: layerSchema,
  },
  {
    idAttribute: 'uuid',
    processStrategy: entity => createComponentInstance(entity),
  },
);

const componentInstancesSchema = new schema.Array(componentInstanceSchema);
componentInstanceSchema.define({ dependencies: componentInstancesSchema });
layerSchema.define({ dependency: componentInstanceSchema });

const componentSchema = new schema.Entity(
  'component',
  {
    dependencies: componentInstancesSchema,
    assets: [assetSchema],
    props: [propsSchema],
    rootLayer: layerSchema,
  },
  {
    idAttribute: 'uuid',
    processStrategy: entity => createComponent(entity),
  },
);

const componentSetSchema = new schema.Entity(
  'componentSet',
  {
    category: categorySchema,
  },
  {
    idAttribute: 'uuid',
    processStrategy: entity => createComponentSet(entity),
  },
);

const stylesheetSchema = new schema.Entity(
  'stylesheet',
  {},
  {
    idAttribute: 'uuid',
    processStrategy: entity => createStylesheet(entity),
  },
);

const asset = new schema.Entity(
  'asset',
  {},
  {
    idAttribute: 'uuid',
  },
);

const projectSchema = new schema.Entity(
  'project',
  {
    componentSets: [componentSetSchema],
    categories: [categorySchema],
    stylesheet: stylesheetSchema,
  },
  {
    idAttribute: 'uuid',
  },
);

const teamSchema = new schema.Entity(
  'team',
  {},
  {
    idAttribute: 'uuid',
    processStrategy: entity => createTeam(entity),
  },
);

export const normalizeProjects = (
  project: ProjectType[],
): NormalizedSchema<
  {
    project: Record<string, NormalizedProjectType>;
    componentSet: Record<string, NormalizedComponentSetType>;
    stylesheet: Record<string, StylesheetType>;
    category: Record<string, CategoryType>;
  },
  any
> => normalize(project, [projectSchema]);

export const normalizeTeam = (
  team: TeamType,
): NormalizedSchema<
  {
    team: Record<string, NormalizedTeamType>;
  },
  any
> => normalize(team, teamSchema);

export const normalizeTeams = (
  teams: TeamType[],
): NormalizedSchema<
  {
    team: Record<string, NormalizedTeamType>;
  },
  any
> => normalize(teams, [teamSchema]);

export const normalizeAssets = (
  assets: AssetType[],
): NormalizedSchema<{ asset: Record<string, AssetType> }, any> => normalize(assets, [asset]);

export const normalizeProject = (
  project: ProjectType,
): NormalizedSchema<
  {
    project: Record<string, NormalizedProjectType>;
    componentSet: Record<string, NormalizedComponentSetType>;
    category: Record<string, CategoryType>;
    stylesheet: Record<string, StylesheetType>;
  },
  any
> => normalize(project, projectSchema);

export const normalizeCategory = (
  category: CategoryType,
): NormalizedSchema<{ category: Record<string, CategoryType> }, any> =>
  normalize(category, categorySchema);

export const normalizeComponentSet = (
  componentSet: ComponentSetType,
): NormalizedSchema<
  {
    componentSet: Record<string, NormalizedComponentSetType>;
  },
  any
> => normalize(componentSet, componentSetSchema);

export const normalizeComponent = (
  component: ComponentType,
): NormalizedSchema<
  {
    component: Record<string, NormalizedComponentType>;
    componentInstance: Record<string, NormalizedComponentInstanceType>;
    asset: Record<string, AssetType>;
    props: Record<string, NormalizedComponentPropsType>;
    layer: Record<string, NormalizedLayerType>;
  },
  any
> => normalize(component, componentSchema);

export const normalizeComponentInstance = (
  componentInstance: ComponentInstanceType,
): NormalizedSchema<
  {
    componentInstance: Record<string, NormalizedComponentInstanceType>;
    asset: Record<string, AssetType>;
    props: Record<string, NormalizedComponentPropsType>;
    layer: Record<string, NormalizedLayerType>;
  },
  any
> => normalize(componentInstance, componentInstanceSchema);

export const normalizeStylesheet = (
  stylesheet: StylesheetType,
): NormalizedSchema<{ stylesheet: Record<string, StylesheetType> }, any> =>
  normalize(stylesheet, stylesheetSchema);

export const denormalizeComponent = (
  componentNormalized: NormalizedComponentType,
  componentMap: Record<string, NormalizedComponentType>,
  componentInstancesMap: Record<string, NormalizedComponentInstanceType>,
  assetsMap: Record<string, AssetType>,
  propsMap: Record<string, NormalizedComponentPropsType>,
  layersMap: Record<string, NormalizedLayerType>,
): ComponentType =>
  denormalize(componentNormalized, componentSchema, {
    component: componentMap,
    componentInstance: componentInstancesMap,
    asset: assetsMap,
    props: propsMap,
    layer: layersMap,
  });

export const denormalizeLayer = (
  layerData: NormalizedLayerType,
  layersMap: Record<string, NormalizedLayerType>,
  componentInstanceMap: Record<string, NormalizedComponentInstanceType>,
  assetsMap: Record<string, AssetType>,
  propsMap: Record<string, ComponentPropsType>,
): LayerType =>
  denormalize(layerData, layerSchema, {
    layer: layersMap,
    componentInstance: componentInstanceMap,
    asset: assetsMap,
    props: propsMap,
  });

export const denormalizeComponentSet = (
  componentSetNormalized: NormalizedComponentSetType,
  componentSetMap: Record<string, NormalizedComponentSetType>,
  categoryMap: Record<string, CategoryType>,
): ComponentSetType =>
  denormalize(componentSetNormalized, componentSetSchema, {
    componentSet: componentSetMap,
    category: categoryMap,
  });

export const denormalizeComponentInstance = (
  componentInstanceNormalized: NormalizedComponentInstanceType,
  componentInstanceMap: Record<string, NormalizedComponentInstanceType>,
  assetsMap: Record<string, AssetType>,
  propsMap: Record<string, NormalizedComponentPropsType>,
  layersMap: Record<string, NormalizedLayerType>,
): ComponentInstanceType =>
  denormalize(componentInstanceNormalized, componentInstanceSchema, {
    componentInstance: componentInstanceMap,
    asset: assetsMap,
    layer: layersMap,
    props: propsMap,
  });

export const denormalizeProject = (
  projectNormalized: NormalizedProjectType,
  projectMap: Record<string, NormalizedProjectType>,
  componentSetMap: Record<string, NormalizedComponentSetType>,
  categoryMap: Record<string, CategoryType>,
  stylesheetMap: Record<string, StylesheetType>,
): ProjectType =>
  denormalize(projectNormalized, projectSchema, {
    project: projectMap,
    componentSet: componentSetMap,
    category: categoryMap,
    stylesheet: stylesheetMap,
  });

export const denormalizeProjects = (
  projectsNormalized: NormalizedProjectType[],
  projectMap: Record<string, NormalizedProjectType>,
  componentSetMap: Record<string, NormalizedComponentSetType>,
): {
  project: ProjectType[];
} =>
  denormalize({ project: projectsNormalized }, [projectSchema], {
    project: projectMap,
    componentSet: componentSetMap,
  });

export const denormalizeTeams = (
  teamsNormalized: NormalizedTeamType[],
  teamMap: Record<string, NormalizedTeamType>,
): {
  team: TeamType[];
} =>
  denormalize({ team: teamsNormalized }, [teamSchema], {
    team: teamMap,
  });

export const denormalizeTeam = (
  teamNormalized: NormalizedTeamType,
  teamMap: Record<string, NormalizedTeamType>,
): {
  team: TeamType;
} =>
  denormalize({ team: teamNormalized }, teamSchema, {
    team: teamMap,
  });

import { PropsStoreType } from './types';
import { ActionType, getType, Reducer } from 'typesafe-actions';
import {
  getComponentSetComponentSuccessCreator,
  getComponentSetSuccessCreator,
} from 'modules/componentSets';
import { getComponentsSuccessCreator } from 'modules/components';
import { getComponentInstancesSuccessCreator } from 'modules/componentInstances';

const initialState: PropsStoreType = {
  map: {},
};

// Actions Creators
type PropsActions = ActionType<
  | typeof getComponentsSuccessCreator
  | typeof getComponentInstancesSuccessCreator
  | typeof getComponentSetSuccessCreator
  | typeof getComponentSetComponentSuccessCreator
>;

// Reducer
export const propsReducer: Reducer<PropsStoreType, PropsActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getType(getComponentsSuccessCreator):
    case getType(getComponentSetSuccessCreator):
    case getType(getComponentSetComponentSuccessCreator):
    case getType(getComponentInstancesSuccessCreator):
      return { ...state, map: { ...state.map, ...action.payload.props } };
    default:
      return state;
  }
};

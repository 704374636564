import { takeLatest, call, select, put, fork } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { selectToken, withAuthentication } from 'modules/authentication';
import {
  getProjectStylesheetSuccessCreator,
  getProjectSuccessCreator,
  selectCurrentProject,
} from 'modules/projects';
import { withLoader } from 'modules/loading/loading';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import { normalizeStylesheet } from 'services/apiNormalizer';
import {
  updateProjectStylesheet,
  getProjectStylesheet,
  deleteProjectStylesheetVariables,
} from 'services/api';
import {
  StylesheetStoreType,
  FrontVariableInputType,
  StylesheetType,
  VariableValidationType,
  FrontVariableStateEnum,
  FrontVariableChangeTypeEnum,
  BackErrorValidationMapping,
} from 'modules/stylesheet/types';
import { OverlayStoreType } from 'redux/types';
import { ProjectType } from 'modules/projects/types';
import { ActionType, createAction, getType } from 'typesafe-actions';
import * as Sentry from '@sentry/react';
import { Reducer } from 'redux';
import { displayErrorToaster } from 'modules/apiError';
import { mergeStylesheet } from 'services/factory/stylesheetFactory';

const initialState: StylesheetStoreType = {
  map: {},
  colorChanges: {},
  typoChanges: {},
  isCommitStylesheetChangeSuccessful: false,
};

// Actions Creators
export const getStylesheetSuccessCreator = createAction('STYLESHEET/GET_STYLESHEET.SUCCESS')<{
  stylesheet: Record<string, StylesheetType>;
}>();

export const getProjectStylesheetRequestCreator = createAction(
  'STYLESHEET/GET_STYLESHEET.REQUEST',
)<{
  projectUuid: string;
}>();

export const changeColorNameCreator = createAction('STYLESHEET/COLOR_CHANGES.CHANGE_NAME')<{
  stylesheetUuid: string;
  colorId: number;
  newName: string;
}>();

export const removeColorCreator = createAction('STYLESHEET/COLOR_CHANGES.REMOVE_COLOR')<{
  colorId: number;
}>();

export const removeTypoCreator = createAction('STYLESHEET/TYPO_CHANGES.REMOVE_COLOR')<{
  typoId: number;
}>();

export const changeTypoNameCreator = createAction('STYLESHEET/TYPO_CHANGES.CHANGE_NAME')<{
  stylesheetUuid: string;
  typoId: number;
  newName: string;
}>();

export const updateStylesheetRequestCreator = createAction(
  'STYLESHEET/UPDATE_STYLESHEET.REQUEST',
)();

export const updateStylesheetSuccessCreator = createAction(
  'STYLESHEET/UPDATE_STYLESHEET.SUCCESS',
)();

export const resetCommitStylesheetSuccessCreator = createAction(
  'STYLESHEET/RESET_COMMIT.SUCCESS',
)();

export const resetStylesheetChangesCreator = createAction('STYLESHEET/RESET_CHANGES.REQUEST')();

export const addColorErrorsCreator = createAction('STYLESHEET/COLOR_ERRORS.ADD')<{
  errors: Record<number, FrontVariableStateEnum>;
}>();

export const addTypoErrorsCreator = createAction('STYLESHEET/TYPOS_ERRORS.ADD')<{
  errors: Record<number, FrontVariableStateEnum>;
}>();

// Selectors

export const selectCurrentStylesheet = (store: OverlayStoreType) => {
  const currentProject = selectCurrentProject(store);
  return currentProject && currentProject.stylesheet
    ? store.stylesheets.map[currentProject.stylesheet]
    : null;
};

export const selectIsCommitStylesheetChangesSuccessful = (store: OverlayStoreType) =>
  store.stylesheets.isCommitStylesheetChangeSuccessful;

export const selectColorChanges = (store: OverlayStoreType) => store.stylesheets.colorChanges;
export const selectTypoChanges = (store: OverlayStoreType) => store.stylesheets.typoChanges;
export const selectValidTypoChanges = (store: OverlayStoreType) =>
  Object.values(store.stylesheets.typoChanges).filter(
    ({ validationState }) => validationState === FrontVariableStateEnum.VALID,
  );
export const selectValidColorChanges = (store: OverlayStoreType) =>
  Object.values(store.stylesheets.colorChanges).filter(
    ({ validationState }) => validationState === FrontVariableStateEnum.VALID,
  );

export const selectStylesheetHasInvalidChanges = (store: OverlayStoreType) =>
  [
    ...Object.values(store.stylesheets.colorChanges),
    ...Object.values(store.stylesheets.typoChanges),
  ].filter(
    ({ validationState }) =>
      validationState === FrontVariableStateEnum.DUPLICATE_NAME_ERROR ||
      validationState === FrontVariableStateEnum.INVALID_NAME_ERROR,
  ).length > 0;
export const selectCurrentStylesheetTextColors = (store: OverlayStoreType) => {
  const stylesheet = selectCurrentStylesheet(store);
  if (!stylesheet) return [];
  return stylesheet.colors.filter(color => color.type === 'text');
};

export const selectCurrentStylesheetColors = (store: OverlayStoreType) => {
  const stylesheet = selectCurrentStylesheet(store);
  if (!stylesheet) return [];
  return stylesheet.colors.filter(color => color.type === 'other');
};

export const selectCurrentStylesheetTypos = (store: OverlayStoreType) => {
  const stylesheet = selectCurrentStylesheet(store);
  if (!stylesheet) return [];
  return stylesheet.typographies;
};

export const selectIsCurrentStylesheetEmpty = (store: OverlayStoreType) => {
  const stylesheet = selectCurrentStylesheet(store);
  if (!stylesheet) return true;
  return stylesheet.typographies.length === 0 && stylesheet.colors.length === 0;
};

// Validator
const getValidationStateNewVariableName = (
  newName: string,
  initialName: string,
  stylesheetUuid: string,
  state: StylesheetStoreType,
): FrontVariableStateEnum => {
  if (newName === initialName) return FrontVariableStateEnum.NOT_MODIFIED;
  if (newName === '') return FrontVariableStateEnum.INVALID_NAME_ERROR;

  // 1. Check if the name is right formatted
  const camelCaseOrKebabCaseRegex = new RegExp('^[a-z][a-zA-Z0-9]*([-_]?[a-zA-Z0-9])*$');
  if (!camelCaseOrKebabCaseRegex.test(newName.trim()))
    return FrontVariableStateEnum.INVALID_NAME_ERROR;

  // 2. Check with existing stylesheet name
  const variableNames = [
    ...state.map[stylesheetUuid].colors.map(color => color.name),
    ...state.map[stylesheetUuid].typographies.map(typo => typo.name),
  ];

  if (variableNames.includes(newName)) return FrontVariableStateEnum.DUPLICATE_NAME_ERROR;

  // 3. Check with new variables name
  const newColorNames = Object.values(state.colorChanges).map(color => color.name);
  const newTypoNames = Object.values(state.typoChanges).map(typo => typo.name);

  if ([...newColorNames, ...newTypoNames].includes(newName))
    return FrontVariableStateEnum.DUPLICATE_NAME_ERROR;

  return FrontVariableStateEnum.VALID;
};

type StylesheetActions =
  | ActionType<typeof getStylesheetSuccessCreator>
  | ActionType<typeof getProjectSuccessCreator>
  | ActionType<typeof updateStylesheetSuccessCreator>
  | ActionType<typeof resetStylesheetChangesCreator>
  | ActionType<typeof resetCommitStylesheetSuccessCreator>
  | ActionType<typeof addTypoErrorsCreator>
  | ActionType<typeof addColorErrorsCreator>
  | ActionType<typeof removeColorCreator>
  | ActionType<typeof removeTypoCreator>
  | ActionType<typeof changeTypoNameCreator>
  | ActionType<typeof changeColorNameCreator>;

// Reducer
export const stylesheetReducer: Reducer<any, StylesheetActions> = (
  state: StylesheetStoreType = initialState,
  action,
) => {
  switch (action.type) {
    case getType(getStylesheetSuccessCreator):
    case getType(getProjectSuccessCreator):
      const newStylesheetMapState = { ...state };
      for (const componentId in action.payload.stylesheet) {
        if (!action.payload.stylesheet.hasOwnProperty(componentId)) continue;
        if (newStylesheetMapState.map.hasOwnProperty(componentId)) {
          newStylesheetMapState.map[componentId] = mergeStylesheet(
            newStylesheetMapState.map[componentId],
            action.payload.stylesheet[componentId],
          );
        } else {
          newStylesheetMapState.map[componentId] = action.payload.stylesheet[componentId];
        }
      }
      return newStylesheetMapState;
    case getType(updateStylesheetSuccessCreator):
      return {
        ...state,
        colorChanges: {},
        typoChanges: {},
        isCommitStylesheetChangesSuccessful: true,
      };
    case getType(resetStylesheetChangesCreator):
      return {
        ...state,
        colorChanges: {},
        typoChanges: {},
      };
    case getType(changeColorNameCreator):
      const initialColor = state.map[action.payload.stylesheetUuid].colors.find(
        color => color.id === action.payload.colorId,
      );
      const newColorValidationState = getValidationStateNewVariableName(
        action.payload.newName,
        initialColor ? initialColor.name : '',
        action.payload.stylesheetUuid,
        state,
      );
      return {
        ...state,
        colorChanges: {
          ...state.colorChanges,
          [action.payload.colorId]: {
            id: action.payload.colorId,
            name: action.payload.newName,
            type: FrontVariableChangeTypeEnum.UPDATE,
            validationState: newColorValidationState,
          },
        },
      };
    case getType(removeColorCreator):
      return {
        ...state,
        colorChanges: {
          ...state.colorChanges,
          [action.payload.colorId]: {
            id: action.payload.colorId,
            name: '',
            type: FrontVariableChangeTypeEnum.DELETE,
            validationState: FrontVariableStateEnum.VALID,
          },
        },
      };
    case getType(changeTypoNameCreator):
      const initialTypo = state.map[action.payload.stylesheetUuid].typographies.find(
        typo => typo.id === action.payload.typoId,
      );
      const newTypoValidationState = getValidationStateNewVariableName(
        action.payload.newName,
        initialTypo ? initialTypo.name : '',
        action.payload.stylesheetUuid,
        state,
      );
      return {
        ...state,
        typoChanges: {
          ...state.typoChanges,
          [action.payload.typoId]: {
            id: action.payload.typoId,
            name: action.payload.newName,
            type: FrontVariableChangeTypeEnum.UPDATE,
            validationState: newTypoValidationState,
          },
        },
      };
    case getType(removeTypoCreator):
      return {
        ...state,
        typoChanges: {
          ...state.typoChanges,
          [action.payload.typoId]: {
            id: action.payload.typoId,
            name: '',
            type: FrontVariableChangeTypeEnum.DELETE,
            validationState: FrontVariableStateEnum.VALID,
          },
        },
      };
    case getType(resetCommitStylesheetSuccessCreator):
      return {
        ...state,
        isCommitStylesheetChangesSuccessful: false,
      };
    case getType(addColorErrorsCreator):
      const newColorState = { ...state };
      let colorErrors: Record<number, FrontVariableStateEnum> = action.payload.errors;
      for (const colorUid in colorErrors) {
        if (!newColorState.colorChanges.hasOwnProperty(colorUid)) continue;
        newColorState.colorChanges[colorUid] = {
          ...newColorState.colorChanges[colorUid],
          validationState: action.payload.errors[colorUid],
        };
      }
      return newColorState;
    case getType(addTypoErrorsCreator):
      const newTypoState = { ...state };
      let typoErrors: Record<number, FrontVariableStateEnum> = action.payload.errors;
      for (const typoUid in typoErrors) {
        if (!newTypoState.typoChanges.hasOwnProperty(typoUid)) continue;
        newTypoState.typoChanges[typoUid] = {
          ...newTypoState.typoChanges[typoUid],
          validationState: action.payload.errors[typoUid],
        };
      }
      return newTypoState;
    default:
      return state;
  }
};

// Sagas
function* commitStylesheetChangesSaga() {
  try {
    const currentProject = yield select(selectCurrentProject);
    const validTypoChanges = yield select(selectValidTypoChanges);
    const validColorChanges = yield select(selectValidColorChanges);
    const token = yield select(selectToken);
    const getUpdates = (changes: FrontVariableInputType[]) =>
      changes.filter(({ type }) => type === FrontVariableChangeTypeEnum.UPDATE);
    const getDeletes = (changes: FrontVariableInputType[]) =>
      changes.filter(({ type }) => type === FrontVariableChangeTypeEnum.DELETE);

    // Delete
    const colorsToRemove = getDeletes(validColorChanges).map(colorToRemove => ({
      id: colorToRemove.id,
    }));
    const typosToRemove = getDeletes(validTypoChanges).map(typoToRemove => ({
      id: typoToRemove.id,
    }));

    if (typosToRemove.length + colorsToRemove.length > 0) {
      const stylesheetAfterRemoveResponse = yield call(
        deleteProjectStylesheetVariables,
        currentProject.uuid,
        token,
        {
          colors: colorsToRemove,
          typos: typosToRemove,
        },
      );
      const { entities } = normalizeStylesheet(stylesheetAfterRemoveResponse.body);
      yield put(getStylesheetSuccessCreator({ stylesheet: entities.stylesheet }));
    }

    // Update
    const colorsToUpdate = getUpdates(validColorChanges).map(color => ({
      id: color.id,
      name: color.name,
    }));
    const typosToUpdate = getUpdates(validTypoChanges).map(typo => ({
      id: typo.id,
      name: typo.name,
    }));

    if (colorsToUpdate.length + typosToUpdate.length > 0) {
      const stylesheetAfterUpdateResponse = yield call(
        updateProjectStylesheet,
        currentProject.uuid,
        token,
        {
          colors: colorsToUpdate,
          typos: typosToUpdate,
        },
      );
      const { entities } = normalizeStylesheet(stylesheetAfterUpdateResponse.body);
      yield put(getStylesheetSuccessCreator({ stylesheet: entities.stylesheet }));
    }

    yield put(updateStylesheetSuccessCreator());
    yield delay(1000);
    yield put(resetCommitStylesheetSuccessCreator());
  } catch (error) {
    if (400 !== error.response.status) {
      Sentry.captureException(error);
      yield put(
        displayErrorToaster({ errorMessage: 'An error occurred while updating the stylesheet.' }),
      );
      return;
    }
    const validationErrors = error.response.body;
    const colorsErrors = transformUpdateStylesheetErrorToStatusUpdate(
      validationErrors.colors.errors,
    );
    const typosErrors = transformUpdateStylesheetErrorToStatusUpdate(
      validationErrors.typographies.errors,
    );
    yield put(addColorErrorsCreator({ errors: colorsErrors }));
    yield put(addTypoErrorsCreator({ errors: typosErrors }));
  }
}

const transformUpdateStylesheetErrorToStatusUpdate = (errors: VariableValidationType[]) => {
  return errors.reduce(
    (map: Record<number, FrontVariableStateEnum>, error: VariableValidationType) => {
      map[error.data.id] = BackErrorValidationMapping[error.message] as FrontVariableStateEnum;
      return map;
    },
    {},
  );
};

function* getProjectStylesheetSaga(action: ReturnType<typeof getProjectStylesheetRequestCreator>) {
  try {
    const token = yield select(selectToken);
    const projectUuid = action.payload.projectUuid;
    const { body }: { body: StylesheetType } = yield call(getProjectStylesheet, token, projectUuid);
    const { entities } = normalizeStylesheet(body);
    yield put(getStylesheetSuccessCreator({ stylesheet: entities.stylesheet }));
    yield put(
      getProjectStylesheetSuccessCreator({
        project: {
          uuid: projectUuid,
          stylesheet: body.uuid,
        } as ProjectType,
      }),
    );
  } catch (e) {
    yield put(
      displayErrorToaster({ errorMessage: 'An error occurred while getting your stylesheet.' }),
    );
    Sentry.captureException(e);
  }
}

// Saga Watchers
function* watchGetStylesheetRequest() {
  yield takeLatest(
    getType(getProjectStylesheetRequestCreator),
    withLoader(withAuthentication(getProjectStylesheetSaga), SimpleLoadingKeysEnum.getStyleSheet),
  );
}

function* watchCommitStylesheetChanges() {
  yield takeLatest(
    getType(updateStylesheetRequestCreator),
    withLoader(
      withAuthentication(commitStylesheetChangesSaga),
      SimpleLoadingKeysEnum.updateStyleSheet,
    ),
  );
}

// Saga export
export function* watchStylesheetSagas() {
  yield fork(watchGetStylesheetRequest);
  yield fork(watchCommitStylesheetChanges);
}

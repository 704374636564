import {
  addProjectContributorRequestCreator,
  changeProjectCategoryOrderRequestCreator,
  changeProjectCategoryOrderSuccessCreator,
  createProjectRequestCreator,
  createProjectsSuccessCreator,
  deleteProjectRequestCreator,
  deleteProjectSuccessCreator,
  getProjectErrorCreator,
  getProjectRequestCreator,
  getProjectsRequestCreator,
  getProjectSuccessCreator,
  removeProjectContributorRequestCreator,
  updateProjectBetaUserRequestCreator,
  updateProjectBetaUserSuccessCreator,
  updateSettingsRequestCreator,
  updateSettingsSuccessCreator,
} from './actions';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { selectToken, withAuthentication } from 'modules/authentication';
import {
  addProjectContributor,
  changeCategoryOrder,
  createProject,
  deleteProject,
  getProjectDetail,
  getUserProjects,
  removeProjectContributor,
  updateProjectBetaUser,
  updateProjectSettings,
} from 'services/api';
import { closeModalCreator, openModalCreator } from 'modules/modals';
import { ModalKeysEnum } from 'modules/modals/types';
import { push } from 'connected-react-router';
import * as Sentry from '@sentry/react';
import { displayErrorToaster } from 'modules/apiError';
import { normalizeProject, normalizeProjects } from 'services/apiNormalizer';
import { getType } from 'typesafe-actions';
import { withLoader, withRemoveInviteLoader } from 'modules/loading';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';

export function* createProjectSaga(action: ReturnType<typeof createProjectRequestCreator>) {
  const token = yield select(selectToken);
  try {
    const { body } = yield call(
      createProject,
      token,
      action.payload.name,
      action.payload.targetTechnology,
      action.payload.uiFramework,
      action.payload.designTool,
      action.payload.teamUuid,
    );
    yield put(
      createProjectsSuccessCreator({
        project: body,
      }),
    );
    yield put(closeModalCreator(ModalKeysEnum.CREATE_PROJECT)());
    yield put(push(`/project/${body.uuid}/components`));
  } catch (e) {
    Sentry.captureException(e);
    // The user don't have any project credit
    yield put(closeModalCreator(ModalKeysEnum.CREATE_PROJECT)());
    if (e.status === 403) {
      yield put(openModalCreator(ModalKeysEnum.UPGRADE_PLAN)());
      return;
    }
    yield put(
      displayErrorToaster({ errorMessage: 'An error occurred while creating your projects.' }),
    );
  }
}

export function* getProjectsSaga() {
  try {
    const token = yield select(selectToken);
    const { body } = yield call(getUserProjects, token);
    const { entities } = normalizeProjects(body);
    yield put(
      getProjectSuccessCreator({
        project: entities.project,
        componentSet: entities.componentSet,
        stylesheet: entities.stylesheet,
        category: entities.category,
      }),
    );
  } catch (e) {
    yield put(
      displayErrorToaster({ errorMessage: 'An error occurred while getting your projects.' }),
    );
    Sentry.captureException(e);
  }
}

export function* getProjectSaga(action: ReturnType<typeof getProjectRequestCreator>) {
  const token = yield select(selectToken);
  try {
    const { body } = yield call(getProjectDetail, token, action.payload.projectUuid);
    const { entities } = normalizeProject(body);
    yield put(
      getProjectSuccessCreator({
        project: entities.project,
        componentSet: entities.componentSet,
        stylesheet: entities.stylesheet,
        category: entities.category,
      }),
    );
  } catch (e) {
    Sentry.captureException(e);
    yield put(getProjectErrorCreator({ getProjectErrorCode: e.status }));
  }
}

export function* deleteProjectSaga(action: ReturnType<typeof deleteProjectRequestCreator>) {
  try {
    const token = yield select(selectToken);
    yield call(deleteProject, token, action.payload.projectUuid);
    yield put(deleteProjectSuccessCreator({ projectUuid: action.payload.projectUuid }));
    yield put(closeModalCreator(ModalKeysEnum.DELETE_PROJECT)());
  } catch (e) {
    yield put(
      displayErrorToaster({ errorMessage: 'An error occurred while deleting your project.' }),
    );
    Sentry.captureException(e);
  }
}

export function* updateProjectSettingsSaga(
  action: ReturnType<typeof updateSettingsRequestCreator>,
) {
  try {
    const token = yield select(selectToken);
    yield call(
      updateProjectSettings,
      token,
      action.payload.projectUuid,
      action.payload.stylesheetPath,
      action.payload.targetTechnology,
      action.payload.uiFramework,
    );
    yield put(updateSettingsSuccessCreator(action.payload));
  } catch (e) {
    yield put(
      displayErrorToaster({
        errorMessage: 'An error occurred while updating your project settings.',
      }),
    );
    Sentry.captureException(e);
  }
}

export function* updateProjectBetaUserSaga(
  action: ReturnType<typeof updateProjectBetaUserRequestCreator>,
) {
  try {
    const token = yield select(selectToken);
    yield call(updateProjectBetaUser, token, action.payload.projectUuid, action.payload.betaUser);
    yield put(
      updateProjectBetaUserSuccessCreator({
        projectUuid: action.payload.projectUuid,
        betaUser: action.payload.betaUser,
      }),
    );
  } catch (e) {
    yield put(
      displayErrorToaster({ errorMessage: 'An error occurred while signing to the beta.' }),
    );
    Sentry.captureException(e);
  }
}

export function* addProjectContributorSaga(
  action: ReturnType<typeof addProjectContributorRequestCreator>,
) {
  const token = yield select(selectToken);
  try {
    const { body } = yield call(
      addProjectContributor,
      token,
      action.payload.projectUuid,
      action.payload.teamContributorUuid,
    );
    const { entities } = normalizeProject(body);
    yield put(
      getProjectSuccessCreator({
        project: entities.project,
        componentSet: entities.componentSet,
        stylesheet: entities.stylesheet,
        category: entities.category,
      }),
    );
  } catch (e) {
    yield put(
      displayErrorToaster({ errorMessage: 'An error occurred while adding this team member.' }),
    );
    Sentry.captureException(e);
  }
}

export function* removeProjectContributorSaga(
  action: ReturnType<typeof removeProjectContributorRequestCreator>,
) {
  try {
    const token = yield select(selectToken);
    const { body } = yield call(
      removeProjectContributor,
      token,
      action.payload.projectUuid,
      action.payload.projectContributorUuid,
    );
    const { entities } = normalizeProject(body);
    yield put(
      getProjectSuccessCreator({
        project: entities.project,
        componentSet: entities.componentSet,
        stylesheet: entities.stylesheet,
        category: entities.category,
      }),
    );
  } catch (e) {
    yield put(
      displayErrorToaster({ errorMessage: 'An error occurred while removing this teammate.' }),
    );
    Sentry.captureException(e);
  }
}

export function* changeCategoryOrderSaga(
  action: ReturnType<typeof changeProjectCategoryOrderRequestCreator>,
) {
  try {
    const token = yield select(selectToken);
    yield call(changeCategoryOrder, token, action.payload.projectUuid, action.payload.categories);
    yield put(
      changeProjectCategoryOrderSuccessCreator({
        categories: action.payload.categories,
        projectUuid: action.payload.projectUuid,
      }),
    );
  } catch (e) {
    yield put(
      displayErrorToaster({ errorMessage: 'An error occurred while updating categories.' }),
    );
    Sentry.captureException(e);
  }
}

// Saga Watchers
function* watchCreateProject() {
  yield takeLatest(
    getType(createProjectRequestCreator),
    withLoader(withAuthentication(createProjectSaga), SimpleLoadingKeysEnum.createProject),
  );
}

function* watchGetProjects() {
  yield takeLatest(
    getType(getProjectsRequestCreator),
    withLoader(
      withLoader(withAuthentication(getProjectsSaga), SimpleLoadingKeysEnum.getProjects),
      SimpleLoadingKeysEnum.wideLoader,
    ),
  );
}

function* watchDeleteProject() {
  yield takeLatest(
    getType(deleteProjectRequestCreator),
    withLoader(withAuthentication(deleteProjectSaga), SimpleLoadingKeysEnum.deleteProject),
  );
}

function* watchGetProjectDetail() {
  yield takeLatest(
    getType(getProjectRequestCreator),
    withLoader(
      withLoader(withAuthentication(getProjectSaga), SimpleLoadingKeysEnum.getProject),
      SimpleLoadingKeysEnum.wideLoader,
    ),
  );
}

function* watchUpdateProjectSettings() {
  yield takeLatest(
    getType(updateSettingsRequestCreator),
    withLoader(
      withAuthentication(updateProjectSettingsSaga),
      SimpleLoadingKeysEnum.updateProjectSettings,
    ),
  );
}

function* watchAddProjectContributor() {
  yield takeLatest(
    getType(addProjectContributorRequestCreator),
    withLoader(
      withAuthentication(addProjectContributorSaga),
      SimpleLoadingKeysEnum.inviteUserToProject,
    ),
  );
}

function* watchRemoveProjectContributor() {
  yield takeLatest(
    getType(removeProjectContributorRequestCreator),
    withRemoveInviteLoader(withAuthentication(removeProjectContributorSaga)),
  );
}

function* watchChangeCategoryOrder() {
  yield takeLatest(
    getType(changeProjectCategoryOrderRequestCreator),
    withRemoveInviteLoader(withAuthentication(changeCategoryOrderSaga)),
  );
}
function* watchUpdateBetaUser() {
  yield takeLatest(
    getType(updateProjectBetaUserRequestCreator),
    withLoader(
      withAuthentication(updateProjectBetaUserSaga),
      SimpleLoadingKeysEnum.subscribeToBeta,
    ),
  );
}

// Saga export
export function* watchProjectSagas() {
  yield fork(watchChangeCategoryOrder);
  yield fork(watchAddProjectContributor);
  yield fork(watchRemoveProjectContributor);
  yield fork(watchUpdateProjectSettings);
  yield fork(watchGetProjectDetail);
  yield fork(watchDeleteProject);
  yield fork(watchGetProjects);
  yield fork(watchCreateProject);
  yield fork(watchUpdateBetaUser);
}

import React, { Fragment } from 'react';
import { Store } from 'redux';
import { Provider } from 'react-redux';
import { Persistor } from 'redux-persist';
import ReactGA from 'react-ga';
import { Route } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Routes from './routes';
import ShowcaseRoutes from './routes.showcase';
import { overlayTheme } from './overlayTheme';
import { PersistGate } from 'redux-persist/integration/react';
import {
  DefaultToastContainer,
  ToastContainerProps,
  ToastProvider,
} from 'react-toast-notifications';

import { ConnectedRouter } from 'connected-react-router';
import { history } from './redux/store';

import frMessages from 'translations/fr.json';
import enMessages from 'translations/en.json';
import { flattenMessages } from 'services/i18n/intl';
import styles from './App.module.scss';
import './reset.scss';
import './stylesheet.scss';
import { isShowcaseApp } from 'services/featureToggling';
import ErrorToaster from 'components/Atoms/ErrorToaster';
import ErrorToasterController from 'components/HightOrderComponents/ErrorToasterController';
import ErrorBoundary from 'components/HightOrderComponents/ErrorBoundary';
import UserQualificationDialog from 'components/Molecules/UserQualificationDialog';

ReactGA.initialize(
  process.env.REACT_APP_GOOGLE_ANALYTICS_ID
    ? process.env.REACT_APP_GOOGLE_ANALYTICS_ID
    : 'UA-142478271-1',
);
ReactGA.pageview(window.location.pathname + window.location.search);

history.listen(location => {
  ReactGA.pageview(window.location.pathname + window.location.search);
});

const locales = {
  fr: flattenMessages(frMessages),
  en: flattenMessages(enMessages),
};

interface IProps {
  store: Store;
  persistor: Persistor;
}

const RootComponentWithRoutes = () => (
  <Fragment>{isShowcaseApp() ? <ShowcaseRoutes /> : <Routes />}</Fragment>
);

export const ZIndexModifiedToastContainer = (props: ToastContainerProps) => (
  // @ts-ignore
  <DefaultToastContainer {...props} style={{ zIndex: 1400 }} />
);

const App: React.FunctionComponent<IProps> = ({ store, persistor }) => {
  return (
    <MuiThemeProvider theme={overlayTheme}>
      <div className={styles.appRoot}>
        <ToastProvider
          components={{ ToastContainer: ZIndexModifiedToastContainer, Toast: ErrorToaster }}
          autoDismiss
        >
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <IntlProvider locale="fr" messages={locales.fr}>
                <ConnectedRouter history={history}>
                  <ErrorBoundary>
                    <Route path="/" component={RootComponentWithRoutes} />
                  </ErrorBoundary>
                </ConnectedRouter>
                <ErrorToasterController />
                <UserQualificationDialog />
              </IntlProvider>
            </PersistGate>
          </Provider>
        </ToastProvider>
      </div>
    </MuiThemeProvider>
  );
};

export default App;

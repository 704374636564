import { connect } from 'react-redux';
import ProjectDetail, { ISettingsProps } from './Settings.component';
import { selectProject } from 'modules/projects';
import { OverlayStoreType } from 'redux/types';

const mapStateToProps = (state: OverlayStoreType, ownProps: ISettingsProps) => ({
  project: selectProject(state, ownProps.match.params.id),
  projectUuid: ownProps.match.params.id,
});

export default connect(mapStateToProps)(ProjectDetail);

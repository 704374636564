import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import ProjectContributor from 'components/Atoms/ProjectContributor';
import Button from 'components/Atoms/Button';
import styles from './AddProjectContributorsDialog.module.scss';
import cx from 'classnames';
import { ProjectContributorType, ProjectType } from 'modules/projects/types';
import { UserType } from 'modules/user';
import { TeamContributorType } from 'modules/teams/types';
import DropDown from 'components/Atoms/DropDown';
import {
  addProjectContributorRequestCreator,
  removeProjectContributorRequestCreator,
} from 'modules/projects';

type Props = {
  me: UserType;
  isOpen: boolean;
  isInviteUserToProjectLoading: boolean;
  onCloseModal: () => void;
  contributors: ProjectContributorType[];
  project: ProjectType;
  goToTeamPage: (teamUuid: string) => void;
  addProjectContributor: typeof addProjectContributorRequestCreator;
  removeProjectContributor: typeof removeProjectContributorRequestCreator;
  addProjectUserErrorMessage: string;
  addProjectUserSuccessfulMessage: string;
  removeInviteLoaders: { [uuid: string]: boolean };
};
const newTeamContributorKey = 'new-team-contributor';

const AddProjectContributorsDialog: React.FunctionComponent<Props> = ({
  me,
  addProjectContributor,
  removeProjectContributor,
  project,
  isOpen,
  isInviteUserToProjectLoading,
  onCloseModal,
  contributors,
  addProjectUserErrorMessage,
  addProjectUserSuccessfulMessage,
  goToTeamPage,
}) => {
  const [selectedTeammate, changeSelectedTeammate] = useState<string | null>(null);

  const handleAddContributor = (e: any) => {
    e.preventDefault();
    if (!selectedTeammate) {
      return;
    }

    addProjectContributor({ projectUuid: project.uuid, teamContributorUuid: selectedTeammate });
    changeSelectedTeammate(null);
  };

  const handleTeammateChange = (teammateUuid: string) => {
    if (teammateUuid === newTeamContributorKey && project.team) {
      goToTeamPage(project.team.uuid);
      onCloseModal();
      return;
    }

    if (teammateUuid === newTeamContributorKey) {
      return;
    }

    changeSelectedTeammate(teammateUuid);
  };

  const projectContributorsMappedByEmail: Record<string, string> =
    project && project.team
      ? project.contributors.reduce(
          (map, projectContributor: ProjectContributorType) => ({
            ...map,
            [projectContributor.email]: projectContributor.email,
          }),
          {},
        )
      : {};

  let projectContributorsOptions: Record<
    string,
    { key: string; value: string; disabled: boolean }
  > =
    project && project.team
      ? project.team.contributors.reduce((map, contributor: TeamContributorType) => {
          if (contributor.type !== 'user') return map;

          return {
            ...map,
            [contributor.uuid]: {
              key: contributor.uuid,
              value: projectContributorsMappedByEmail.hasOwnProperty(contributor.email)
                ? contributor.email + ' (invited)'
                : contributor.email,
              disabled: projectContributorsMappedByEmail.hasOwnProperty(contributor.email),
            },
          };
        }, {})
      : {};

  if (project && project.team) {
    projectContributorsOptions[newTeamContributorKey] = {
      key: newTeamContributorKey,
      value: 'Add team members',
      disabled: false,
    };
  }

  return (
    <Dialog open={isOpen} onClose={onCloseModal} classes={{ paper: styles.dialogContainer }}>
      <div className={styles.contentContainer}>
        <form className={styles.formContainer} onSubmit={handleAddContributor}>
          <DropDown
            className={styles.teammateDropDown}
            placeholder={'Select a teammate ...'}
            onChange={handleTeammateChange}
            options={projectContributorsOptions}
            value={selectedTeammate}
            emptyMessage={'No team associated'}
            isLoading={false}
          />
          <Button
            type="submit"
            isLoading={isInviteUserToProjectLoading}
            isDisabled={!selectedTeammate}
          >
            Invite
          </Button>
        </form>
        {addProjectUserErrorMessage && (
          <p className={cx(styles.requestMessage, styles.error)}>
            Teammate could not be added. Make sure they have an account to add them.
          </p>
        )}
        {addProjectUserSuccessfulMessage && (
          <p className={styles.requestMessage}>{addProjectUserSuccessfulMessage}</p>
        )}
        {contributors &&
          contributors.map(contributor => (
            <ProjectContributor
              projectUuid={project.uuid}
              key={contributor.uuid}
              me={me}
              contributor={contributor}
              removeProjectContributor={removeProjectContributor}
            />
          ))}
      </div>
    </Dialog>
  );
};

export default AddProjectContributorsDialog;

import {
  FrontendContributorType,
  NormalizedTeamType,
  TeamContributorType,
  TeamStoreType,
  TeamType,
} from 'modules/teams/types';
import { push, replace } from 'connected-react-router';
import { ActionType, createAction, getType, Reducer } from 'typesafe-actions';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { withLoader } from 'modules/loading';
import { selectToken, withAuthentication } from 'modules/authentication';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import {
  addTeamContributor,
  createTeam,
  deleteTeam,
  getMyTeam,
  getMyTeams,
  getTeamInvoices,
  removeTeamContributor,
  updateTeam,
} from 'services/api';
import { displayErrorToaster } from 'modules/apiError';
import * as Sentry from '@sentry/react';
import {
  denormalizeTeam,
  denormalizeTeams,
  normalizeTeam,
  normalizeTeams,
} from 'services/apiNormalizer';
import { OverlayStoreType } from 'redux/types';
import { closeModalCreator, openModalCreator } from 'modules/modals';
import { ModalKeysEnum } from 'modules/modals/types';
import { Invoice } from 'modules/user';
import { mergeTeam } from 'services/factory/teamFactory';

const initialState: TeamStoreType = {
  map: {},
  selectedTeam: null,
  teamContributorToRemove: null,
};

export const selectTeamRequestCreator = createAction('TEAMS/SELECT_TEAM.REQUEST')<{
  teamUuid: string | null;
}>();

export const selectTeamContributorToRemoveRequestCreator = createAction(
  'TEAMS/SELECT_TEAM_CONTRIBUTOR_TO_REMOVE.REQUEST',
)<{
  teamContributorToRemove: TeamContributorType;
}>();

export const getTeamInvoicesRequestCreator = createAction('TEAMS/GET_TEAM_INVOICES.REQUEST')<{
  teamUuid: string;
}>();

export const getTeamInvoicesSuccessCreator = createAction('TEAMS/GET_TEAM_INVOICES.SUCCESS')<{
  teamUuid: string;
  invoices: Invoice[];
}>();

export const getMyTeamRequestCreator = createAction('TEAMS/GET_TEAM.REQUEST')<{
  teamUuid: string;
}>();

export const getMyTeamsRequestCreator = createAction('TEAMS/GET_TEAMS.REQUEST')();

export const createTeamRequestCreator = createAction('TEAMS/CREATE_TEAM.REQUEST')<{
  name: string;
}>();

export const updateTeamNameRequestCreator = createAction('TEAMS/UPDATE_TEAM_NAME.REQUEST')<{
  uuid: string;
  newName: string;
}>();

export const deleteTeamRequestCreator = createAction('TEAMS/DELETE_TEAM.REQUEST')();

export const removeTeamContributorRequestCreator = createAction(
  'TEAMS/REMOVE_TEAM_CONTRIBUTOR.REQUEST',
)();

export const addTeamContributorRequestCreator = createAction('TEAMS/ADD_TEAM_CONTRIBUTOR.REQUEST')<{
  email: string;
  teamUuid: string;
}>();

export const addTeamContributorsRequestCreator = createAction(
  'TEAMS/ADD_TEAM_CONTRIBUTORS.REQUEST',
)<{
  contributors: FrontendContributorType[];
}>();

export const addTeamContributorSuccessCreator = createAction('TEAMS/ADD_TEAM_CONTRIBUTOR.SUCCESS')<{
  teamUuid: string;
  contributors: TeamContributorType[];
}>();

export const deleteTeamSuccessCreator = createAction('TEAMS/DELETE_TEAM.SUCCESS')<{
  uuid: string;
}>();

export const getMyTeamsSuccessCreator = createAction('TEAMS/GET_TEAMS.SUCCESS')<{
  team: Record<string, NormalizedTeamType>;
  selectedTeamUuid: null | string;
}>();

export const selectTeams = (state: OverlayStoreType) =>
  denormalizeTeams(Object.values(state.teams.map), state.teams.map).team;

export const selectSelectedTeamUuid = (state: OverlayStoreType) => state.teams.selectedTeam;

export const selectTeamContributorToRemove = (state: OverlayStoreType) =>
  state.teams.teamContributorToRemove;

export const selectSelectedTeam = (state: OverlayStoreType) => {
  if (!state.teams.selectedTeam || !state.teams.map.hasOwnProperty(state.teams.selectedTeam)) {
    return null;
  }

  return denormalizeTeam(state.teams.map[state.teams.selectedTeam], state.teams.map).team;
};

export const selectTeamByUuid = (state: OverlayStoreType, teamUuid: string) => {
  if (!state.teams.map.hasOwnProperty(teamUuid)) {
    return null;
  }

  return denormalizeTeam(state.teams.map[teamUuid], state.teams.map).team;
};

type TeamActions =
  | ActionType<typeof getMyTeamsSuccessCreator>
  | ActionType<typeof selectTeamRequestCreator>
  | ActionType<typeof addTeamContributorSuccessCreator>
  | ActionType<typeof getTeamInvoicesSuccessCreator>
  | ActionType<typeof selectTeamContributorToRemoveRequestCreator>
  | ActionType<typeof deleteTeamSuccessCreator>;

export const teamsReducer: Reducer<TeamStoreType, TeamActions> = (
  state = initialState,
  action,
): TeamStoreType => {
  switch (action.type) {
    case getType(getMyTeamsSuccessCreator):
      const newTeamMapState = { ...state };
      for (const teamUuid in action.payload.team) {
        if (!action.payload.team.hasOwnProperty(teamUuid)) continue;
        if (newTeamMapState.map.hasOwnProperty(teamUuid)) {
          newTeamMapState.map[teamUuid] = mergeTeam(
            newTeamMapState.map[teamUuid],
            action.payload.team[teamUuid],
          );
        } else {
          newTeamMapState.map[teamUuid] = action.payload.team[teamUuid];
        }
      }

      if (action.payload.selectedTeamUuid) {
        newTeamMapState.selectedTeam = action.payload.selectedTeamUuid;
      }

      return newTeamMapState;
    case getType(getTeamInvoicesSuccessCreator):
      return {
        ...state,
        map: {
          ...state.map,
          [action.payload.teamUuid]: {
            ...state.map[action.payload.teamUuid],
            invoices: action.payload.invoices,
          },
        },
      };
    case getType(selectTeamContributorToRemoveRequestCreator):
      return {
        ...state,
        teamContributorToRemove: action.payload.teamContributorToRemove,
      };
    case getType(selectTeamRequestCreator):
      return {
        ...state,
        selectedTeam: action.payload.teamUuid,
      };
    case getType(addTeamContributorSuccessCreator):
      return {
        ...state,
        teamContributorToRemove: null,
        map: {
          ...state.map,
          [action.payload.teamUuid]: {
            ...state.map[action.payload.teamUuid],
            contributors: action.payload.contributors,
          },
        },
      };
    case getType(deleteTeamSuccessCreator):
      const { ...newMap } = state.map;
      delete newMap[action.payload.uuid];

      return {
        ...state,
        map: newMap,
      };
    default:
      return state;
  }
};

// Sagas
export function* getMyTeamsSaga(action: ReturnType<typeof getMyTeamsRequestCreator>) {
  try {
    const token = yield select(selectToken);
    const { body }: { body: TeamType[] } = yield call(getMyTeams, token);
    const selectedTeamUuid = selectSelectedTeamUuid(yield select());
    const newSelectedTeamUuid = body.length > 0 && !selectedTeamUuid ? body[0].uuid : null;
    const { entities } = normalizeTeams(body);
    yield put(
      getMyTeamsSuccessCreator({
        team: entities.team,
        selectedTeamUuid: newSelectedTeamUuid,
      }),
    );
  } catch (e) {
    yield put(
      displayErrorToaster({
        errorMessage: 'An error occurred while getting your teams.',
      }),
    );
    Sentry.captureException(e);
  }
}

export function* getTeamInvoicesSaga(action: ReturnType<typeof getTeamInvoicesRequestCreator>) {
  try {
    const token = yield select(selectToken);
    const { body }: { body: Invoice[] } = yield call(
      getTeamInvoices,
      token,
      action.payload.teamUuid,
    );
    yield put(
      getTeamInvoicesSuccessCreator({
        teamUuid: action.payload.teamUuid,
        invoices: body,
      }),
    );
  } catch (e) {
    yield put(
      displayErrorToaster({
        errorMessage: 'An error occurred while getting your team invoices.',
      }),
    );
    Sentry.captureException(e);
  }
}

export function* getTeamDetailSaga(action: ReturnType<typeof getMyTeamRequestCreator>) {
  try {
    const token = yield select(selectToken);
    const { body }: { body: TeamType } = yield call(getMyTeam, token, action.payload.teamUuid);
    const { entities } = normalizeTeam(body);
    yield put(
      getMyTeamsSuccessCreator({
        team: entities.team,
        selectedTeamUuid: action.payload.teamUuid,
      }),
    );
  } catch (e) {
    yield put(
      displayErrorToaster({
        errorMessage: 'An error occurred while getting your team.',
      }),
    );
    Sentry.captureException(e);
  }
}

export function* updateTeamSaga(action: ReturnType<typeof updateTeamNameRequestCreator>) {
  try {
    const token: string = yield select(selectToken);
    const currentTeamInfo: TeamType | null = yield select(selectTeamByUuid, action.payload.uuid);

    if (null === currentTeamInfo) {
      return;
    }

    const updatedTeam: TeamType = {
      ...currentTeamInfo,
      name: action.payload.newName,
    };

    const { body }: { body: TeamType } = yield call(updateTeam, token, updatedTeam);
    const { entities } = normalizeTeam(body);
    yield put(
      getMyTeamsSuccessCreator({
        team: entities.team,
        selectedTeamUuid: body.uuid,
      }),
    );
  } catch (e) {
    yield put(
      displayErrorToaster({
        errorMessage: 'An error occurred while updating your team.',
      }),
    );
    Sentry.captureException(e);
  }
}

export function* createTeamSaga(action: ReturnType<typeof createTeamRequestCreator>) {
  try {
    const token = yield select(selectToken);
    const { body }: { body: TeamType } = yield call(createTeam, token, action.payload.name);
    const { entities } = normalizeTeam(body);
    yield put(openModalCreator(ModalKeysEnum.CREATE_TEAM_CONTRIBUTORS)());
    yield put(closeModalCreator(ModalKeysEnum.CREATE_TEAM_NAMING)());
    yield put(
      getMyTeamsSuccessCreator({
        team: entities.team,
        selectedTeamUuid: body.uuid,
      }),
    );
    yield put(push(`/teams/${body.uuid}`));
  } catch (e) {
    yield put(
      displayErrorToaster({
        errorMessage: 'An error occurred while creating your team.',
      }),
    );
    Sentry.captureException(e);
  }
}

export function* deleteTeamSaga(action: ReturnType<typeof deleteTeamRequestCreator>) {
  try {
    const teamUuid = yield select(selectSelectedTeamUuid);

    if (null === teamUuid) {
      return;
    }

    const token = yield select(selectToken);
    yield call(deleteTeam, token, teamUuid);
    yield put(
      deleteTeamSuccessCreator({
        uuid: teamUuid,
      }),
    );
    yield put(closeModalCreator(ModalKeysEnum.DELETE_TEAM)());
    const remainingTeams = selectTeams(yield select());
    if (remainingTeams.length === 0) {
      yield put(selectTeamRequestCreator({ teamUuid: null }));
      yield put(replace('/teams/'));
    } else {
      yield put(push('/teams/' + remainingTeams[0].uuid));
    }
  } catch (e) {
    yield put(
      displayErrorToaster({
        errorMessage: 'An error occurred while deleting your team.',
      }),
    );
    Sentry.captureException(e);
  }
}

export function* addTeamContributorsSaga(
  action: ReturnType<typeof addTeamContributorsRequestCreator>,
) {
  try {
    const teamUuid = yield select(selectSelectedTeamUuid);

    if (null === teamUuid) {
      return;
    }

    const teamInvitations = action.payload.contributors.map(contributor => {
      return call(
        addTeamContributorSaga,
        addTeamContributorRequestCreator({ email: contributor.email, teamUuid }),
      );
    });
    yield all(teamInvitations);
    yield put(getMyTeamRequestCreator({ teamUuid }));
    yield put(closeModalCreator(ModalKeysEnum.CREATE_TEAM_CONTRIBUTORS)());
  } catch (e) {
    yield put(closeModalCreator(ModalKeysEnum.CREATE_TEAM_CONTRIBUTORS)());
    Sentry.captureException(e);
  }
}

export function* addTeamContributorSaga(
  action: ReturnType<typeof addTeamContributorRequestCreator>,
) {
  try {
    const token = yield select(selectToken);
    const { body }: { body: TeamType } = yield call(
      addTeamContributor,
      token,
      action.payload.teamUuid,
      action.payload.email,
    );
    yield put(closeModalCreator(ModalKeysEnum.ADD_TEAM_CONTRIBUTORS)());
    yield put(
      addTeamContributorSuccessCreator({
        teamUuid: action.payload.teamUuid,
        contributors: body.contributors,
      }),
    );
    yield put(getTeamInvoicesRequestCreator({ teamUuid: action.payload.teamUuid }));
  } catch (e) {
    yield put(
      displayErrorToaster({
        errorMessage: 'An error occurred while inviting a team member.',
      }),
    );
    Sentry.captureException(e);
  }
}

export function* removeTeamContributorSaga(
  action: ReturnType<typeof removeTeamContributorRequestCreator>,
) {
  try {
    const token = yield select(selectToken);
    const selectedTeamUuid = selectSelectedTeamUuid(yield select());
    const teamContributorToRemove = selectTeamContributorToRemove(yield select());

    if (!selectedTeamUuid || !teamContributorToRemove) return;

    const { body }: { body: TeamType } = yield call(
      removeTeamContributor,
      token,
      selectedTeamUuid,
      teamContributorToRemove.uuid,
    );
    yield put(
      addTeamContributorSuccessCreator({
        teamUuid: selectedTeamUuid,
        contributors: body.contributors,
      }),
    );
    yield put(getTeamInvoicesRequestCreator({ teamUuid: selectedTeamUuid }));
  } catch (e) {
    yield put(
      displayErrorToaster({
        errorMessage: 'An error occurred while removing a team member.',
      }),
    );
    Sentry.captureException(e);
  }
}

// Saga watchers
function* watchGetMyTeams() {
  yield takeLatest(
    getType(getMyTeamsRequestCreator),
    withLoader(
      withLoader(withAuthentication(getMyTeamsSaga), SimpleLoadingKeysEnum.getTeams),
      SimpleLoadingKeysEnum.wideLoader,
    ),
  );
}

function* watchGetMyTeam() {
  yield takeLatest(
    getType(getMyTeamRequestCreator),
    withLoader(
      withLoader(withAuthentication(getTeamDetailSaga), SimpleLoadingKeysEnum.getTeams),
      SimpleLoadingKeysEnum.wideLoader,
    ),
  );
}

function* watchGetTeamInvoices() {
  yield takeLatest(
    getType(getTeamInvoicesRequestCreator),
    withLoader(withAuthentication(getTeamInvoicesSaga), SimpleLoadingKeysEnum.wideLoader),
  );
}

function* watchCreateTeam() {
  yield takeLatest(
    getType(createTeamRequestCreator),
    withLoader(withAuthentication(createTeamSaga), SimpleLoadingKeysEnum.createTeam),
  );
}

function* watchDeleteTeam() {
  yield takeLatest(
    getType(deleteTeamRequestCreator),
    withLoader(withAuthentication(deleteTeamSaga), SimpleLoadingKeysEnum.deleteTeam),
  );
}

function* watchAddTeamContributor() {
  yield takeLatest(
    getType(addTeamContributorRequestCreator),
    withLoader(
      withAuthentication(addTeamContributorSaga),
      SimpleLoadingKeysEnum.addTeamContributor,
    ),
  );
}

function* watchAddTeamContributors() {
  yield takeLatest(
    getType(addTeamContributorsRequestCreator),
    withLoader(
      withAuthentication(addTeamContributorsSaga),
      SimpleLoadingKeysEnum.addTeamContributor,
    ),
  );
}

function* watchUpdateTeamName() {
  yield takeLatest(
    getType(updateTeamNameRequestCreator),
    withLoader(withAuthentication(updateTeamSaga), SimpleLoadingKeysEnum.updateTeam),
  );
}

function* watchRemoveTeamContributor() {
  yield takeLatest(
    getType(removeTeamContributorRequestCreator),
    withLoader(
      withAuthentication(removeTeamContributorSaga),
      SimpleLoadingKeysEnum.removeTeamContributor,
    ),
  );
}

// Saga export
export function* watchTeamSagas() {
  yield fork(watchGetMyTeams);
  yield fork(watchGetTeamInvoices);
  yield fork(watchGetMyTeam);
  yield fork(watchCreateTeam);
  yield fork(watchDeleteTeam);
  yield fork(watchUpdateTeamName);
  yield fork(watchAddTeamContributor);
  yield fork(watchRemoveTeamContributor);
  yield fork(watchAddTeamContributors);
}

import { connect } from 'react-redux';
import Stylesheet, { IStylesheetProps } from './Stylesheet.component';
import {
  getProjectStylesheetRequestCreator,
  selectIsCurrentStylesheetEmpty,
} from 'modules/stylesheet';
import { selectCurrentStylesheet } from 'modules/stylesheet';
import { isLoading } from 'modules/loading';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import { OverlayStoreType } from 'redux/types';
import { selectProject } from 'modules/projects';

const mapStateToProps = (state: OverlayStoreType, ownProps: IStylesheetProps) => ({
  stylesheet: selectCurrentStylesheet(state),
  isStylesheetEmpty: selectIsCurrentStylesheetEmpty(state),
  project: selectProject(state, ownProps.match.params.id),
  isGetStylesheetLoading: isLoading(state, SimpleLoadingKeysEnum.getStyleSheet),
  isUpdateStylesheetLoading: isLoading(state, SimpleLoadingKeysEnum.updateStyleSheet),
});

const mapDispatchToProps = {
  fetchStylesheet: getProjectStylesheetRequestCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(Stylesheet);

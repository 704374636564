import { takeLatest, call, put, select, fork } from 'redux-saga/effects';
import { withLoader } from 'modules/loading';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import { selectToken, withAuthentication } from 'modules/authentication';
import { ComponentSetsStoreType, ComponentSetType, NormalizedComponentSetType } from './types';
import { OverlayStoreType } from 'redux/types';
import {
  denormalizeComponent,
  denormalizeComponentSet,
  normalizeComponent,
  normalizeComponentSet,
} from 'services/apiNormalizer';
import { getType, ActionType, Reducer, createAction } from 'typesafe-actions';
import { getComponentFontsRequestCreator } from 'modules/components';
import {
  closeReviewSectionRequestCreator,
  openReviewSectionWithTimeoutRequestCreator,
} from 'modules/reviews';
import {
  deleteComponentSet,
  getComponentSet,
  getComponentSetComponent,
  updateComponentSetName,
} from 'services/api';
import { selectHasDismissReview } from 'modules/user';
import { push } from 'connected-react-router';
import { displayErrorToaster } from 'modules/apiError';
import * as Sentry from '@sentry/react';
import { ComponentType, NormalizedComponentType } from 'modules/components/types';
import { getProjectSuccessCreator } from 'modules/projects';
import { closeModalCreator } from 'modules/modals';
import { ModalKeysEnum } from 'modules/modals/types';
import omit from 'lodash/omit';
import { createCategorySuccessCreator, updateCategorySuccessCreator } from 'modules/categories';
import { mergeComponentSet } from 'services/factory/componentSetFactory';
import { NormalizedComponentInstanceType } from 'modules/componentInstances/types';
import { AssetType } from 'modules/assets/types';
import { NormalizedLayerType } from 'modules/layers/types';
import { NormalizedComponentPropsType } from 'modules/props/types';

const initialState: ComponentSetsStoreType = {
  map: {},
};

// Actions Creators
export const getComponentSetRequestCreator = createAction(
  'COMPONENT_SETS/GET_COMPONENT_SET.REQUEST',
)<{
  projectUuid: string;
  componentSetUuid: string;
}>();

export const getComponentSetComponentRequestCreator = createAction(
  'COMPONENT_SETS/GET_COMPONENT_SET_COMPONENT.REQUEST',
)<{
  projectUuid: string;
  componentSetUuid: string;
  variantKeyQuery: string;
  lastPropertyChosen: string;
}>();

export const getComponentSetComponentSuccessCreator = createAction(
  'COMPONENT_SETS/GET_COMPONENT_SET_COMPONENT.SUCCESS',
)<{
  components: Record<string, NormalizedComponentType>;
  componentInstances: Record<string, NormalizedComponentInstanceType>;
  assets: Record<string, AssetType>;
  layer: Record<string, NormalizedLayerType>;
  props: Record<string, NormalizedComponentPropsType>;
  componentSetUuid: string;
  componentUuid: string;
  selectedComponentVariantKey: string | null;
}>();

export const getComponentSetSuccessCreator = createAction('COMPONENT_SETS/GET_COMPONENT.SUCCESS')<{
  componentSet: Record<string, NormalizedComponentSetType>;
  components: Record<string, NormalizedComponentType>;
  componentInstances: Record<string, NormalizedComponentInstanceType>;
  assets: Record<string, AssetType>;
  layer: Record<string, NormalizedLayerType>;
  props: Record<string, NormalizedComponentPropsType>;
}>();

export const deleteComponentSetRequestCreator = createAction(
  'COMPONENT_SETS/DELETE_COMPONENT.REQUEST',
)<{
  projectUuid: string;
  componentSetUuid: string;
}>();

export const deleteComponentSetSuccessCreator = createAction(
  'COMPONENT_SETS/DELETE_COMPONENT.SUCCESS',
)<{
  projectUuid: string;
  componentSetUuid: string;
}>();

export const updateComponentSetNameRequestCreator = createAction(
  'COMPONENT_SETS/UPDATE_COMPONENT_NAME.REQUEST',
)<{
  componentSetUuid: string;
  projectUuid: string;
  newName: string;
}>();

type ComponentSetActions =
  | ActionType<typeof getComponentSetSuccessCreator>
  | ActionType<typeof deleteComponentSetSuccessCreator>
  | ActionType<typeof createCategorySuccessCreator>
  | ActionType<typeof updateCategorySuccessCreator>
  | ActionType<typeof getComponentSetComponentSuccessCreator>
  | ActionType<typeof getProjectSuccessCreator>;

// Selectors
export const selectComponentSetSelectedComponent = (
  state: OverlayStoreType,
  componentSetUuid: string,
) => {
  if (!state.componentSets.map.hasOwnProperty(componentSetUuid)) {
    return null;
  }

  const componentUuid = state.componentSets.map[componentSetUuid].selectedComponent;

  if (!componentUuid) {
    return null;
  }

  return denormalizeComponent(
    state.components.map[componentUuid],
    state.components.map,
    state.componentInstances.map,
    state.assets.map,
    state.props.map,
    state.layers.map,
  );
};

export const selectComponentSet = (state: OverlayStoreType, componentSetUuid: string) => {
  if (!state.componentSets.map.hasOwnProperty(componentSetUuid)) {
    return null;
  }

  return denormalizeComponentSet(
    state.componentSets.map[componentSetUuid],
    state.componentSets.map,
    state.categories.map,
  );
};

export const selectComponentSetSelectedComponentUuid = (
  state: OverlayStoreType,
  componentSetUuid: string,
) => {
  return state.componentSets.map[componentSetUuid].selectedComponent;
};

// Reducer
export const componentSetsReducer: Reducer<any, ComponentSetActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getType(getProjectSuccessCreator):
    case getType(getComponentSetSuccessCreator):
      const newProjectState = { ...state };
      for (const componentId in action.payload.componentSet) {
        if (!action.payload.componentSet.hasOwnProperty(componentId)) continue;
        if (newProjectState.map.hasOwnProperty(componentId)) {
          newProjectState.map[componentId] = mergeComponentSet(
            newProjectState.map[componentId],
            action.payload.componentSet[componentId],
          );
        } else {
          newProjectState.map[componentId] = action.payload.componentSet[componentId];
        }
      }
      return newProjectState;
    case getType(getComponentSetComponentSuccessCreator):
      return {
        ...state,
        map: {
          ...state.map,
          [action.payload.componentSetUuid]: {
            ...state.map[action.payload.componentSetUuid],
            selectedComponent: action.payload.componentUuid,
            selectedComponentVariantKey: action.payload.selectedComponentVariantKey,
          },
        },
      };

    case getType(deleteComponentSetSuccessCreator):
      return {
        map: {
          ...omit(state.map, [action.payload.componentSetUuid]),
        },
      };
    case getType(createCategorySuccessCreator):
    case getType(updateCategorySuccessCreator):
      const stateWithUpdateComponent = { ...state };
      if (!action.payload.componentSets) {
        return stateWithUpdateComponent;
      }
      action.payload.componentSets.map(componentUuid => {
        if (stateWithUpdateComponent.map.hasOwnProperty(componentUuid)) {
          stateWithUpdateComponent.map[componentUuid].category = action.payload.categoryUuid;
        }
      });
      return stateWithUpdateComponent;
    default:
      return state;
  }
};

// Sagas
function* getComponentSetSaga(action: ReturnType<typeof getComponentSetRequestCreator>) {
  try {
    yield put(closeReviewSectionRequestCreator());
    const token = yield select(selectToken);
    const componentSetUuid = action.payload.componentSetUuid;
    const { body: componentSet }: { body: ComponentSetType } = yield call(
      getComponentSet,
      token,
      componentSetUuid,
      action.payload.projectUuid,
    );

    const { body: component }: { body: ComponentType } = yield call(
      getComponentSetComponent,
      token,
      componentSetUuid,
      action.payload.projectUuid,
    );

    componentSet.selectedComponent = component.uuid;
    componentSet.selectedComponentVariantKey = component.variantKey;

    const normalizedComponentSet = normalizeComponentSet(componentSet);
    const normalizedComponent = normalizeComponent(component);

    yield put(
      getComponentSetSuccessCreator({
        componentSet: normalizedComponentSet.entities.componentSet,
        components: normalizedComponent.entities.component,
        componentInstances: normalizedComponent.entities.componentInstance,
        assets: normalizedComponent.entities.asset,
        layer: normalizedComponent.entities.layer,
        props: normalizedComponent.entities.props,
      }),
    );

    yield put(
      getComponentFontsRequestCreator({
        componentUuid: component.uuid,
        projectUuid: action.payload.projectUuid,
      }),
    );
    const hasDismissReview: boolean = yield select(selectHasDismissReview);
    if (!component.reviewed && !hasDismissReview) {
      yield put(openReviewSectionWithTimeoutRequestCreator());
    }
  } catch (error) {
    if (
      401 === error.response.status ||
      403 === error.response.status ||
      404 === error.response.status
    ) {
      yield put(push(`/404`));
      return;
    }
    yield put(
      displayErrorToaster({ errorMessage: 'An error occurred while getting this component.' }),
    );
    Sentry.captureException(error);
  }
}

function* getComponentSetComponentSaga(
  action: ReturnType<typeof getComponentSetComponentRequestCreator>,
) {
  try {
    yield put(closeReviewSectionRequestCreator());
    const token = yield select(selectToken);
    const componentSetUuid = action.payload.componentSetUuid;

    const { body: component }: { body: ComponentType } = yield call(
      getComponentSetComponent,
      token,
      componentSetUuid,
      action.payload.projectUuid,
      action.payload.variantKeyQuery,
      action.payload.lastPropertyChosen,
    );

    const normalizedComponent = normalizeComponent(component);

    yield put(
      getComponentSetComponentSuccessCreator({
        componentSetUuid,
        componentUuid: component.uuid,
        selectedComponentVariantKey: component.variantKey,
        components: normalizedComponent.entities.component,
        componentInstances: normalizedComponent.entities.componentInstance,
        assets: normalizedComponent.entities.asset,
        layer: normalizedComponent.entities.layer,
        props: normalizedComponent.entities.props,
      }),
    );

    yield put(
      getComponentFontsRequestCreator({
        componentUuid: component.uuid,
        projectUuid: action.payload.projectUuid,
      }),
    );

    const hasDismissReview: boolean = yield select(selectHasDismissReview);
    if (!component.reviewed && !hasDismissReview) {
      yield put(openReviewSectionWithTimeoutRequestCreator());
    }
  } catch (error) {
    if (
      401 === error.response.status ||
      403 === error.response.status ||
      404 === error.response.status
    ) {
      yield put(push(`/404`));
      return;
    }
    yield put(
      displayErrorToaster({ errorMessage: 'An error occurred while getting this component.' }),
    );
    Sentry.captureException(error);
  }
}

function* deleteComponentSetSaga(action: ReturnType<typeof deleteComponentSetRequestCreator>) {
  try {
    const token = yield select(selectToken);
    yield call(
      deleteComponentSet,
      token,
      action.payload.componentSetUuid,
      action.payload.projectUuid,
    );
    yield put(deleteComponentSetSuccessCreator(action.payload));
    yield put(closeModalCreator(ModalKeysEnum.DELETE_COMPONENT)());
  } catch (e) {
    yield put(
      displayErrorToaster({ errorMessage: 'An error occurred while deleting this component.' }),
    );
    Sentry.captureException(e);
  }
}

function* updateComponentSetNameSaga(
  action: ReturnType<typeof updateComponentSetNameRequestCreator>,
) {
  try {
    const token: string = yield select(selectToken);
    const componentUuid: string | null = yield select(
      selectComponentSetSelectedComponentUuid,
      action.payload.componentSetUuid,
    );

    const {
      body: updateComponentSetNameResponse,
    }: { body: { componentSet: ComponentSetType; component: ComponentType } } = yield call(
      updateComponentSetName,
      token,
      action.payload.componentSetUuid,
      componentUuid,
      action.payload.projectUuid,
      action.payload.newName,
    );
    const normalizedComponentSet = normalizeComponentSet(
      updateComponentSetNameResponse.componentSet,
    );
    const normalizedComponent = normalizeComponent(updateComponentSetNameResponse.component);

    yield put(
      getComponentSetSuccessCreator({
        componentSet: normalizedComponentSet.entities.componentSet,
        components: normalizedComponent.entities.component,
        componentInstances: normalizedComponent.entities.componentInstance,
        assets: normalizedComponent.entities.asset,
        layer: normalizedComponent.entities.layer,
        props: normalizedComponent.entities.props,
      }),
    );
  } catch (e) {
    yield put(
      displayErrorToaster({
        errorMessage: 'An error occurred while updating this component name.',
      }),
    );
    Sentry.captureException(e);
  }
}

function* watchGetComponentSet() {
  yield takeLatest(
    getType(getComponentSetRequestCreator),
    withLoader(withAuthentication(getComponentSetSaga), SimpleLoadingKeysEnum.getComponent),
  );
}

function* watchGetComponentSetComponent() {
  yield takeLatest(
    getType(getComponentSetComponentRequestCreator),
    withLoader(
      withAuthentication(getComponentSetComponentSaga),
      SimpleLoadingKeysEnum.changeVariant,
    ),
  );
}

function* watchUpdateComponentSetName() {
  yield takeLatest(
    getType(updateComponentSetNameRequestCreator),
    withLoader(
      withAuthentication(updateComponentSetNameSaga),
      SimpleLoadingKeysEnum.updateComponentName,
    ),
  );
}

function* watchDeleteComponentSet() {
  yield takeLatest(
    getType(deleteComponentSetRequestCreator),
    withLoader(withAuthentication(deleteComponentSetSaga), SimpleLoadingKeysEnum.deleteComponent),
  );
}

// Saga export
export function* watchComponentSetSagas() {
  yield fork(watchGetComponentSet);
  yield fork(watchGetComponentSetComponent);
  yield fork(watchUpdateComponentSetName);
  yield fork(watchDeleteComponentSet);
}

import { OverlayPaymentRecurringInterval, OverlayTeamPlanEnum } from 'modules/user/types';

export type SubscriptionStoreType = {
  selectedPrice: Price;
  promotionCode: PromotionCode | null;
  selectedRecurringInterval: OverlayPaymentRecurringInterval;
  selectedTeamPlan: SelectableTeamPlanEnum;
  seatNumber: number;
  promotionCodeValidationIsSuccessful: boolean;
  promotionCodeValidationIsFailed: boolean;
};

export enum SelectableTeamPlanEnum {
  START = OverlayTeamPlanEnum.START,
  SCALE = OverlayTeamPlanEnum.SCALE,
}

export type Price = {
  priceId: string;
  price: number;
};

export type PromotionCode = {
  id: string;
  percentageOff: number;
};

import React from 'react';
import cx from 'classnames';
import styles from './LinearLoader.module.scss';
import { ClassValue } from 'classnames/types';

interface IProps {
  className?: ClassValue;
  size?: number;
}

const LinearLoader: React.FunctionComponent<IProps> = ({ className, size }) => {
  return (
    <div
      className={cx(styles.ldsEllipsis, className)}
      style={size ? { width: size, height: size * 0.1625 } : {}}
    >
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default LinearLoader;

import React, { ReactNode } from 'react';
import styles from './FlatButton.module.scss';
import cx from 'classnames';
import { ClassValue } from 'classnames/types';

interface IProps {
  onClick?: () => void;
  isSelected: boolean;
  children?: ReactNode;
  className?: ClassValue;
  theme: string;
}

const FlatButton: React.FunctionComponent<IProps> = ({
  theme,
  isSelected,
  onClick,
  children,
  className,
}) => {
  const themeMap: Record<string, ClassValue> = {
    react: styles.react,
    vue: styles.vue,
    scss: styles.scss,
    css: styles.css,
    html: styles.html,
    styledComponents: styles.styledComponents,
    designTool: styles.designTool,
  };

  return (
    <div
      className={cx(
        className,
        styles.container,
        theme && themeMap[theme],
        isSelected && styles.selected,
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default FlatButton;

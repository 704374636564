import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { validatePromotionCode } from 'services/api';
import { PromotionCode, SubscriptionStoreType, Price, SelectableTeamPlanEnum } from './types';
import { ActionType, createAction, getType, Reducer } from 'typesafe-actions';
import { selectToken, withAuthentication } from 'modules/authentication';
import { OverlayStoreType } from 'redux/types';
import { withLoader } from 'modules/loading';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import { OverlayPaymentRecurringInterval } from 'modules/user/types';
import * as Sentry from '@sentry/react';

export const teamPriceMap: Record<
  SelectableTeamPlanEnum,
  Record<OverlayPaymentRecurringInterval, Price>
> = {
  [SelectableTeamPlanEnum.START]: {
    [OverlayPaymentRecurringInterval.MONTHLY]: {
      priceId: process.env.REACT_APP_STRIPE_START_MONTHLY as string,
      price: 18,
    },
    [OverlayPaymentRecurringInterval.YEARLY]: {
      priceId: process.env.REACT_APP_STRIPE_START_YEARLY as string,
      price: 15,
    },
  },
  [SelectableTeamPlanEnum.SCALE]: {
    [OverlayPaymentRecurringInterval.MONTHLY]: {
      priceId: process.env.REACT_APP_STRIPE_SCALE_MONTHLY as string,
      price: 15,
    },
    [OverlayPaymentRecurringInterval.YEARLY]: {
      priceId: process.env.REACT_APP_STRIPE_SCALE_YEARLY as string,
      price: 12,
    },
  },
};
const initialState: SubscriptionStoreType = {
  selectedPrice: teamPriceMap[SelectableTeamPlanEnum.START][OverlayPaymentRecurringInterval.YEARLY],
  selectedRecurringInterval: OverlayPaymentRecurringInterval.YEARLY,
  selectedTeamPlan: SelectableTeamPlanEnum.START,
  seatNumber: 1,
  promotionCode: null,
  promotionCodeValidationIsFailed: false,
  promotionCodeValidationIsSuccessful: false,
};

// Actions Creators
export const validatePromotionCodeRequestCreator = createAction(
  'SUBSCRIPTION/VALIDATE_PROMOTION_CODE.REQUEST',
)<{
  promotionCode: string;
}>();

export const resetPromotionCodeRequestCreator = createAction(
  'SUBSCRIPTION/RESET_PROMOTION_CODE.REQUEST',
)();

export const changeSelectedTeamPlanCreator = createAction(
  'SUBSCRIPTION/CHANGE_SELECTED_TEAM_PLAN.REQUEST',
)<{
  teamPlan: SelectableTeamPlanEnum;
}>();

export const changeRecurringIntervalCreator = createAction(
  'SUBSCRIPTION/CHANGE_RECURRING_INTERVAL.REQUEST',
)<{
  recurringInterval: OverlayPaymentRecurringInterval;
}>();

export const changeSeatNumberCreator = createAction('SUBSCRIPTION/CHANGE_SEAT_NUMBER.REQUEST')<{
  seatNumber: number;
}>();

export const validatePromotionCodeSuccessCreator = createAction(
  'SUBSCRIPTION/VALIDATE_PROMOTION_CODE.SUCCESS',
)<{
  promotion: PromotionCode;
}>();

export const validatePromotionCodeFailedCreator = createAction(
  'SUBSCRIPTION/VALIDATE_PROMOTION_CODE.FAILED',
)();

type SubscriptionActions =
  | ActionType<typeof validatePromotionCodeSuccessCreator>
  | ActionType<typeof changeSelectedTeamPlanCreator>
  | ActionType<typeof changeSeatNumberCreator>
  | ActionType<typeof changeRecurringIntervalCreator>
  | ActionType<typeof resetPromotionCodeRequestCreator>
  | ActionType<typeof validatePromotionCodeFailedCreator>;

// Selectors
export const selectPromotionCode = (store: OverlayStoreType) => store.subscription.promotionCode;
export const selectSelectedRecurringPlan = (store: OverlayStoreType) =>
  store.subscription.selectedRecurringInterval;
export const selectSeatNumber = (store: OverlayStoreType) => store.subscription.seatNumber;
export const selectSelectedTeamPlan = (store: OverlayStoreType) =>
  store.subscription.selectedTeamPlan;
export const selectSelectedPrice = (store: OverlayStoreType) => store.subscription.selectedPrice;
export const selectPromotionCodeValidationSuccessFul = (store: OverlayStoreType) =>
  store.subscription.promotionCodeValidationIsSuccessful;
export const selectPromotionCodeValidationFailed = (store: OverlayStoreType) =>
  store.subscription.promotionCodeValidationIsFailed;

// Reducer
export const subscriptionReducer: Reducer<SubscriptionStoreType, SubscriptionActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getType(validatePromotionCodeSuccessCreator):
      return {
        ...state,
        promotionCode: action.payload.promotion,
        promotionCodeValidationIsSuccessful: true,
        promotionCodeValidationIsFailed: false,
      };
    case getType(resetPromotionCodeRequestCreator):
      return {
        ...state,
        promotionCode: null,
        promotionCodeValidationIsSuccessful: false,
        promotionCodeValidationIsFailed: false,
      };
    case getType(changeSeatNumberCreator):
      return {
        ...state,
        seatNumber: action.payload.seatNumber,
      };
    case getType(changeSelectedTeamPlanCreator):
      return {
        ...state,
        selectedTeamPlan: action.payload.teamPlan,
        selectedPrice: teamPriceMap[action.payload.teamPlan][state.selectedRecurringInterval],
      };
    case getType(changeRecurringIntervalCreator):
      return {
        ...state,
        selectedRecurringInterval: action.payload.recurringInterval,
        selectedPrice: teamPriceMap[state.selectedTeamPlan][action.payload.recurringInterval],
      };
    case getType(validatePromotionCodeFailedCreator):
      return {
        ...state,
        promotionCodeValidationIsFailed: true,
      };
    default:
      return state;
  }
};

// Sagas
export function* validatePromotionCodeSaga(
  action: ReturnType<typeof validatePromotionCodeRequestCreator>,
) {
  try {
    const token = yield select(selectToken);
    const { body: promotionCode }: { body: PromotionCode } = yield call(
      validatePromotionCode,
      token,
      action.payload.promotionCode,
    );
    yield put(
      validatePromotionCodeSuccessCreator({
        promotion: promotionCode,
      }),
    );
  } catch (e) {
    Sentry.captureException(e);
    yield put(validatePromotionCodeFailedCreator());
  }
}

// Saga Watchers
function* watchValidatePromotionCode() {
  yield takeLatest(
    getType(validatePromotionCodeRequestCreator),
    withLoader(
      withAuthentication(validatePromotionCodeSaga),
      SimpleLoadingKeysEnum.validatePromotionCode,
    ),
  );
}

// Saga export
export function* watchSubscriptionSagas() {
  yield fork(watchValidatePromotionCode);
}

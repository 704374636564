import { AssetsStoreType, AssetType } from 'modules/assets/types';
import { OverlayStoreType } from 'redux/types';
import { ActionType, createAction, getType, Reducer } from 'typesafe-actions';
import { getComponentInstancesSuccessCreator } from 'modules/componentInstances';
import {
  getComponentSetComponentSuccessCreator,
  getComponentSetSuccessCreator,
} from 'modules/componentSets';

const initialState: AssetsStoreType = {
  map: {},
};

export const getAssetsSuccessCreator = createAction('ASSETS/GET_COMPONENT_ASSETS.SUCCESS')<{
  assets: { [uuid: string]: AssetType };
}>();

type AssetActions =
  | ActionType<typeof getAssetsSuccessCreator>
  | ActionType<typeof getComponentSetSuccessCreator>
  | ActionType<typeof getComponentSetComponentSuccessCreator>
  | ActionType<typeof getComponentInstancesSuccessCreator>;

export const selectComponentAssets = (store: OverlayStoreType) => Object.values(store.assets.map);

export const assetsReducer: Reducer<AssetsStoreType, AssetActions> = (
  state = initialState,
  action,
): AssetsStoreType => {
  switch (action.type) {
    case getType(getAssetsSuccessCreator):
    case getType(getComponentInstancesSuccessCreator):
    case getType(getComponentSetSuccessCreator):
    case getType(getComponentSetComponentSuccessCreator):
      return {
        ...state,
        map: {
          ...state.map,
          ...action.payload.assets,
        },
      };
    default:
      return state;
  }
};

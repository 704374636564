import { takeLatest, call, put, fork } from 'redux-saga/effects';
import { withLoader } from 'modules/loading';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import { checkToken, resetPassword, forgotPassword } from 'services/api';
import { OverlayStoreType } from 'redux/types';
import { ResetPasswordStoreType } from 'modules/resetPassword/types';
import * as Sentry from '@sentry/react';

const initialState: ResetPasswordStoreType = {
  isValidToken: false,
  isValidReset: false,
  isValidForgot: false,
  isProcessForgotRequestOk: false,
};

export const actionTypes = {
  VALIDATE_TOKEN: {
    REQUEST: 'VALIDATE_TOKEN.REQUEST',
    SUCCESS: 'VALIDATE_TOKEN.SUCCESS',
    FAIL: 'VALIDATE_TOKEN.FAIL',
  },
  VALIDATE_RESET: {
    REQUEST: 'VALIDATE_RESET.REQUEST',
    SUCCESS: 'VALIDATE_RESET.SUCCESS',
    FAIL: 'VALIDATE_RESET.FAIL',
  },
  VALIDATE_FORGOT: {
    REQUEST: 'VALIDATE_FORGOT.REQUEST',
    SUCCESS: 'VALIDATE_FORGOT.SUCCESS',
    FAIL: 'VALIDATE_FORGOT.FAIL',
  },
};

// Actions Creators
export const validateTokenRequestCreator = (token: string) => ({
  type: actionTypes.VALIDATE_TOKEN.REQUEST,
  token,
});

export const validateTokenSuccessCreator = () => ({
  type: actionTypes.VALIDATE_TOKEN.SUCCESS,
});

export const validateTokenFailCreator = () => ({
  type: actionTypes.VALIDATE_TOKEN.FAIL,
});

export const validateResetRequestCreator = (token: string, password: string) => ({
  type: actionTypes.VALIDATE_RESET.REQUEST,
  token,
  password,
});

export const validateResetSuccessCreator = () => ({
  type: actionTypes.VALIDATE_RESET.SUCCESS,
});

export const validateResetFailCreator = () => ({
  type: actionTypes.VALIDATE_RESET.FAIL,
});

export const validateForgotRequestCreator = (email: string) => ({
  type: actionTypes.VALIDATE_FORGOT.REQUEST,
  email,
});

export const validateForgotSuccessCreator = () => ({
  type: actionTypes.VALIDATE_FORGOT.SUCCESS,
});

export const validateForgotFailCreator = () => ({
  type: actionTypes.VALIDATE_FORGOT.FAIL,
});

// Selectors
export const selectIsValidToken = (state: OverlayStoreType): boolean =>
  state.resetPassword.isValidToken;

export const selectIsValidReset = (state: OverlayStoreType): boolean =>
  state.resetPassword.isValidReset;

export const selectIsValidForgot = (state: OverlayStoreType): boolean =>
  state.resetPassword.isValidForgot;

export const selectisProcessForgotRequestOk = (state: OverlayStoreType): boolean =>
  state.resetPassword.isProcessForgotRequestOk;

// Reducer
export const resetPasswordReducer = (state: ResetPasswordStoreType = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.VALIDATE_TOKEN.SUCCESS:
      return { ...state, isValidToken: true };
    case actionTypes.VALIDATE_TOKEN.FAIL:
      return { ...state, isValidToken: false };
    case actionTypes.VALIDATE_RESET.SUCCESS:
      return { ...state, isValidReset: true };
    case actionTypes.VALIDATE_RESET.FAIL:
      return { ...state, isValidReset: false };
    case actionTypes.VALIDATE_FORGOT.REQUEST:
      return { ...state, isProcessForgotRequestOk: false };
    case actionTypes.VALIDATE_FORGOT.SUCCESS:
      return { ...state, isValidForgot: true, isProcessForgotRequestOk: true };
    case actionTypes.VALIDATE_FORGOT.FAIL:
      return { ...state, isValidForgot: false, isProcessForgotRequestOk: true };
    default:
      return state;
  }
};

// Sagas
export function* validateTokenSaga(action: any) {
  try {
    yield call(checkToken, action.token);
    yield put(validateTokenSuccessCreator());
  } catch (error) {
    Sentry.captureException(error);
    yield put(validateTokenFailCreator());
  }
}

export function* validateResetSaga(action: any) {
  try {
    yield call(resetPassword, action.token, action.password);
    yield put(validateResetSuccessCreator());
  } catch (error) {
    Sentry.captureException(error);
    yield put(validateResetFailCreator());
  }
}

export function* validateForgotSaga(action: any) {
  try {
    yield call(forgotPassword, action.email);
    yield put(validateForgotSuccessCreator());
  } catch (error) {
    Sentry.captureException(error);
    yield put(validateForgotFailCreator());
  }
}

// Saga Watchers
function* watchValidateToken() {
  yield takeLatest(
    actionTypes.VALIDATE_TOKEN.REQUEST,
    withLoader(validateTokenSaga, SimpleLoadingKeysEnum.validateResetPasswordToken),
  );
}

function* watchValidateReset() {
  yield takeLatest(
    actionTypes.VALIDATE_RESET.REQUEST,
    withLoader(validateResetSaga, SimpleLoadingKeysEnum.resetPassword),
  );
}

function* watchValidateForgot() {
  yield takeLatest(
    actionTypes.VALIDATE_FORGOT.REQUEST,
    withLoader(validateForgotSaga, SimpleLoadingKeysEnum.forgotPassword),
  );
}

// Saga export
export function* watchResetPasswordSagas() {
  yield fork(watchValidateForgot);
  yield fork(watchValidateReset);
  yield fork(watchValidateToken);
}

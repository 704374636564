import { logoutSuccessCreator } from './authentication';
import { getType } from 'typesafe-actions';

export default function createReducerWithLogout(baseReducer: any, storage: any) {
  return (state: any, action: any) => {
    if (action.type === getType(logoutSuccessCreator)) {
      state = undefined;
      storage.removeItem('persist:root');
    }
    return baseReducer(state, action);
  };
}

import { denormalizeProject, denormalizeProjects } from 'services/apiNormalizer';
import { OverlayStoreType } from 'redux/types';
import { NormalizedProjectType, ProjectsStoreType } from 'modules/projects/types';
import { ActionType, getType } from 'typesafe-actions';
import { createCategorySuccessCreator, deleteCategorySuccessCreator } from 'modules/categories';
import { deleteComponentSetSuccessCreator } from 'modules/componentSets';
import {
  changeCurrentProjectCreator,
  changeProjectCategoryOrderSuccessCreator,
  createProjectsSuccessCreator,
  deleteProjectSuccessCreator,
  getProjectErrorCreator,
  getProjectStylesheetSuccessCreator,
  getProjectSuccessCreator,
  selectCreateProjectTeamRequestCreator,
  updateProjectBetaUserSuccessCreator,
  updateSettingsSuccessCreator,
} from 'modules/projects/actions';

const initialState: ProjectsStoreType = {
  map: {},
  addProjectUserErrorMessage: '',
  addProjectUserSuccessfulMessage: '',
  getProjectErrorCode: null,
  currentProject: null,
  createProjectSelectedTeam: null,
};

// Selectors
export const selectProjects = (state: OverlayStoreType) =>
  denormalizeProjects(
    Object.values(state.projects.map),
    state.projects.map,
    state.componentSets.map,
  ).project;

export const selectProject = (state: OverlayStoreType, projectUuid: string) => {
  return denormalizeProject(
    state.projects.map[projectUuid],
    state.projects.map,
    state.componentSets.map,
    state.categories.map,
    state.stylesheets.map,
  );
};

export const selectCreateProjectSelectedTeam = (state: OverlayStoreType) =>
  state.projects.createProjectSelectedTeam;

export const selectStylesheetPath = (state: OverlayStoreType, projectUuid: string) =>
  state.projects.map[projectUuid].stylesheetPath;

export const selectTargetTechnology = (state: OverlayStoreType, projectUuid: string) =>
  state.projects.map[projectUuid].targetTechnology;

export const selectUIFramework = (state: OverlayStoreType, projectUuid: string) =>
  state.projects.map[projectUuid].uiFramework;

export const selectAddProjectUserErrorMessage = (store: OverlayStoreType) => {
  return store.projects.addProjectUserErrorMessage;
};

export const selectAddProjectUserSuccessfulMessage = (store: OverlayStoreType) => {
  return store.projects.addProjectUserSuccessfulMessage;
};

export const selectGetProjectErrorCode = (store: OverlayStoreType) =>
  store.projects.getProjectErrorCode;

export const selectCurrentProject = (store: OverlayStoreType) => {
  if (!store.projects.currentProject) return null;
  return store.projects.map[store.projects.currentProject];
};

// Helpers
const removeComponentFromProject = (
  project: NormalizedProjectType,
  removedComponentUuid: string,
) => {
  project.componentSets = project.componentSets.filter(
    componentUuid => componentUuid !== removedComponentUuid,
  );
  return project;
};

type ProjectActions =
  | ActionType<typeof createProjectsSuccessCreator>
  | ActionType<typeof deleteProjectSuccessCreator>
  | ActionType<typeof getProjectSuccessCreator>
  | ActionType<typeof getProjectStylesheetSuccessCreator>
  | ActionType<typeof changeProjectCategoryOrderSuccessCreator>
  | ActionType<typeof deleteComponentSetSuccessCreator>
  | ActionType<typeof updateSettingsSuccessCreator>
  | ActionType<typeof updateProjectBetaUserSuccessCreator>
  | ActionType<typeof deleteCategorySuccessCreator>
  | ActionType<typeof getProjectErrorCreator>
  | ActionType<typeof changeCurrentProjectCreator>
  | ActionType<typeof selectCreateProjectTeamRequestCreator>
  | ActionType<typeof createCategorySuccessCreator>;

// Reducer
export const projectsReducer = (state = initialState, action: ProjectActions) => {
  switch (action.type) {
    case getType(createProjectsSuccessCreator):
      return {
        map: {
          ...state.map,
          [action.payload.project.uuid]: action.payload.project,
        },
      };
    case getType(deleteProjectSuccessCreator):
      const { ...newMap } = state.map;
      delete newMap[action.payload.projectUuid];

      return {
        map: newMap,
      };
    case getType(selectCreateProjectTeamRequestCreator):
      return {
        ...state,
        createProjectSelectedTeam: action.payload.teamUuid,
      };
    case getType(getProjectSuccessCreator):
      return {
        ...state,
        map: {
          ...state.map,
          ...action.payload.project,
        },
        getProjectErrorCode: null,
      };
    case getType(getProjectStylesheetSuccessCreator):
      return {
        ...state,
        map: {
          ...state.map,
          [action.payload.project.uuid]: {
            ...state.map[action.payload.project.uuid],
            stylesheet: action.payload.project.stylesheet,
          },
        },
        getProjectErrorCode: null,
      };
    case getType(changeProjectCategoryOrderSuccessCreator):
      return {
        ...state,
        map: {
          ...state.map,
          [action.payload.projectUuid]: {
            ...state.map[action.payload.projectUuid],
            categories: action.payload.categories,
          },
        },
      };
    case getType(getProjectErrorCreator):
      return {
        ...state,
        getProjectErrorCode: action.payload.getProjectErrorCode,
      };
    case getType(changeCurrentProjectCreator):
      return {
        ...state,
        currentProject: action.payload.projectUuid,
      };
    case getType(deleteComponentSetSuccessCreator):
      return {
        ...state,
        map: {
          ...state.map,
          [action.payload.projectUuid]: removeComponentFromProject(
            state.map[action.payload.projectUuid],
            action.payload.componentSetUuid,
          ),
        },
      };
    case getType(updateSettingsSuccessCreator):
      return {
        map: {
          ...state.map,
          [action.payload.projectUuid]: {
            ...state.map[action.payload.projectUuid],
            stylesheetPath: action.payload.stylesheetPath,
            targetTechnology: action.payload.targetTechnology,
            uiFramework: action.payload.uiFramework,
          },
        },
      };
    case getType(updateProjectBetaUserSuccessCreator):
      return {
        ...state,
        map: {
          ...state.map,
          [action.payload.projectUuid]: {
            ...state.map[action.payload.projectUuid],
            betaUser: action.payload.betaUser,
          },
        },
      };
    case getType(deleteCategorySuccessCreator):
      return {
        ...state,
        map: {
          ...state.map,
          [action.payload.projectUuid]: {
            ...state.map[action.payload.projectUuid],
            categories: state.map[action.payload.projectUuid].categories.filter(
              categoryUuid => categoryUuid !== action.payload.categoryUuid,
            ),
          },
        },
      };
    case getType(createCategorySuccessCreator):
      return {
        ...state,
        map: {
          ...state.map,
          [action.payload.projectUuid]: {
            ...state.map[action.payload.projectUuid],
            categories: [
              ...state.map[action.payload.projectUuid].categories,
              action.payload.categoryUuid,
            ],
          },
        },
      };
    default:
      return state;
  }
};

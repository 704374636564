import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ProjectDetail from './pages/ProjectDetail';
import Stylesheet from './pages/Stylesheet';
import Settings from './pages/Settings';
import NotFound from './pages/404';

const Loading = () => <div>Loading...</div>;

const lazyComponent = (name: string) => lazy(() => import(`./pages/${name}`));

const RedirectProject = (props: any) => (
  <Redirect to={`/project/${props.match.params.id}/components`} />
);

const routes: React.FunctionComponent = () => (
  <Suspense fallback={<Loading />}>
    <Switch>
      <Route exact path="/" component={lazyComponent('ProjectList')} />
      <Route exact path="/project/:id/components" component={ProjectDetail} />
      <Route
        path="/project/:id/component-sets/:componentId"
        component={lazyComponent('ComponentSetDetail')}
      />
      <Route
        exact
        path="/project/:id/component-instances/:componentInstanceId"
        component={lazyComponent('ComponentInstanceDetail')}
      />
      <Route exact path="/project/:id/stylesheet" component={Stylesheet} />
      <Route exact path="/project/:id/settings" component={Settings} />
      <Route path="/project/:id" component={RedirectProject} />
      <Route render={() => <NotFound />} />
    </Switch>
  </Suspense>
);

export default routes;

import { NormalizedLayerType } from 'modules/layers/types';
import { NormalizedComponentType } from 'modules/components/types';
import { defaultComponentProperty } from 'services/factory/componentFactory';

export const defaultLayerProperty: NormalizedLayerType = {
  type: 'LAYER',
  uuid: '',
  name: '',
  layoutCode: '',
  styleCode: '',
  styleVariables: {
    typos: [],
    colors: [],
  },
  children: [],
  dependency: null,
  props: null,
  assets: [],
};

export const create = (entity: Partial<NormalizedLayerType>): NormalizedLayerType => ({
  type: 'LAYER',
  uuid: entity.uuid ? entity.uuid : defaultLayerProperty.uuid,
  name: entity.name ? entity.name : defaultLayerProperty.name,
  layoutCode: entity.layoutCode ? entity.layoutCode : defaultLayerProperty.layoutCode,
  styleCode: entity.styleCode ? entity.styleCode : defaultLayerProperty.styleCode,
  styleVariables: {
    typos:
      entity.styleVariables && entity.styleVariables.typos
        ? entity.styleVariables.typos
        : defaultLayerProperty.styleVariables.typos,
    colors:
      entity.styleVariables && entity.styleVariables.colors
        ? entity.styleVariables.colors
        : defaultLayerProperty.styleVariables.colors,
  },
  dependency: entity.dependency ? entity.dependency : defaultLayerProperty.dependency,
  props: entity.props ? entity.props : defaultLayerProperty.props,
  children: entity.children ? entity.children : defaultLayerProperty.children,
  assets: entity.assets ? entity.assets : defaultLayerProperty.assets,
});

export const mergeLayer = (
  currentLayerState: NormalizedLayerType,
  newLayer: NormalizedLayerType,
) => {
  const newCurrentComponentState = { ...currentLayerState };

  for (const [key, value] of Object.entries(newLayer)) {
    if (Array.isArray(value) && value.length === 0) {
      continue;
    }

    if (key === 'styleVariables') {
      if (newLayer[key].colors.length > 0) {
        newCurrentComponentState[key].colors = newLayer[key].colors;
      }
      if (newLayer[key].typos.length > 0) {
        newCurrentComponentState[key].typos = newLayer[key].typos;
      }

      continue;
    }

    // @ts-ignore
    if (value !== defaultComponentProperty[key]) {
      // @ts-ignore
      newCurrentComponentState[key] = value;
    }
  }

  return newCurrentComponentState;
};

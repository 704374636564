import { NormalizedComponentInstanceType } from 'modules/componentInstances/types';
import { ComponentEnumDesignTool } from 'modules/components/types';

export const defaultComponentProperty: NormalizedComponentInstanceType = {
  type: 'INSTANCE',
  uuid: '',
  name: '',
  previewHeight: 0,
  previewWidth: 0,
  styleCode: null,
  layoutCode: '',
  cssPreviewCode: '',
  htmlPreviewCode: '',
  parentLink: '',
  styleVariables: {
    typos: [],
    colors: [],
  },
  designTool: ComponentEnumDesignTool.FIGMA,
  dependencies: [],
  props: [],
  assets: [],
  rootLayer: '',
  reviewed: false,
  missingFonts: [],
};

export const create = (
  entity: Partial<NormalizedComponentInstanceType>,
): NormalizedComponentInstanceType => ({
  type: 'INSTANCE',
  uuid: entity.uuid ? entity.uuid : defaultComponentProperty.uuid,
  name: entity.name ? entity.name : defaultComponentProperty.name,
  parentLink: entity.parentLink ? entity.parentLink : defaultComponentProperty.parentLink,
  previewHeight: entity.previewHeight
    ? entity.previewHeight
    : defaultComponentProperty.previewHeight,
  previewWidth: entity.previewWidth ? entity.previewWidth : defaultComponentProperty.previewWidth,
  layoutCode: entity.layoutCode ? entity.layoutCode : defaultComponentProperty.layoutCode,
  styleCode: entity.styleCode ? entity.styleCode : defaultComponentProperty.styleCode,
  cssPreviewCode: entity.cssPreviewCode
    ? entity.cssPreviewCode
    : defaultComponentProperty.cssPreviewCode,
  htmlPreviewCode: entity.htmlPreviewCode
    ? entity.htmlPreviewCode
    : defaultComponentProperty.htmlPreviewCode,
  styleVariables: {
    typos:
      entity.styleVariables && entity.styleVariables.typos
        ? entity.styleVariables.typos
        : defaultComponentProperty.styleVariables.typos,
    colors:
      entity.styleVariables && entity.styleVariables.colors
        ? entity.styleVariables.colors
        : defaultComponentProperty.styleVariables.colors,
  },
  designTool: entity.designTool ? entity.designTool : defaultComponentProperty.designTool,
  dependencies: entity.dependencies ? entity.dependencies : defaultComponentProperty.dependencies,
  props: entity.props ? entity.props : defaultComponentProperty.props,
  assets: entity.assets ? entity.assets : defaultComponentProperty.assets,
  rootLayer: entity.rootLayer ? entity.rootLayer : defaultComponentProperty.rootLayer,
  reviewed: entity.reviewed ? entity.reviewed : defaultComponentProperty.reviewed,
  missingFonts: entity.missingFonts ? entity.missingFonts : defaultComponentProperty.missingFonts,
});

export const mergeComponentInstance = (
  currentComponentInstanceState: NormalizedComponentInstanceType,
  newComponentInstance: NormalizedComponentInstanceType,
) => {
  const newCurrentComponentState = { ...currentComponentInstanceState };

  for (const [key, value] of Object.entries(newComponentInstance)) {
    if (Array.isArray(value) && value.length === 0) {
      continue;
    }

    if (key === 'styleVariables') {
      if (newComponentInstance[key].colors.length > 0) {
        newCurrentComponentState[key].colors = newComponentInstance[key].colors;
      }
      if (newComponentInstance[key].typos.length > 0) {
        newCurrentComponentState[key].typos = newComponentInstance[key].typos;
      }

      continue;
    }

    // @ts-ignore
    if (value !== defaultComponentProperty[key]) {
      // @ts-ignore
      newCurrentComponentState[key] = value;
    }
  }

  return newCurrentComponentState;
};

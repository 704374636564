import React, { MouseEventHandler } from 'react';
import styles from './RemoveButton.module.scss';
import { ReactComponent as CloseIcon } from 'assets/cross.svg';
import classnames from 'classnames';
import { ClassValue } from 'classnames/types';

interface IProps {
  onClick: MouseEventHandler;
  className?: ClassValue;
}

const RemoveButton: React.FunctionComponent<IProps> = ({ onClick, className }) => {
  return (
    <div className={classnames(styles.removeButton, className)} onClick={onClick}>
      <CloseIcon className={styles.removeCross} />
    </div>
  );
};

export default RemoveButton;

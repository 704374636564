import React from 'react';
import styles from './InternalError.module.scss';
import Button from 'components/Atoms/Button';

const InternalError = () => {
  const openSupport = () => {
    // @ts-ignore
    $crisp.push(['do', 'chat:open']);
  };

  return (
    <div className={styles.criticalErrorSection}>
      <p className={styles.title}>Uh oh..</p>
      <p className={styles.description}>
        We’re experiencing a problem here.. We’re on it, don’t panic!
      </p>
      <div className={styles.bottom}>
        <p className={styles.instructions}>
          Until we fix it, try to reload your page, read our content, or contact us..
          <br /> but please, don’t stay here, it’s not safe :)
        </p>
        <div className={styles.buttons}>
          <Button theme={'dark'} className={styles.button} onClick={openSupport}>
            Contact us
          </Button>
          <a className={styles.button} target="_blank" href="https://medium.com/overlay-blog">
            <Button theme={'dark'}>Take a break</Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default InternalError;

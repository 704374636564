import { CategoriesStoreType, CategoryType } from 'modules/categories/types';
import { ActionType, createAction, getType, Reducer } from 'typesafe-actions';
import { OverlayStoreType } from 'redux/types';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { withLoader } from 'modules/loading';
import { selectToken, withAuthentication } from 'modules/authentication';
import { createCategory, deleteCategory, updateCategory } from 'services/api';
import { normalizeCategory } from 'services/apiNormalizer';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import * as Sentry from '@sentry/react';
import { displayErrorToaster } from 'modules/apiError';
import { getProjectSuccessCreator } from 'modules/projects';

const initialState: CategoriesStoreType = {
  map: {},
  lastCategoryCreated: null,
  lastCategoryUpdated: null,
};

// Actions creators
export const deleteCategoryRequestCreator = createAction('CATEGORY/DELETE_CATEGORY.REQUEST')<{
  categoryUuid: string;
  projectUuid: string;
}>();

export const changeLastCategoryCreatedRequestCreator = createAction(
  'CATEGORY/CHANGE_LAST_CREATED.REQUEST',
)<{
  categoryUuid: string | null;
}>();

export const changeLastCategoryUpdatedRequestCreator = createAction(
  'CATEGORY/CHANGE_LAST_UPDATED.REQUEST',
)<{
  categoryUuid: string | null;
}>();

export const createCategoryRequestCreator = createAction('CATEGORY/CREATE_CATEGORY.REQUEST')<{
  components: string[];
  projectUuid: string;
}>();

export const updateCategoryRequestCreator = createAction('CATEGORY/UPDATE_CATEGORY.REQUEST')<{
  components?: string[];
  categoryUuid: string;
  name?: string;
}>();

export const createCategorySuccessCreator = createAction('CATEGORY/CREATE_CATEGORY.SUCCESS')<{
  componentSets: string[];
  category: Record<string, CategoryType>;
  categoryUuid: string;
  projectUuid: string;
}>();

export const updateCategorySuccessCreator = createAction('CATEGORY/UPDATE_CATEGORY.SUCCESS')<{
  componentSets?: string[];
  category: Record<string, CategoryType>;
  categoryUuid: string;
}>();

export const deleteCategorySuccessCreator = createAction('CATEGORY/DELETE_CATEGORY.SUCCESS')<{
  categoryUuid: string;
  projectUuid: string;
}>();

type CategoryActions =
  | ActionType<typeof getProjectSuccessCreator>
  | ActionType<typeof createCategorySuccessCreator>
  | ActionType<typeof updateCategorySuccessCreator>
  | ActionType<typeof changeLastCategoryCreatedRequestCreator>
  | ActionType<typeof changeLastCategoryUpdatedRequestCreator>
  | ActionType<typeof deleteCategorySuccessCreator>;

// Selectors
export const selectCategoryByUuid = (state: OverlayStoreType, categoryUuid: string) =>
  state.categories.map[categoryUuid];

export const selectLastCreatedCategory = (state: OverlayStoreType) =>
  state.categories.lastCategoryCreated;

export const selectLastUpdatedCategory = (state: OverlayStoreType) =>
  state.categories.lastCategoryUpdated;

// Reducer
export const categoriesReducer: Reducer<any, CategoryActions> = (state = initialState, action) => {
  switch (action.type) {
    case getType(getProjectSuccessCreator):
    case getType(createCategorySuccessCreator):
    case getType(updateCategorySuccessCreator):
      const newCategoryMapState = { ...state };
      for (const componentId in action.payload.category) {
        if (!action.payload.category.hasOwnProperty(componentId)) continue;
        if (newCategoryMapState.map.hasOwnProperty(componentId)) {
          newCategoryMapState.map[componentId] = {
            ...newCategoryMapState.map[componentId],
            ...action.payload.category[componentId],
          };
        } else {
          newCategoryMapState.map[componentId] = action.payload.category[componentId];
        }
      }
      return newCategoryMapState;
    case getType(changeLastCategoryCreatedRequestCreator):
      return {
        ...state,
        lastCategoryCreated: action.payload.categoryUuid,
      };
    case getType(changeLastCategoryUpdatedRequestCreator):
      return {
        ...state,
        lastCategoryUpdated: action.payload.categoryUuid,
      };
    case getType(deleteCategorySuccessCreator):
      const newState = { ...state };
      delete newState.map[action.payload.categoryUuid];
      return newState;
    default:
      return state;
  }
};

// Sagas
export function* removeCategorySaga(action: ReturnType<typeof deleteCategoryRequestCreator>) {
  try {
    const token = yield select(selectToken);
    yield call(deleteCategory, token, action.payload.categoryUuid);
    yield put(
      deleteCategorySuccessCreator({
        categoryUuid: action.payload.categoryUuid,
        projectUuid: action.payload.projectUuid,
      }),
    );
  } catch (e) {
    yield put(
      displayErrorToaster({ errorMessage: 'An error occurred while deleting this category.' }),
    );
    Sentry.captureException(e);
  }
}

export function* createCategorySaga(action: ReturnType<typeof createCategoryRequestCreator>) {
  try {
    const token = yield select(selectToken);
    const { body } = yield call(
      createCategory,
      token,
      action.payload.projectUuid,
      action.payload.components,
    );
    const { entities } = normalizeCategory(body);
    yield put(
      createCategorySuccessCreator({
        category: entities.category,
        categoryUuid: body.uuid,
        componentSets: action.payload.components,
        projectUuid: action.payload.projectUuid,
      }),
    );
    yield put(
      changeLastCategoryCreatedRequestCreator({
        categoryUuid: body.uuid,
      }),
    );
  } catch (e) {
    yield put(
      displayErrorToaster({ errorMessage: 'An error occurred while creating this category.' }),
    );
    Sentry.captureException(e);
  }
}

export function* updateCategorySaga(action: ReturnType<typeof updateCategoryRequestCreator>) {
  try {
    const token = yield select(selectToken);
    const { body } = yield call(
      updateCategory,
      token,
      action.payload.categoryUuid,
      action.payload.components,
      action.payload.name,
    );
    const { entities } = normalizeCategory(body);
    let response: any = {
      category: entities.category,
      categoryUuid: action.payload.categoryUuid,
    };
    if (action.payload.components) {
      response.componentSets = action.payload.components;
    }
    yield put(updateCategorySuccessCreator(response));
    yield put(
      changeLastCategoryUpdatedRequestCreator({
        categoryUuid: body.uuid,
      }),
    );
  } catch (e) {
    yield put(
      displayErrorToaster({ errorMessage: 'An error occurred while updating this category.' }),
    );
    Sentry.captureException(e);
  }
}

// Sagas Watchers
function* watchRemoveCategory() {
  yield takeLatest(
    getType(deleteCategoryRequestCreator),
    withLoader(withAuthentication(removeCategorySaga), SimpleLoadingKeysEnum.createCategory),
  );
}

function* watchCreateCategory() {
  yield takeLatest(
    getType(createCategoryRequestCreator),
    withLoader(withAuthentication(createCategorySaga), SimpleLoadingKeysEnum.createCategory),
  );
}

function* watchUpdateCategory() {
  yield takeLatest(
    getType(updateCategoryRequestCreator),
    withLoader(withAuthentication(updateCategorySaga), SimpleLoadingKeysEnum.createCategory),
  );
}

// Saga export
export function* watchCategoriesSagas() {
  yield fork(watchRemoveCategory);
  yield fork(watchCreateCategory);
  yield fork(watchUpdateCategory);
}

import { TeamType } from 'modules/teams/types';
import { OverlayTeamPlanEnum } from 'modules/user/types';

export const defaultTeamProperty: TeamType = {
  uuid: '',
  name: '',
  plan: OverlayTeamPlanEnum.FREE,
  canEdit: false,
  currentPeriodEnd: null,
  cancelAtPeriodEnd: false,
  paymentRecurringInterval: null,
  subscriptionId: null,
  last4: null,
  expMonth: null,
  expYear: null,
  billingEmail: null,
  priceId: null,
  extraBillingInfo: null,
  companyName: null,
  invoices: [],
  contributors: [],
};

export const create = (entity: Partial<TeamType>): TeamType => ({
  uuid: entity.uuid ? entity.uuid : defaultTeamProperty.uuid,
  name: entity.name ? entity.name : defaultTeamProperty.name,
  plan: entity.plan ? entity.plan : defaultTeamProperty.plan,
  canEdit: entity.canEdit ? entity.canEdit : defaultTeamProperty.canEdit,
  subscriptionId: entity.subscriptionId
    ? entity.subscriptionId
    : defaultTeamProperty.subscriptionId,
  cancelAtPeriodEnd: entity.cancelAtPeriodEnd
    ? entity.cancelAtPeriodEnd
    : defaultTeamProperty.cancelAtPeriodEnd,
  currentPeriodEnd: entity.currentPeriodEnd
    ? entity.currentPeriodEnd
    : defaultTeamProperty.currentPeriodEnd,
  paymentRecurringInterval: entity.paymentRecurringInterval
    ? entity.paymentRecurringInterval
    : defaultTeamProperty.paymentRecurringInterval,
  last4: entity.last4 ? entity.last4 : defaultTeamProperty.last4,
  priceId: entity.priceId ? entity.priceId : defaultTeamProperty.priceId,
  expMonth: entity.expMonth ? entity.expMonth : defaultTeamProperty.expMonth,
  expYear: entity.expYear ? entity.expYear : defaultTeamProperty.expYear,
  billingEmail: entity.billingEmail ? entity.billingEmail : defaultTeamProperty.billingEmail,
  extraBillingInfo: entity.extraBillingInfo
    ? entity.extraBillingInfo
    : defaultTeamProperty.extraBillingInfo,
  companyName: entity.companyName ? entity.companyName : defaultTeamProperty.companyName,
  invoices: entity.invoices ? entity.invoices : defaultTeamProperty.invoices,
  contributors: entity.contributors ? entity.contributors : defaultTeamProperty.contributors,
});

export const mergeTeam = (currentTeam: TeamType, newTeamInfo: TeamType) => {
  const newTeam = { ...currentTeam };

  for (const [key, value] of Object.entries(newTeamInfo)) {
    if (Array.isArray(value) && value.length === 0) {
      continue;
    }

    // @ts-ignore
    if (value !== defaultTeamProperty[key]) {
      // @ts-ignore
      newTeam[key] = value;
    }
  }

  return newTeam;
};

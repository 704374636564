import React from 'react';
import styles from './ErrorToaster.module.scss';
import { ToastProps, TransitionState } from 'react-toast-notifications';
import classnames from 'classnames';
import { ClassValue } from 'classnames/types';
import { ReactComponent as CrossIcon } from 'assets/cross.svg';

interface IProps extends ToastProps {
  description?: string;
}

const styleMap: Record<TransitionState, ClassValue> = {
  entering: styles.entering,
  entered: styles.entered,
  exiting: styles.exiting,
  exited: styles.exited,
};

const ErrorToaster: React.FunctionComponent<IProps> = ({
  onDismiss,
  children,
  transitionState,
}) => {
  const handleClose = () => {
    onDismiss();
  };

  return (
    <div className={classnames(styles.errorToasterWeb, styleMap[transitionState])}>
      <div className={styles.container}>
        <p className={styles.errorLabel}>ERROR</p>
        <p className={styles.description}>{children}</p>
      </div>
      {onDismiss && <CrossIcon className={styles.iconCross} onClick={handleClose} />}
    </div>
  );
};

export default ErrorToaster;

import React, { MouseEventHandler } from 'react';
import styles from './SelectButton.module.scss';
import classnames from 'classnames';
import { ReactComponent as CheckIcon } from 'assets/check.svg';
import { ClassValue } from 'classnames/types';

interface IProps {
  isSelected: boolean;
  className?: ClassValue;
  onClick?: MouseEventHandler;
}

const SelectButton: React.FunctionComponent<IProps> = ({ isSelected, className, onClick }) => {
  return (
    <div
      className={classnames(styles.selectComponent, isSelected && styles.selected, className)}
      onClick={onClick}
    >
      <CheckIcon className={styles.icon} />
    </div>
  );
};

export default SelectButton;

import {
  DesignToolEnum,
  NormalizedProjectType,
  ProjectType,
  TargetTechnologyEnum,
  UIFrameworkEnum,
} from 'modules/projects/types';
import { createAction } from 'typesafe-actions';
import { CategoryType } from 'modules/categories/types';
import { NormalizedComponentSetType } from 'modules/componentSets/types';
import { StylesheetType } from 'modules/stylesheet/types';

export const createProjectRequestCreator = createAction('PROJECT/CREATE_PROJECT.REQUEST')<{
  name: string;
  targetTechnology: TargetTechnologyEnum;
  uiFramework: UIFrameworkEnum;
  designTool: DesignToolEnum;
  teamUuid: string;
}>();

export const createProjectsSuccessCreator = createAction('PROJECT/CREATE_PROJECT.SUCCESS')<{
  project: ProjectType;
}>();

export const changeCurrentProjectCreator = createAction('PROJECT/CHANGE_CURRENT_PROJECT.REQUEST')<{
  projectUuid: string;
}>();

export const deleteProjectRequestCreator = createAction('PROJECT/DELETE_PROJECT.REQUEST')<{
  projectUuid: string;
}>();

export const deleteProjectSuccessCreator = createAction('PROJECT/DELETE_PROJECT.SUCCESS')<{
  projectUuid: string;
}>();

export const getProjectsRequestCreator = createAction('PROJECT/GET_PROJECTS.REQUEST')();

export const getProjectRequestCreator = createAction('PROJECT/GET_PROJECT.REQUEST')<{
  projectUuid: string;
}>();

export const getProjectSuccessCreator = createAction('PROJECT/GET_PROJECT.SUCCESS')<{
  project: Record<string, NormalizedProjectType>;
  componentSet: Record<string, NormalizedComponentSetType>;
  stylesheet: Record<string, StylesheetType>;
  category: Record<string, CategoryType>;
}>();

export const getProjectStylesheetSuccessCreator = createAction(
  'PROJECT/GET_PROJECT_STYLESHEET.SUCCESS',
)<{
  project: ProjectType;
}>();

export const changeProjectCategoryOrderRequestCreator = createAction(
  'PROJECT/CHANGE_CATEGORY_ORDER.REQUEST',
)<{
  categories: string[];
  projectUuid: string;
}>();

export const changeProjectCategoryOrderSuccessCreator = createAction(
  'PROJECT/CHANGE_CATEGORY_ORDER.SUCCESS',
)<{
  categories: string[];
  projectUuid: string;
}>();

export const getProjectErrorCreator = createAction('PROJECT/GET_PROJECT.ERROR')<{
  getProjectErrorCode: number;
}>();

export const updateSettingsRequestCreator = createAction('PROJECT/UPDATE_SETTINGS.REQUEST')<{
  projectUuid: string;
  stylesheetPath: string;
  targetTechnology: TargetTechnologyEnum;
  uiFramework: UIFrameworkEnum;
}>();

export const updateSettingsSuccessCreator = createAction('PROJECT/UPDATE_SETTINGS.SUCCESS')<{
  projectUuid: string;
  stylesheetPath: string;
  targetTechnology: TargetTechnologyEnum;
  uiFramework: UIFrameworkEnum;
}>();

export const addProjectContributorRequestCreator = createAction(
  'PROJECT/ADD_PROJECT_CONTRIBUTOR.REQUEST',
)<{
  projectUuid: string;
  teamContributorUuid: string;
}>();

export const removeProjectContributorRequestCreator = createAction(
  'PROJECT/REMOVE_PROJECT_CONTRIBUTOR.REQUEST',
)<{
  projectUuid: string;
  projectContributorUuid: string;
}>();

export const updateProjectBetaUserRequestCreator = createAction('PROJECT/BETA_USER.REQUEST')<{
  betaUser: boolean;
  projectUuid: string;
}>();

export const updateProjectBetaUserSuccessCreator = createAction('PROJECT/BETA_USER.SUCCESS')<{
  betaUser: boolean;
  projectUuid: string;
}>();

export const selectCreateProjectTeamRequestCreator = createAction(
  'PROJECT/SELECT_CREATE_PROJECT_TEAM.REQUEST',
)<{
  teamUuid: string;
}>();

import { connect } from 'react-redux';
import UserQualificationDialog from './UserQualificationDialog.component';
import { OverlayStoreType } from 'redux/types';
import { closeModalCreator, isModalOpen } from 'modules/modals';
import { ModalKeysEnum } from 'modules/modals/types';
import { qualifyUserRequestCreator } from 'modules/user';
import { isLoading } from 'modules/loading';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';

const mapStateToProps = (state: OverlayStoreType) => ({
  isOpen: isModalOpen(state, ModalKeysEnum.USER_QUALIFICATION),
  isLoading: isLoading(state, SimpleLoadingKeysEnum.qualifyUser),
});

const mapDispatchToProps = {
  onClose: closeModalCreator(ModalKeysEnum.USER_QUALIFICATION),
  qualifyUser: qualifyUserRequestCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserQualificationDialog);

import React, { useState } from 'react';
import { match } from 'react-router';
import style from './ProjectDetail.module.scss';
import ProjectDetailLayout from 'components/Layout/ProjectDetailLayout';
import CircularProgress from '@material-ui/core/es/CircularProgress/CircularProgress';
import { ProjectType } from 'modules/projects/types';
import { ProjectUuidMatchParams } from 'src/routes';
import ConfirmDeleteDialog from 'components/Molecules/ConfirmDeleteDialog';
import { ComponentType } from 'modules/components/types';
import CategoryList from 'components/Molecules/CategoryList';
import { isShowcaseApp } from 'services/featureToggling';
import EmptyProjectState from 'pages/ProjectDetail/EmptyState.component';
import { updateProjectBetaUserRequestCreator } from 'modules/projects';
import { deleteComponentSetRequestCreator } from 'modules/componentSets';

export interface IProjectDetailProps {
  isProjectLoading: boolean;
  isDeletingComponent: boolean;
  isDeleteModalOpen: boolean;
  isSubscribingToBeta: boolean;
  openDeleteComponentModal: () => void;
  closeDeleteComponentModal: () => void;
  deleteComponent: typeof deleteComponentSetRequestCreator;
  updateProjectBetaUser: typeof updateProjectBetaUserRequestCreator;
  project: ProjectType;
  projectUuid: string;
  match: match<ProjectUuidMatchParams>;
}

const ProjectDetail: React.FunctionComponent<IProjectDetailProps> = ({
  project,
  projectUuid,
  isProjectLoading,
  isDeleteModalOpen,
  isDeletingComponent,
  deleteComponent,
  updateProjectBetaUser,
  isSubscribingToBeta,
  openDeleteComponentModal,
  closeDeleteComponentModal,
}) => {
  const [selectedComponent, changeSelectedComponent] = useState<ComponentType | null>(null);
  const selectComponentAndOpenDeleteModal = (component: ComponentType) => {
    changeSelectedComponent(component);
    openDeleteComponentModal();
  };

  const onConfirmDelete = () => {
    if (!selectedComponent) {
      return;
    }
    deleteComponent({ projectUuid, componentSetUuid: selectedComponent.uuid });
  };

  const projectHasComponent = project && project.componentSets;

  return (
    <ProjectDetailLayout projectId={projectUuid}>
      <div className={style.componentListSection}>
        {isProjectLoading && !projectHasComponent && (
          <CircularProgress className={style.loader} size={30} />
        )}
        {projectHasComponent && (
          <>
            {project.componentSets && project.componentSets.length > 0 && (
              <CategoryList
                categories={project.categories}
                components={project.componentSets}
                onComponentDelete={isShowcaseApp() ? null : selectComponentAndOpenDeleteModal}
                projectUuid={projectUuid}
              />
            )}
            {project.componentSets && project.componentSets.length === 0 && (
              <EmptyProjectState
                onSubscribeBeta={updateProjectBetaUser}
                project={project}
                isLoading={isSubscribingToBeta}
              />
            )}
          </>
        )}
      </div>
      <ConfirmDeleteDialog
        isLoading={isDeletingComponent}
        isOpen={isDeleteModalOpen && !!selectedComponent}
        onCloseModal={closeDeleteComponentModal}
        onConfirmation={onConfirmDelete}
      >
        <p className={style.deleteMessage}>Are you sure you want to delete this component ?</p>
      </ConfirmDeleteDialog>
    </ProjectDetailLayout>
  );
};

export default ProjectDetail;

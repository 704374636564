import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import createReducer from './reducers';
import rootSaga from './sagas';
import { persistStore, persistReducer } from 'redux-persist';
import localForage from 'localforage';
import createReducerWithLogout from 'modules/reducerWithLogout';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();

declare var window: {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
};

export default function configureStore(initialState = {}) {
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  const enhancers = [applyMiddleware(...middlewares)];

  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  /* eslint-enable */

  const rootReducer = createReducer(history);

  const persistConfig = {
    key: 'root',
    whitelist: ['authentication', 'cssProperties'],
    storage: localForage,
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const reducerWithCleanup = createReducerWithLogout(persistedReducer, persistConfig.storage);
  const store: any = createStore(reducerWithCleanup, initialState, composeEnhancers(...enhancers));
  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);
  store.runSaga = sagaMiddleware.run;
  store.asyncReducers = {};

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      import('./reducers').then(reducerModule => {
        const createReducers = reducerModule.default;
        const nextReducers = createReducers(store.asyncReducers);

        store.replaceReducer(nextReducers);
      });
    });
  }

  return { store, persistor };
}

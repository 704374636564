import React, { useEffect } from 'react';
import styles from './Pairing.module.scss';
import Button from 'components/Atoms/Button';
import OverlayLogo from 'assets/logo.png';
import { useParams } from 'react-router-dom';
import { validatePairingRequestCreator } from 'modules/authentication';
import { UserType } from 'modules/user';
import CircularProgress from '@material-ui/core/CircularProgress';

type Props = {
  isLoading: boolean;
  validatePairing: typeof validatePairingRequestCreator;
  user: UserType;
};

const Pairing: React.FunctionComponent<Props> = ({ validatePairing, user, isLoading }) => {
  const {
    pairingToken,
    designTool,
  }: { pairingToken: string; designTool: 'figma' | 'sketch' } = useParams();

  useEffect(() => {
    validatePairing({ pairingToken });
  }, []);

  return (
    <div className={styles.pairingSection}>
      <img className={styles.logo} src={OverlayLogo} />
      {isLoading ? (
        <CircularProgress size={30} />
      ) : (
        <>
          <p className={styles.description}>Login as {user.email}</p>
          <a className={styles.button} href={`${designTool === 'figma' ? 'figma' : 'sketch'}://`}>
            <Button theme={'dark'}>Open {designTool === 'figma' ? 'Figma' : 'Sketch'}</Button>
          </a>
        </>
      )}
    </div>
  );
};

export default Pairing;

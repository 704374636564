import { googleFonts } from 'services/googleFonts';

export const fontChecker = (document => {
  let width;
  const body = document.body;

  const container = document.createElement('span');
  container.innerHTML = Array(100).join('wi');
  container.style.cssText = [
    'position:absolute',
    'width:auto',
    'font-size:128px',
    'left:-99999px',
  ].join(' !important;');

  const getWidth = (fontFamily: string) => {
    container.style.fontFamily = fontFamily;

    body.appendChild(container);
    width = container.clientWidth;
    body.removeChild(container);

    return width;
  };

  // Pre compute the widths of monospace, serif & sans-serif
  // to improve performance.
  const monoWidth = getWidth('monospace');
  const serifWidth = getWidth('serif');
  const sansWidth = getWidth('sans-serif');

  return {
    isFontAvailable: (font: string) => {
      return (
        monoWidth !== getWidth(font + ',monospace') ||
        sansWidth !== getWidth(font + ',sans-serif') ||
        serifWidth !== getWidth(font + ',serif')
      );
    },
  };
})(document);

export const isAGoogleFont = (fontName: string): boolean => {
  return googleFonts.hasOwnProperty(fontName);
};

export const loadGoogleFont = (font: { fontFamily: string; fontWeight: number }) => {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = `https://fonts.googleapis.com/css2?family=${font.fontFamily}:wght@${font.fontWeight}&display=swap`;
  document.getElementsByTagName('head')[0].appendChild(link);
};

export const loadGoogleMaterialIcons = () => {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = `https://fonts.googleapis.com/icon?family=Material+Icons`;
  document.getElementsByTagName('head')[0].appendChild(link);
};

import { takeLatest, call, put, select, fork } from 'redux-saga/effects';
import { withLoader } from 'modules/loading';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import { selectToken, withAuthentication } from 'modules/authentication';
import { createReview, updateReview } from 'services/api';
import { OverlayStoreType } from 'redux/types';
import { getType, createAction, ActionType, Reducer } from 'typesafe-actions';
import * as Sentry from '@sentry/react';
import { displayErrorToaster } from 'modules/apiError';
import { delay } from 'redux-saga';
import { ReviewsStoreType, ReviewType, ReviewTypeEnum } from './types';

const initialState: ReviewsStoreType = {
  currentReview: null,
  shouldDisplayComment: false,
  isOpen: false,
  shouldDisplayThanks: false,
};

// Actions Creators
export const createReviewRequestCreator = createAction('REVIEWS/CREATE_REVIEW.REQUEST')<{
  type: ReviewTypeEnum;
  compilableUuid: string;
  mark: number;
}>();

export const openReviewSectionRequestCreator = createAction(
  'REVIEWS/OPEN_REVIEW_SECTION.REQUEST',
)();

export const openReviewSectionWithTimeoutRequestCreator = createAction(
  'REVIEWS/OPEN_REVIEW_SECTION_WITH_TIMEOUT.REQUEST',
)();

export const closeReviewSectionRequestCreator = createAction(
  'REVIEWS/CLOSE_REVIEW_SECTION.REQUEST',
)();

export const updateReviewRequestCreator = createAction('REVIEWS/UPDATE_REVIEW.REQUEST')<{
  reviewUuid: string;
  comment: string;
}>();

export const createReviewSuccessCreator = createAction('REVIEWS/CREATE_REVIEW.SUCCESS')<{
  componentReview: ReviewType;
  shouldDisplayComment: boolean;
  shouldDisplayThanks: boolean;
}>();

type Actions = ActionType<
  | typeof createReviewSuccessCreator
  | typeof openReviewSectionRequestCreator
  | typeof closeReviewSectionRequestCreator
>;

// Selectors
export const selectReview = (state: OverlayStoreType) => {
  return state.reviews;
};

// Reducer
export const reviewsReducer: Reducer<any, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case getType(createReviewSuccessCreator):
      return {
        ...state,
        shouldDisplayComment: action.payload.shouldDisplayComment,
        shouldDisplayThanks: action.payload.shouldDisplayThanks,
        currentReview: action.payload.componentReview,
      };
    case getType(openReviewSectionRequestCreator):
      return {
        ...state,
        isOpen: true,
      };
    case getType(closeReviewSectionRequestCreator):
      return {
        ...state.componentReview,
        isOpen: false,
        shouldDisplayComment: false,
      };
    default:
      return state;
  }
};

// Sagas
function* createReviewSaga(action: ReturnType<typeof createReviewRequestCreator>) {
  try {
    const token = yield select(selectToken);
    const { body: componentReview }: { body: ReviewType } = yield call(
      createReview,
      token,
      action.payload.type,
      action.payload.compilableUuid,
      action.payload.mark,
    );
    const isABadMark = componentReview.mark < 4;
    yield put(
      createReviewSuccessCreator({
        componentReview,
        shouldDisplayComment: isABadMark,
        shouldDisplayThanks: !isABadMark,
      }),
    );
    if (!isABadMark) {
      yield delay(3000);
      yield put(closeReviewSectionRequestCreator());
    }
  } catch (e) {
    yield put(
      displayErrorToaster({
        errorMessage: 'An error occurred while sending your review.',
      }),
    );
    Sentry.captureException(e);
  }
}

function* updateReviewSaga(action: ReturnType<typeof updateReviewRequestCreator>) {
  try {
    const token = yield select(selectToken);
    const { body: componentReview }: { body: ReviewType } = yield call(
      updateReview,
      token,
      action.payload.reviewUuid,
      action.payload.comment,
    );
    yield put(
      createReviewSuccessCreator({
        componentReview,
        shouldDisplayComment: false,
        shouldDisplayThanks: true,
      }),
    );
    yield delay(3000);
    yield put(closeReviewSectionRequestCreator());
  } catch (e) {
    yield put(
      displayErrorToaster({
        errorMessage: 'An error occurred while sending your review.',
      }),
    );
    Sentry.captureException(e);
  }
}

function* openReviewWitTimeoutSaga() {
  yield delay(3000);
  yield put(openReviewSectionRequestCreator());
}

// Saga Watchers
function* watchCreateReview() {
  yield takeLatest(
    getType(createReviewRequestCreator),
    withLoader(withAuthentication(createReviewSaga), SimpleLoadingKeysEnum.createReview),
  );
}

function* watchUpdateReview() {
  yield takeLatest(
    getType(updateReviewRequestCreator),
    withLoader(withAuthentication(updateReviewSaga), SimpleLoadingKeysEnum.updateReview),
  );
}

function* watchOpenReviewWithTimeout() {
  yield takeLatest(getType(openReviewSectionWithTimeoutRequestCreator), openReviewWitTimeoutSaga);
}

// Saga export
export function* watchReviewSagas() {
  yield fork(watchOpenReviewWithTimeout);
  yield fork(watchUpdateReview);
  yield fork(watchCreateReview);
}

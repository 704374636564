import { ModalKeysEnum, ModalsStoreType } from 'modules/modals/types';
import { Reducer } from 'redux';
import { OverlayStoreType } from 'redux/types';

const initialState: ModalsStoreType = Object.values(ModalKeysEnum).reduce(
  (state: any, key: ModalKeysEnum) => {
    state[key] = false;
    return state;
  },
  {},
);

const actionTypes = {
  MODAL: {
    SHOW: 'MODAL.SHOW',
    HIDE: 'MODAL.HIDE',
  },
};

// Actions Creators
export const openModalCreator = (modalKey: ModalKeysEnum) => () => ({
  type: actionTypes.MODAL.SHOW,
  modalKey,
});

export const closeModalCreator = (modalKey: ModalKeysEnum) => () => ({
  type: actionTypes.MODAL.HIDE,
  modalKey,
});

// Selectors
export const isModalOpen = (store: OverlayStoreType, modalKey: ModalKeysEnum): boolean =>
  store.modals[modalKey];

// Reducer
export const modalsReducer: Reducer<ModalsStoreType> = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.MODAL.SHOW:
      return {
        ...state,
        [action.modalKey]: true,
      };
    case actionTypes.MODAL.HIDE:
      return {
        ...state,
        [action.modalKey]: false,
      };
    default:
      return state;
  }
};

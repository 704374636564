import React, { Children } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import OverlayHeader from 'components/Molecules/OverlayHeader';
import NotFound from 'pages/404';
import AddProjectContributorsDialog from 'components/Molecules/AddProjectContributorsDialog';
import style from './ProjectDetailLayout.module.scss';
import ProjectUsers from 'components/Molecules/ProjectUsers';
import { ReactComponent as AddIcon } from 'assets/add.svg';
import { NavLink } from 'react-router-dom';
import { ProjectType } from 'modules/projects/types';
import { isShowcaseApp } from 'services/featureToggling';
import OverlayWideLoader from 'components/Molecules/OverlayWideLoader';
import { UserType } from 'modules/user';
import {
  addProjectContributorRequestCreator,
  changeCurrentProjectCreator,
  getProjectRequestCreator,
  removeProjectContributorRequestCreator,
} from 'modules/projects';

export interface IProjectDetailProps {
  me: UserType;
  getProjectDetail: typeof getProjectRequestCreator;
  removeInvite: (projectId: string, inviteUuid: string) => void;
  isProjectLoading: boolean;
  isInviteUserToProjectLoading: boolean;
  isInviteModalOpen: boolean;
  openInviteComponentModal: () => void;
  closeInviteComponentModal: () => void;
  changeCurrentProject: typeof changeCurrentProjectCreator;
  goToTeamPage: (teamUuid: string) => void;
  addProjectContributor: typeof addProjectContributorRequestCreator;
  removeProjectContributor: typeof removeProjectContributorRequestCreator;
  project: ProjectType;
  history: History;
  addProjectUserErrorMessage: string;
  addProjectUserSuccessfulMessage: string;
  children?: typeof Children;
  getProjectErrorCode: number | null;
  projectId: string;
  removeInviteLoaders: { [uuid: string]: boolean };
}

type State = {
  open: boolean;
};

const PROJECT_TABS: Record<string, any> = {
  COMPONENTS: {
    name: 'Components',
    link: '/project/:projectId/components',
    isHiddenForShowcase: false,
  },
  STYLESHEET: {
    name: 'Stylesheet',
    link: '/project/:projectId/stylesheet',
    isHiddenForShowcase: false,
  },
  SETTINGS: {
    name: 'Settings',
    link: '/project/:projectId/settings',
    isHiddenForShowcase: true,
  },
};

class ProjectDetailLayout extends React.Component<IProjectDetailProps, State> {
  constructor(props: IProjectDetailProps) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentWillMount() {
    this.props.changeCurrentProject({ projectUuid: this.props.projectId });
    this.props.getProjectDetail({ projectUuid: this.props.projectId });
  }

  renderLoader = () => (
    <div className={style.loaderContainer}>
      <CircularProgress size={50} />
    </div>
  );

  renderDetailsTab = () => {
    return Object.keys(PROJECT_TABS).map(tab => {
      if (isShowcaseApp() && PROJECT_TABS[tab].isHiddenForShowcase) return null;
      return (
        <NavLink
          to={PROJECT_TABS[tab].link.replace(':projectId', `${this.props.projectId}`)}
          key={PROJECT_TABS[tab].name}
          activeClassName={style.selected}
          exact
          className={style.tab}
        >
          <p className={style.buttonTitle}>{PROJECT_TABS[tab].name}</p>
        </NavLink>
      );
    });
  };

  isProjectReady() {
    return this.props.project && this.props.project.contributors;
  }

  render() {
    const {
      project,
      isInviteModalOpen,
      isInviteUserToProjectLoading,
      closeInviteComponentModal,
      addProjectContributor,
      addProjectUserErrorMessage,
      addProjectUserSuccessfulMessage,
      getProjectErrorCode,
      removeProjectContributor,
      removeInviteLoaders,
      goToTeamPage,
    } = this.props;

    if (getProjectErrorCode === 403 || getProjectErrorCode === 404 || getProjectErrorCode === 401) {
      return <NotFound />;
    }

    return (
      <>
        <OverlayWideLoader />
        <OverlayHeader goBackLinkRoute={'/'} goBackLinkLabel={'My Projects'} />
        <div className={style.homeContainer}>
          <div className={style.projectTopContainer}>
            <div className={style.tabContainer}>{this.renderDetailsTab()}</div>
            {!isShowcaseApp() && (
              <div className={style.projectUsersWrapper}>
                {project && project.contributors && <ProjectUsers users={project.contributors} />}
                <button
                  title="Add contributor"
                  className={style.inviteButton}
                  onClick={this.props.openInviteComponentModal}
                >
                  <AddIcon height={16} width={16} />
                </button>
              </div>
            )}
          </div>
          <div className={style.tabContent}>{this.props.children}</div>
        </div>
        {this.isProjectReady() && (
          <AddProjectContributorsDialog
            me={this.props.me}
            goToTeamPage={goToTeamPage}
            isOpen={isInviteModalOpen}
            isInviteUserToProjectLoading={isInviteUserToProjectLoading}
            onCloseModal={closeInviteComponentModal}
            contributors={project.contributors}
            project={project}
            addProjectUserErrorMessage={addProjectUserErrorMessage}
            addProjectUserSuccessfulMessage={addProjectUserSuccessfulMessage}
            removeProjectContributor={removeProjectContributor}
            addProjectContributor={addProjectContributor}
            removeInviteLoaders={removeInviteLoaders}
          />
        )}
      </>
    );
  }
}

export default ProjectDetailLayout;

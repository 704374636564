import React, { ReactNode } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import cx from 'classnames';
import styles from './Button.module.scss';
import { ClassValue } from 'classnames/types';

type Props = {
  onClick?: () => void;
  children?: ReactNode;
  isLoading?: boolean;
  isDisabled?: boolean;
  theme?: string;
  className?: ClassValue;
  type?: 'button' | 'submit' | 'reset' | undefined;
};

class Button extends React.Component<Props> {
  handleClick = () => {
    if (this.props.isLoading) return;
    if (this.props.isDisabled) return;
    if (this.props.onClick) this.props.onClick();
  };

  renderLoader = () => <CircularProgress style={{ color: 'inherit' }} size={16} />;

  render() {
    const themeMap: Record<string, ClassValue> = {
      secondary: styles.secondary,
      dark: styles.dark,
    };

    const { isLoading, children, className, type } = this.props;

    return (
      <button
        type={type}
        className={cx(
          styles.button,
          !!this.props.theme && themeMap[this.props.theme],
          !!this.props.isLoading && styles.disable,
          !!this.props.isDisabled && styles.disable,
          className,
        )}
        onClick={this.handleClick}
        disabled={isLoading}
      >
        {isLoading ? this.renderLoader() : <>{children}</>}
      </button>
    );
  }
}

export default Button;

import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import styles from './UserQualificationDialog.module.scss';
import Tab from 'components/Atoms/Tab';
import Button from 'components/Atoms/Button';
import { CompanyEnum, JobEnum, qualifyUserRequestCreator } from 'modules/user';

interface IUserQualificationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  qualifyUser: typeof qualifyUserRequestCreator;
}

const UserQualificationDialog: React.FunctionComponent<IUserQualificationDialogProps> = ({
  isOpen,
  isLoading,
  qualifyUser,
}) => {
  const [selectedJob, selectJob] = useState<JobEnum | null>(null);
  const [selectedCompany, selectCompany] = useState<CompanyEnum | null>(null);

  const handleSelectJob = (jobName: JobEnum) => () => {
    selectJob(jobName);
  };

  const handleSelectCompany = (company: CompanyEnum) => () => {
    selectCompany(company);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (selectedCompany === null || selectedJob === null) return;

    qualifyUser({
      job: selectedJob,
      company: selectedCompany,
    });
  };

  const isFormInvalid = selectedCompany === null || selectedJob === null;

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="user-qualification-dialog-title"
      aria-describedby="user-qualification-dialog-description"
      classes={{ paper: styles.dialogContainer }}
    >
      <form className={styles.qualificationPopUp} onSubmit={handleSubmit}>
        <p className={styles.title}>Tell us more about you..</p>
        <p className={styles.description}>
          Our goal is to make the best product for you. So please, just tell us a little about you
          🙏
        </p>
        <div className={styles.form}>
          <div className={styles.formContainer}>
            <p className={styles.question}>I am :</p>
            <div className={styles.answers}>
              <Tab
                className={styles.tabVue}
                isSelected={selectedJob === JobEnum.DESIGNER}
                isActive
                onClick={handleSelectJob(JobEnum.DESIGNER)}
              >
                A Designer
              </Tab>
              <Tab
                className={styles.tabVue}
                isSelected={selectedJob === JobEnum.DEV}
                isActive
                onClick={handleSelectJob(JobEnum.DEV)}
              >
                A Developer
              </Tab>
              <Tab
                className={styles.tabVue}
                isSelected={selectedJob === JobEnum.BOTH}
                isActive
                onClick={handleSelectJob(JobEnum.BOTH)}
              >
                Both
              </Tab>
              <Tab
                className={styles.tabVue}
                isSelected={selectedJob === JobEnum.OTHER}
                isActive
                onClick={handleSelectJob(JobEnum.OTHER)}
              >
                Other
              </Tab>
            </div>
          </div>
          <div className={styles.formContainer}>
            <p className={styles.question}>And I work for/as :</p>
            <div className={styles.answers}>
              <Tab
                className={styles.tabVue}
                isSelected={selectedCompany === CompanyEnum.FREELANCE}
                isActive
                onClick={handleSelectCompany(CompanyEnum.FREELANCE)}
              >
                A Freelance
              </Tab>
              <Tab
                className={styles.tabVue}
                isSelected={selectedCompany === CompanyEnum.AGENCY}
                isActive
                onClick={handleSelectCompany(CompanyEnum.AGENCY)}
              >
                An Agency
              </Tab>
              <Tab
                className={styles.tabVue}
                isSelected={selectedCompany === CompanyEnum.STARTUP}
                isActive
                onClick={handleSelectCompany(CompanyEnum.STARTUP)}
              >
                A start-up
              </Tab>
              <Tab
                className={styles.tabVue}
                isSelected={selectedCompany === CompanyEnum.OTHER}
                isActive
                onClick={handleSelectCompany(CompanyEnum.OTHER)}
              >
                Other
              </Tab>
            </div>
          </div>
        </div>
        <Button isLoading={isLoading} type="submit" isDisabled={isFormInvalid}>
          Submit
        </Button>
      </form>
    </Dialog>
  );
};

export default UserQualificationDialog;

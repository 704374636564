import { StylesheetType } from 'modules/stylesheet/types';

export const defaultStylesheetProperty: StylesheetType = {
  uuid: '',
  typographies: [],
  colors: [],
  scssCode: '',
  jsCode: '',
  cssCode: '',
};

export const create = (entity: Partial<StylesheetType>): StylesheetType => ({
  uuid: entity.uuid ? entity.uuid : defaultStylesheetProperty.uuid,
  colors: entity.colors ? entity.colors : defaultStylesheetProperty.colors,
  typographies: entity.typographies ? entity.typographies : defaultStylesheetProperty.typographies,
  jsCode: entity.jsCode ? entity.jsCode : defaultStylesheetProperty.jsCode,
  cssCode: entity.cssCode ? entity.cssCode : defaultStylesheetProperty.cssCode,
  scssCode: entity.scssCode ? entity.scssCode : defaultStylesheetProperty.scssCode,
});

export const mergeStylesheet = (
  currentStylesheetState: StylesheetType,
  newStylesheet: StylesheetType,
) => {
  const newCurrentStylesheetState = { ...currentStylesheetState };

  for (const [key, value] of Object.entries(newStylesheet)) {
    if (Array.isArray(value) && value.length === 0) {
      continue;
    }

    // @ts-ignore
    if (value !== defaultStylesheetProperty[key]) {
      // @ts-ignore
      newCurrentStylesheetState[key] = value;
    }
  }

  return newCurrentStylesheetState;
};

import React from 'react';
import CodeBox from 'components/Molecules/CodeBox';

import style from './StylesheetViewer.module.scss';
import { StylesheetType } from 'modules/stylesheet/types';
import { ProjectType, UIFrameworkEnum } from 'modules/projects/types';

type Props = {
  project: ProjectType;
  isLoading: boolean;
  stylesheet: StylesheetType | null;
};

const StylesheetViewer: React.FunctionComponent<Props> = ({ stylesheet, isLoading, project }) => {
  const getLanguageToHighlights = () => {
    if (!project || !stylesheet) return 'scss';
    switch (project.uiFramework) {
      case UIFrameworkEnum.CSS:
        return 'css';
      case UIFrameworkEnum.SCSS:
        return 'scss';
      case UIFrameworkEnum.STYLED_COMPONENTS:
        return 'js';
      default:
        return 'scss';
    }
  };

  const getCodeToDisplay = () => {
    if (!project || !stylesheet) return '';
    switch (project.uiFramework) {
      case UIFrameworkEnum.CSS:
        return stylesheet.cssCode;
      case UIFrameworkEnum.SCSS:
        return stylesheet.scssCode;
      case UIFrameworkEnum.STYLED_COMPONENTS:
        return stylesheet.jsCode;
      default:
        return stylesheet.scssCode;
    }
  };

  return (
    <div className={style.container}>
      <div className={style.codeContainer}>
        <CodeBox
          language={getLanguageToHighlights()}
          code={getCodeToDisplay()}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default StylesheetViewer;

import { LayerStoreType } from './types';
import { ActionType, createAction, getType, Reducer } from 'typesafe-actions';
import { OverlayStoreType } from 'redux/types';
import { denormalizeLayer } from 'services/apiNormalizer';
import {
  getComponentSetComponentSuccessCreator,
  getComponentSetSuccessCreator,
} from 'modules/componentSets';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { withLoader } from 'modules/loading';
import { selectToken, withAuthentication } from 'modules/authentication';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import { displayErrorToaster } from 'modules/apiError';
import * as Sentry from '@sentry/react';
import { getComponentsSuccessCreator } from 'modules/components';
import { compileComponentFragment, compileComponentInstanceFragment } from 'services/api';
import { mergeLayer } from 'services/factory/layerFactory';
import { getComponentInstancesSuccessCreator } from 'modules/componentInstances';

const initialState: LayerStoreType = {
  map: {},
};

// Actions Creators
export const compileComponentFragmentRequestCreator = createAction(
  'LAYERS/COMPILE_COMPONENT_FRAGMENT.REQUEST',
)<{
  componentUuid: string;
  layerUuid: string;
}>();

export const compileComponentInstanceFragmentRequestCreator = createAction(
  'LAYERS/COMPILE_COMPONENT_INSTANCE_FRAGMENT.REQUEST',
)<{
  componentInstanceUuid: string;
  layerUuid: string;
}>();

export const compileFragmentSuccessCreator = createAction('LAYERS/COMPILE_FRAGMENT.SUCCESS')<{
  layerUuid: string;
  layoutCode: string;
  styleCode: string;
}>();

type LayerActions = ActionType<
  | typeof getComponentsSuccessCreator
  | typeof getComponentSetSuccessCreator
  | typeof getComponentSetComponentSuccessCreator
  | typeof getComponentInstancesSuccessCreator
  | typeof compileFragmentSuccessCreator
>;

// Selectors
export const selectLayerByUuid = (state: OverlayStoreType, layerUuid: string) => {
  return denormalizeLayer(
    state.layers.map[layerUuid],
    state.layers.map,
    state.componentInstances.map,
    state.assets.map,
    state.props.map,
  );
};

// Reducer
export const layersReducer: Reducer<any, LayerActions> = (state = initialState, action) => {
  switch (action.type) {
    case getType(getComponentsSuccessCreator):
    case getType(getComponentSetSuccessCreator):
    case getType(getComponentSetComponentSuccessCreator):
    case getType(getComponentInstancesSuccessCreator):
      const newProjectState = { ...state };
      for (const componentId in action.payload.layer) {
        if (!action.payload.layer.hasOwnProperty(componentId)) continue;
        if (newProjectState.map.hasOwnProperty(componentId)) {
          newProjectState.map[componentId] = mergeLayer(
            newProjectState.map[componentId],
            action.payload.layer[componentId],
          );
        } else {
          newProjectState.map[componentId] = action.payload.layer[componentId];
        }
      }
      return newProjectState;
    case getType(compileFragmentSuccessCreator):
      return {
        ...state,
        map: {
          ...state.map,
          [action.payload.layerUuid]: {
            ...state.map[action.payload.layerUuid],
            layoutCode: action.payload.layoutCode,
            styleCode: action.payload.styleCode,
          },
        },
      };
    default:
      return state;
  }
};

// Sagas
function* compileComponentFragmentSaga(
  action: ReturnType<typeof compileComponentFragmentRequestCreator>,
) {
  try {
    const token = yield select(selectToken);
    const { body }: { body: { layoutCode: string; styleCode: string } } = yield call(
      compileComponentFragment,
      token,
      action.payload.componentUuid,
      action.payload.layerUuid,
    );
    yield put(
      compileFragmentSuccessCreator({
        layerUuid: action.payload.layerUuid,
        layoutCode: body.layoutCode,
        styleCode: body.styleCode,
      }),
    );
  } catch (e) {
    yield put(
      displayErrorToaster({
        errorMessage: 'An error occurred while compiling this fragment.',
      }),
    );
    Sentry.captureException(e);
  }
}

function* compileComponentInstanceFragmentSaga(
  action: ReturnType<typeof compileComponentInstanceFragmentRequestCreator>,
) {
  try {
    const token = yield select(selectToken);
    const { body }: { body: { layoutCode: string; styleCode: string } } = yield call(
      compileComponentInstanceFragment,
      token,
      action.payload.componentInstanceUuid,
      action.payload.layerUuid,
    );
    yield put(
      compileFragmentSuccessCreator({
        layerUuid: action.payload.layerUuid,
        layoutCode: body.layoutCode,
        styleCode: body.styleCode,
      }),
    );
  } catch (e) {
    yield put(
      displayErrorToaster({
        errorMessage: 'An error occurred while compiling this fragment.',
      }),
    );
    Sentry.captureException(e);
  }
}

// Sagas watcher
function* watchCompileComponentFragment() {
  yield takeLatest(
    getType(compileComponentFragmentRequestCreator),
    withLoader(
      withAuthentication(compileComponentFragmentSaga),
      SimpleLoadingKeysEnum.compilingFragment,
    ),
  );
}

function* watchCompileComponentInstanceFragment() {
  yield takeLatest(
    getType(compileComponentInstanceFragmentRequestCreator),
    withLoader(
      withAuthentication(compileComponentInstanceFragmentSaga),
      SimpleLoadingKeysEnum.compilingFragment,
    ),
  );
}

// Saga export
export function* watchLayerSagas() {
  yield fork(watchCompileComponentFragment);
  yield fork(watchCompileComponentInstanceFragment);
}

export type StylesheetStoreType = {
  map: Record<string, StylesheetType>;
  colorChanges: Record<number, FrontVariableInputType>;
  typoChanges: Record<number, FrontVariableInputType>;
  isCommitStylesheetChangeSuccessful: boolean;
};

export type StylesheetType = {
  uuid: string;
  typographies: VariableTypographyType[];
  colors: VariableColorType[];
  scssCode: string;
  jsCode: string;
  cssCode: string;
};

export type VariableColorType = {
  id: number;
  validationId: string;
  name: string;
  value: string;
  type: string;
};

export type VariableTypographyType = {
  id: number;
  validationId: string;
  name: string;
  family: string;
  size: number;
  lineHeight: number;
  weight: number;
};

export enum FrontVariableStateEnum {
  NOT_MODIFIED = 'NOT_MODIFIED',
  SAVED = 'SAVED',
  VALID = 'VALID',
  DUPLICATE_NAME_ERROR = 'DUPLICATE_NAME_ERROR',
  INVALID_NAME_ERROR = 'INVALID_NAME_ERROR',
}

export enum FrontVariableChangeTypeEnum {
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum BackErrorValidationMapping {
  INVALID_NAME = FrontVariableStateEnum.INVALID_NAME_ERROR,
  DUPLICATE_NAME = FrontVariableStateEnum.DUPLICATE_NAME_ERROR,
}

export type BackUpdateVariable = {
  id: number;
  name: string;
};

export type FrontVariableInputType = {
  id: number;
  name: string;
  validationState: FrontVariableStateEnum;
  type: FrontVariableChangeTypeEnum;
};

export type VariableType = VariableColorType | VariableTypographyType;

export type VariableValidationType = {
  message: BackErrorValidationMapping;
  data: VariableType;
};

import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ProjectDetail from './pages/ProjectDetail';
import Stylesheet from './pages/Stylesheet';
import Settings from './pages/Settings';
import NotFound from './pages/404';
import Pairing from './pages/Pairing';

import AnonymousRoute from 'components/HightOrderComponents/AnonymousRoute';
import PrivateRoute from 'components/HightOrderComponents/PrivateRoute';

export interface ProjectUuidMatchParams {
  id: string;
}

export interface ComponentIdMatchParams {
  componentId: string;
}

export interface ComponentInstanceIdMatchParams {
  componentInstanceId: string;
}

const Loading = () => <div>Loading...</div>;

const lazyComponent = (name: string) => lazy(() => import(`./pages/${name}`));

const RedirectProject = (props: any) => (
  <Redirect to={`/project/${props.match.params.id}/components`} />
);

const routes: React.FunctionComponent = () => (
  <Suspense fallback={<Loading />}>
    <Switch>
      <PrivateRoute exact path="/project/:id/components" component={ProjectDetail} />
      <PrivateRoute path="/teams/:teamUuid?" component={lazyComponent('TeamList')} />
      <PrivateRoute
        path="/project/:id/component-sets/:componentId"
        component={lazyComponent('ComponentSetDetail')}
      />
      <PrivateRoute
        path="/project/:id/component-instances/:componentInstanceId"
        component={lazyComponent('ComponentInstanceDetail')}
      />
      <PrivateRoute exact path="/project/:id/stylesheet" component={Stylesheet} />
      <PrivateRoute exact path="/project/:id/settings" component={Settings} />
      <PrivateRoute exact path="/pairing/:pairingToken/:designTool" component={Pairing} />
      <PrivateRoute path="/project/:id" component={RedirectProject} />
      <PrivateRoute exact path="/" component={lazyComponent('ProjectList')} />
      <AnonymousRoute exact path="/login" component={lazyComponent('Login')} />
      <AnonymousRoute exact path="/signUp" component={lazyComponent('SignUp')} />
      <AnonymousRoute exact path="/appsumo-signUp" component={lazyComponent('AppSumoSignUp')} />
      <AnonymousRoute exact path="/forgot-password" component={lazyComponent('ForgotPassword')} />
      <AnonymousRoute exact path="/reset-password" component={lazyComponent('ResetPassword')} />
      <AnonymousRoute
        exact
        path="/validate-account/:token"
        component={lazyComponent('SignUpSuccess')}
      />
      <AnonymousRoute
        exact
        path="/pending-account/:email"
        component={lazyComponent('SignUpSuccess')}
      />
      <AnonymousRoute exact path="/signUp-success" component={lazyComponent('SignUpSuccess')} />
      <Route exact path="/500" component={lazyComponent('500')} />
      <Route exact path="/terms" component={lazyComponent('Terms')} />
      <Route exact path="/privacy" component={lazyComponent('Privacy')} />
      <Route render={() => <NotFound />} />
    </Switch>
  </Suspense>
);

export default routes;

import React, { ReactNode } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { RouteComponentProps, StaticContext } from 'react-router';
import { meRequestCreator } from 'modules/user';

type Props = RouteComponentProps<{}, StaticContext, { from: { pathname: string } }> & {
  component: ReactNode;
  isAuthenticated: boolean;
  getUserInfo: typeof meRequestCreator;
};

class PrivateRoute extends React.Component<Props> {
  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.getUserInfo();
    }
  }

  render() {
    const { component: Component, isAuthenticated, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props => {
          return isAuthenticated ? (
            // @ts-ignore
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          );
        }}
      />
    );
  }
}

export default PrivateRoute;

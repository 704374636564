import { OverlayStoreType } from 'redux/types';
import { ActionType, createAction, getType } from 'typesafe-actions';
import { ApiErrorStoreType } from 'modules/apiError/types';
import { Reducer } from 'redux';

const initialState: ApiErrorStoreType = {
  errorsToDisplay: [],
};

// Action Creators
export const displayErrorToaster = createAction('API_ERROR/ADD_ERROR.REQUEST')<{
  errorMessage: string;
}>();

export const resetErrorsRequest = createAction('API_ERROR/RESET_ERROR.REQUEST')();

// Selectors
export const selectErrorsToDisplay = (store: OverlayStoreType) => store.apiError.errorsToDisplay;

type ApiErrorActions =
  | ActionType<typeof displayErrorToaster>
  | ActionType<typeof resetErrorsRequest>;

// Reducer
export const apiErrorReducer: Reducer<ApiErrorStoreType, ApiErrorActions> = (
  state: ApiErrorStoreType = initialState,
  action: any,
) => {
  switch (action.type) {
    case getType(displayErrorToaster):
      return {
        ...state,
        errorsToDisplay: [...state.errorsToDisplay, action.payload.errorMessage],
      };
    case getType(resetErrorsRequest):
      return initialState;
    default:
      return state;
  }
};

import { AssetType } from 'modules/assets/types';
import { LayerType } from 'modules/layers/types';
import { ComponentPropsType } from 'modules/props/types';

export type ComponentsStoreType = {
  map: { [uuid: string]: NormalizedComponentType };
};

export type CompilableEntity = {
  type: 'COMPONENT' | 'INSTANCE';
  uuid: string;
  name: string;
  previewHeight: number;
  previewWidth: number;
  cssPreviewCode: string;
  htmlPreviewCode: string;
  designTool: ComponentEnumDesignTool;
  rootLayer: LayerType;
  props: ComponentPropsType[];
  dependencies: CompilableEntity[];
  reviewed: boolean;
  missingFonts: string[];
};

export type InspectableEntity = {
  type: 'COMPONENT' | 'LAYER' | 'INSTANCE';
  uuid: string;
  name: string;
  layoutCode: string;
  styleCode: string | null;
  styleVariables: {
    typos: TypoVariable[];
    colors: ColorVariable[];
  };
  assets: AssetType[];
};

export type ColorVariable = {
  uuid: string;
  name: string;
  value: string;
};

export type TypoVariable = {
  uuid: string;
  name: string;
  family: string;
};

export type ComponentType = CompilableEntity &
  InspectableEntity & {
    readonly type: 'COMPONENT';
    previewImage: string;
    variantKey: string | null;
  };

export enum ComponentEnumDesignTool {
  SKETCH = 'SKETCH',
  FIGMA = 'FIGMA',
}

export type ComponentProps = {
  uuid: string;
  name: string;
  active: boolean;
  defaultValue: string;
};

export type NormalizedComponentType = Omit<
  ComponentType,
  'dependencies' | 'assets' | 'rootLayer' | 'props'
> & {
  dependencies: string[];
  assets: string[];
  props: string[];
  rootLayer: string;
};

import { ComponentEnumDesignTool } from 'modules/components/types';
import { NormalizedComponentSetType } from 'modules/componentSets/types';

export const defaultComponentSetProperty: NormalizedComponentSetType = {
  uuid: '',
  name: '',
  type: 'SINGLE',
  variantCount: 0,
  designTool: ComponentEnumDesignTool.FIGMA,
  previewImage: '',
  category: null,
  selectedComponent: null,
  selectedComponentVariantKey: null,
  variantOptions: {},
};

export const create = (
  entity: Partial<NormalizedComponentSetType>,
): NormalizedComponentSetType => ({
  uuid: entity.uuid ? entity.uuid : defaultComponentSetProperty.uuid,
  name: entity.name ? entity.name : defaultComponentSetProperty.name,
  type: entity.type ? entity.type : defaultComponentSetProperty.type,
  variantOptions: entity.variantOptions
    ? entity.variantOptions
    : defaultComponentSetProperty.variantOptions,
  variantCount: entity.variantCount
    ? entity.variantCount
    : defaultComponentSetProperty.variantCount,
  designTool: entity.designTool ? entity.designTool : defaultComponentSetProperty.designTool,
  selectedComponent: entity.selectedComponent
    ? entity.selectedComponent
    : defaultComponentSetProperty.selectedComponent,
  selectedComponentVariantKey: entity.selectedComponentVariantKey
    ? entity.selectedComponentVariantKey
    : defaultComponentSetProperty.selectedComponentVariantKey,
  previewImage: entity.previewImage
    ? entity.previewImage
    : defaultComponentSetProperty.previewImage,
  category: entity.category ? entity.category : defaultComponentSetProperty.category,
});

export const mergeComponentSet = (
  currentComponentState: NormalizedComponentSetType,
  newComponent: NormalizedComponentSetType,
) => {
  const newCurrentComponentState = { ...currentComponentState };

  for (const [key, value] of Object.entries(newComponent)) {
    // @ts-ignore
    if (value !== defaultComponentSetProperty[key]) {
      // @ts-ignore
      newCurrentComponentState[key] = value;
    }
  }

  return newCurrentComponentState;
};

import { connect } from 'react-redux';
import ProjectDetail, { IProjectDetailProps } from './ProjectDetail.component';
import { selectProject, updateProjectBetaUserRequestCreator } from 'modules/projects';
import { isLoading } from 'modules/loading';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import { closeModalCreator, isModalOpen, openModalCreator } from 'modules/modals';
import { OverlayStoreType } from 'redux/types';
import { ModalKeysEnum } from 'modules/modals/types';
import { deleteComponentSetRequestCreator } from 'modules/componentSets';

const mapStateToProps = (state: OverlayStoreType, ownProps: IProjectDetailProps) => ({
  isProjectLoading: isLoading(state, SimpleLoadingKeysEnum.getProject),
  isSubscribingToBeta: isLoading(state, SimpleLoadingKeysEnum.subscribeToBeta),
  isDeletingComponent: isLoading(state, SimpleLoadingKeysEnum.deleteComponent),
  isDeleteModalOpen: isModalOpen(state, ModalKeysEnum.DELETE_COMPONENT),
  project: selectProject(state, ownProps.match.params.id),
  projectUuid: ownProps.match.params.id,
});

const mapDispatchToProps = {
  deleteComponent: deleteComponentSetRequestCreator,
  openDeleteComponentModal: openModalCreator(ModalKeysEnum.DELETE_COMPONENT),
  closeDeleteComponentModal: closeModalCreator(ModalKeysEnum.DELETE_COMPONENT),
  updateProjectBetaUser: updateProjectBetaUserRequestCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetail);

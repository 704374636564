import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './ComponentList.module.scss';
import { ReactComponent as SketchComponentPlaceholder } from 'assets/sketch-component.svg';
import { ReactComponent as FigmaComponentPlaceholder } from 'assets/figma-component.svg';
import RemoveButton from 'components/Atoms/RemoveButton';
import { ComponentEnumDesignTool } from 'modules/components/types';
import SelectButton from 'components/Atoms/SelectButton';
import classnames from 'classnames';
import EditableText from 'components/Atoms/EditableText';
import { isShowcaseApp } from 'services/featureToggling';
import { ComponentSetType } from 'modules/componentSets/types';
import { updateComponentSetNameRequestCreator } from 'modules/componentSets';

type Props = {
  component: ComponentSetType;
  selectedComponents?: string[];
  componentLinksMustBeOpenInANewTab?: boolean;
  onDelete?: (component: ComponentSetType) => void;
  onSelect?: (component: ComponentSetType) => void;
  onUpdateComponentName?: typeof updateComponentSetNameRequestCreator;
  handleSelectComponent: MouseEventHandler;
  handleRemoveComponent: MouseEventHandler;
  projectUuid: string;
};

const ComponentCard: React.FunctionComponent<Props> = ({
  selectedComponents,
  onUpdateComponentName,
  componentLinksMustBeOpenInANewTab,
  handleSelectComponent,
  handleRemoveComponent,
  onDelete,
  onSelect,
  projectUuid,
  component,
}) => {
  const [componentNameInput, setComponentNameInput] = useState<string>('');
  const componentNameRef = useRef(null);

  useEffect(() => {
    if (component && component.name && '' === componentNameInput) {
      setComponentNameInput(component.name);
    }
  }, [component]);

  const handleComponentNameChange = (event: any) => {
    const newName = event.target.value;
    setComponentNameInput(newName);
  };

  const submitNewComponentName = (event: any) => {
    event.preventDefault();
    if (!onUpdateComponentName) return;

    if ('' === componentNameInput) {
      return;
    }

    if (component.name === componentNameInput) {
      return;
    }

    if (null !== componentNameRef && null !== componentNameRef.current) {
      // @ts-ignore
      componentNameRef.current.blur();
    }
    onUpdateComponentName({
      projectUuid,
      newName: componentNameInput,
      componentSetUuid: component.uuid,
    });
  };

  const renderPreview = (component: ComponentSetType) => {
    return (
      <>
        {component.previewImage ? (
          <img src={component.previewImage} alt="component_preview" className={styles.preview} />
        ) : component.designTool === ComponentEnumDesignTool.FIGMA ? (
          <FigmaComponentPlaceholder className={styles.preview} />
        ) : (
          <SketchComponentPlaceholder className={styles.preview} />
        )}
      </>
    );
  };

  const isComponentSelected =
    !!selectedComponents && -1 !== selectedComponents.indexOf(component.uuid);

  return (
    <div>
      <Link
        className={classnames(styles.componentCard, isComponentSelected && styles.selected)}
        key={component.uuid}
        to={`/project/${projectUuid}/component-sets/${component.uuid}`}
        target={componentLinksMustBeOpenInANewTab ? '_blank' : undefined}
      >
        {onDelete && <RemoveButton className={styles.deleteIcon} onClick={handleRemoveComponent} />}
        {onSelect && selectedComponents && (
          <SelectButton
            isSelected={isComponentSelected}
            className={styles.selectIcon}
            onClick={handleSelectComponent}
          />
        )}
        {renderPreview(component)}
        {component.type === 'VARIANT' && (
          <div className={styles.variantLabel}>
            <p className={styles.variantLabelText}>{component.variantCount} variants</p>
          </div>
        )}
      </Link>
      {onUpdateComponentName && !isShowcaseApp() ? (
        <EditableText
          placeholder={component.name}
          inputRef={componentNameRef}
          className={styles.componentName}
          value={componentNameInput}
          onChange={handleComponentNameChange}
          onBlur={submitNewComponentName}
          onSubmit={submitNewComponentName}
        />
      ) : (
        <p className={styles.componentText}>{component.name}</p>
      )}
    </div>
  );
};

export default ComponentCard;

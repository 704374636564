import { connect } from 'react-redux';
import ProjectDetailLayout, { IProjectDetailProps } from './ProjectDetailLayout.component';
import {
  getProjectRequestCreator,
  selectProject,
  addProjectContributorRequestCreator,
  removeProjectContributorRequestCreator,
  selectAddProjectUserErrorMessage,
  selectAddProjectUserSuccessfulMessage,
  selectGetProjectErrorCode,
  changeCurrentProjectCreator,
} from 'modules/projects';
import { isLoading, selectRemoveInviteLoaders } from 'modules/loading';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import { openModalCreator, isModalOpen, closeModalCreator } from 'modules/modals';
import { OverlayStoreType } from 'redux/types';
import { ModalKeysEnum } from 'modules/modals/types';
import { selectUserInfo } from 'modules/user';
import { push } from 'connected-react-router';

const mapStateToProps = (state: OverlayStoreType, ownProps: IProjectDetailProps) => ({
  me: selectUserInfo(state),
  isProjectLoading: isLoading(state, SimpleLoadingKeysEnum.getProject),
  isInviteUserToProjectLoading: isLoading(state, SimpleLoadingKeysEnum.inviteUserToProject),
  isInviteModalOpen: isModalOpen(state, ModalKeysEnum.INVITE_CONTRIBUTOR),
  project: selectProject(state, ownProps.projectId),
  addProjectUserErrorMessage: selectAddProjectUserErrorMessage(state),
  addProjectUserSuccessfulMessage: selectAddProjectUserSuccessfulMessage(state),
  getProjectErrorCode: selectGetProjectErrorCode(state),
  removeInviteLoaders: selectRemoveInviteLoaders(state),
});

const mapDispatchToProps = {
  getProjectDetail: getProjectRequestCreator,
  openInviteComponentModal: openModalCreator(ModalKeysEnum.INVITE_CONTRIBUTOR),
  closeInviteComponentModal: closeModalCreator(ModalKeysEnum.INVITE_CONTRIBUTOR),
  changeCurrentProject: changeCurrentProjectCreator,
  goToTeamPage: (teamUuid: string) => push(`/teams/${teamUuid}`),
  removeProjectContributor: removeProjectContributorRequestCreator,
  addProjectContributor: addProjectContributorRequestCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetailLayout);

export type UserStoreType = {
  user: UserType;
  subscriptionIsSuccessful: boolean;
  subscriptionIsFailed: boolean;
  pricePreview: number | null;
};

export enum JobEnum {
  DESIGNER = 'DESIGNER',
  DEV = 'DEV',
  BOTH = 'BOTH',
  OTHER = 'OTHER',
}

export enum CompanyEnum {
  FREELANCE = 'FREELANCE',
  AGENCY = 'AGENCY',
  STARTUP = 'STARTUP',
  OTHER = 'OTHER',
}

export type UserType = {
  email: string;
  subscriptionId: string | null;
  currentPlan: OverlayPlanEnum | null;
  remainingExport: number | null;
  hasRemainingExport: boolean;
  cancelAtPeriodEnd: boolean;
  hasAlreadyLogin: boolean;
  hasDismissReview: boolean;
  currentPeriodEnd: string | null;
  paymentRecurringInterval: OverlayPaymentRecurringInterval | null;
  last4: string | null;
  expMonth: number | null;
  expYear: number | null;
  trialStartedAt: Date | null;
  billingEmail: string | null;
  extraBillingInfo: string | null;
  companyName: string | null;
  projectOwnedCount: number | null;
};

export type Invoice = {
  amount: number;
  created: string;
  pdfUrl: string;
  planName: string;
};

export enum OverlayPaymentRecurringInterval {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export enum OverlayTeamPlanEnum {
  START = 'start',
  SCALE = 'scale',
  FREE = 'free',
  APPSUMO = 'appSumo',
}

export enum OverlayPlanEnum {
  PREMIUM = 'premium',
  STARTER = 'starter',
  START = 'start',
  SCALE = 'scale',
  TRIAL = 'trial',
  FREE = 'free',
  TRIAL_EXPIRED = 'trial_expired',
}

import { connect } from 'react-redux';
import CategoryList from './CategoryList.component';
import { OverlayStoreType } from 'redux/types';
import {
  changeLastCategoryCreatedRequestCreator,
  changeLastCategoryUpdatedRequestCreator,
  createCategoryRequestCreator,
  deleteCategoryRequestCreator,
  selectLastCreatedCategory,
  selectLastUpdatedCategory,
  updateCategoryRequestCreator,
} from 'modules/categories';
import { isLoading } from 'modules/loading';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import { changeProjectCategoryOrderRequestCreator } from 'modules/projects';
import { updateComponentSetNameRequestCreator } from 'modules/componentSets';

const mapStateToProps = (state: OverlayStoreType) => ({
  isCreatingCategory: isLoading(state, SimpleLoadingKeysEnum.createCategory),
  lastCreatedCategory: selectLastCreatedCategory(state),
  lastUpdatedCategory: selectLastUpdatedCategory(state),
});

const mapDispatchToProps = {
  deleteCategory: deleteCategoryRequestCreator,
  createCategory: createCategoryRequestCreator,
  updateCategory: updateCategoryRequestCreator,
  changeProjectCategoryOrder: changeProjectCategoryOrderRequestCreator,
  changeLastCategoryCreated: changeLastCategoryCreatedRequestCreator,
  changeLastCategoryUpdated: changeLastCategoryUpdatedRequestCreator,
  updateComponentName: updateComponentSetNameRequestCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);

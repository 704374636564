import { connect } from 'react-redux';
import StylesheetSection from './StylesheetSection.component';
import { isLoading } from 'modules/loading';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import {
  selectCurrentStylesheetTypos,
  selectCurrentStylesheetColors,
  selectCurrentStylesheetTextColors,
  updateStylesheetRequestCreator,
  selectIsCommitStylesheetChangesSuccessful,
  selectCurrentStylesheet,
  selectColorChanges,
  selectTypoChanges,
  selectStylesheetHasInvalidChanges,
  changeTypoNameCreator,
  changeColorNameCreator,
  resetStylesheetChangesCreator,
  removeTypoCreator,
  removeColorCreator,
} from 'modules/stylesheet';
import { OverlayStoreType } from 'redux/types';

const mapStateToProps = (state: OverlayStoreType) => ({
  textColors: selectCurrentStylesheetTextColors(state),
  stylesheet: selectCurrentStylesheet(state),
  colorChanges: selectColorChanges(state),
  typoChanges: selectTypoChanges(state),
  colors: selectCurrentStylesheetColors(state),
  typos: selectCurrentStylesheetTypos(state),
  stylesheetHasInvalidChanges: selectStylesheetHasInvalidChanges(state),
  isCommitStylesheetChangesSuccessful: selectIsCommitStylesheetChangesSuccessful(state),
  isLoading:
    isLoading(state, SimpleLoadingKeysEnum.getStyleSheet) ||
    isLoading(state, SimpleLoadingKeysEnum.updateStyleSheet),
});

const mapDispatchToProps = {
  updateStylesheet: updateStylesheetRequestCreator,
  changeColorName: changeColorNameCreator,
  changeTypoName: changeTypoNameCreator,
  removeTypo: removeTypoCreator,
  removeColor: removeColorCreator,
  resetStylesheetChanges: resetStylesheetChangesCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(StylesheetSection);

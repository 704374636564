import { connect } from 'react-redux';
import PrivateRoute from './PrivateRoute.component';
import { isAuthenticated } from 'modules/authentication';
import { OverlayStoreType } from 'redux/types';
import { meRequestCreator } from 'modules/user';

const mapStateToProps = (state: OverlayStoreType) => ({
  isAuthenticated: isAuthenticated(state),
});

const mapDispatchToProps = {
  getUserInfo: meRequestCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);

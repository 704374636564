import { connect } from 'react-redux';
import Pairing from './Pairing.component';
import { OverlayStoreType } from 'redux/types';
import { validatePairingRequestCreator } from 'modules/authentication';
import { selectUserInfo } from 'modules/user';
import { isLoading } from 'modules/loading';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';

const mapStateToProps = (state: OverlayStoreType) => ({
  user: selectUserInfo(state),
  isLoading: isLoading(state, SimpleLoadingKeysEnum.getUserInfo),
});

const mapDispatchToProps = {
  validatePairing: validatePairingRequestCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(Pairing);

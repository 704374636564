import React from 'react';
import styles from './ProjectUsers.module.scss';
import UserBadge from 'components/Atoms/UserBadge';
import { ProjectContributorType } from 'modules/projects/types';

const MAX_DISPLAYED_USERS = 3;

type Props = {
  users: ProjectContributorType[];
};

class ProjectUsers extends React.Component<Props> {
  get otherUsersNumber(): number {
    return this.props.users.length - MAX_DISPLAYED_USERS > 0
      ? this.props.users.length - MAX_DISPLAYED_USERS
      : 0;
  }

  get otherUsersEmails(): string {
    return this.props.users
      .slice(MAX_DISPLAYED_USERS)
      .reduce((acc, user) => `${acc}${user.email}\n`, '');
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <p className={styles.title}>Project members:</p>
        {this.props.users.slice(0, MAX_DISPLAYED_USERS).map((user, index) => (
          <div className={styles.userBadge} key={user.uuid}>
            <UserBadge variant={index} title={user.email}>
              {user.email[0].toUpperCase()}
            </UserBadge>
          </div>
        ))}
        {!!this.otherUsersNumber && (
          <UserBadge title={this.otherUsersEmails}>+{this.otherUsersNumber}</UserBadge>
        )}
      </div>
    );
  }
}

export default ProjectUsers;

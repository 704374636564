import { createMuiTheme } from '@material-ui/core/styles';

export const styleguide = {
  colors: {
    backgroundColor: '#f9fdff',
    primary: '#203f54',
    secondary: '#00b9b0',
    cyan: 'rgba(238,248,253,1)',
    white: 'white',
    componentCardGrey: '#f0f5f7',
    componentCardText: '#4e6574',
    componentCardSelectedState: '#eef8fd',
  },
  fontFamily: {
    ubuntu: '"Ubuntu", "Helvetica", "Arial", sans-serif',
  },
  fontSize: {
    h1: 40,
    h2: 30,
    h3: 25,
    h4: 20,
    h5: 16,
  },
  fontWeight: {
    normal: 600,
    bold: 'bold',
  },
  codeMaxWidth: 500,
};

export const overlayTheme = createMuiTheme({
  overrides: {
    MuiTypography: {
      title: {
        fontSize: styleguide.fontSize.h1,
        fontWeight: styleguide.fontWeight.normal,
        color: 'inherit',
      },
      display1: {
        fontSize: styleguide.fontSize.h2,
        fontWeight: styleguide.fontWeight.normal,
        color: 'inherit',
      },
      display2: {
        fontSize: styleguide.fontSize.h3,
        fontWeight: styleguide.fontWeight.normal,
        color: 'inherit',
      },
      display3: {
        fontSize: styleguide.fontSize.h4,
        fontWeight: styleguide.fontWeight.normal,
        color: 'inherit',
      },
      display4: {
        fontSize: styleguide.fontSize.h5,
        fontWeight: styleguide.fontWeight.normal,
        color: 'inherit',
      },
      root: {
        color: styleguide.colors.primary,
        fontFamily: styleguide.fontFamily.josefinSans,
      },
      subheading: {
        fontSize: styleguide.fontSize.h3,
        color: 'inherit',
        fontWeight: styleguide.fontWeight.normal,
      },
    },
    MuiInput: {
      input: {
        padding: '17px 12px',
      },
      root: {
        border: 'solid 1px',
        backgroundColor: styleguide.colors.white,
        borderRadius: 3,
        borderColor: styleguide.colors.borderInput,
        '&:after': {
          display: 'none',
        },
        '&:before': {
          display: 'none',
        },
      },
    },
  },
  typography: {
    fontFamily: styleguide.fontFamily.josefinSans,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    primary: {
      main: styleguide.colors.primary,
    },
    secondary: {
      main: styleguide.colors.secondary,
    },
  },
});

import React from 'react';
import styles from './NotFound.module.scss';
import Button from 'components/Atoms/Button';
import { Link } from 'react-router-dom';

const NotFound: React.FunctionComponent = () => (
  <div className={styles.container}>
    <div className={styles.contentContainer}>
      <div className={styles.titleContainer}>
        <p className={styles.title}>4O4</p>
        <p className={styles.title}>Oh, oh, oh</p>
      </div>
      <p className={styles.description}>Seems that your are swimming in the wrong place...</p>
      <Link to={'/'}>
        <Button theme={'dark'}>Get back to the boat</Button>
      </Link>
    </div>
  </div>
);

export default NotFound;

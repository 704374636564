import React, { ReactNode } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { RouteComponentProps, StaticContext } from 'react-router';

type Props = RouteComponentProps<{}, StaticContext, { from: { pathname: string } }> & {
  component: ReactNode;
  isAuthenticated: boolean;
};

class AnonymousRoute extends React.Component<Props> {
  render() {
    const { component: Component, isAuthenticated, ...rest } = this.props;
    const { from } = this.props.location.state || {
      from: { pathname: '/' },
    };

    return (
      <Route
        {...rest}
        render={props => {
          // @ts-ignore
          return isAuthenticated ? <Redirect to={from} /> : <Component {...props} />;
        }}
      />
    );
  }
}

export default AnonymousRoute;

import { call, put } from 'redux-saga/effects';
import { OverlayStoreType } from 'redux/types';
import { Reducer } from 'redux';
import {
  LoadingStoreType,
  NestedLoadingKeysEnum,
  SimpleLoadingKeysEnum,
} from 'modules/loading/types';
import { ActionType, createAction, getType } from 'typesafe-actions';

const initialState: LoadingStoreType = {
  ...Object.values(SimpleLoadingKeysEnum).reduce((state: any, value) => {
    state[value] = false;
    return state;
  }, {}),
  ...Object.values(NestedLoadingKeysEnum).reduce((state: any, value) => {
    state[value] = {};
    return state;
  }, {}),
};

// Action Creator
export const startLoadingActionCreator = createAction('LOADING/LOADING.START')<{
  key: SimpleLoadingKeysEnum;
}>();

export const stopLoadingActionCreator = createAction('LOADING/LOADING.STOP')<{
  key: SimpleLoadingKeysEnum;
}>();

export const startLoadingRemoveInvite = createAction('LOADING/REMOVE_INVITE.START')<{
  inviteId: number;
}>();

export const stopLoadingRemoveInvite = createAction('LOADING/REMOVE_INVITE.STOP')<{
  inviteId: number;
}>();

type LoadingActions =
  | ActionType<typeof startLoadingActionCreator>
  | ActionType<typeof stopLoadingActionCreator>
  | ActionType<typeof startLoadingRemoveInvite>
  | ActionType<typeof stopLoadingRemoveInvite>;

// Selectors
export const isLoading = (store: OverlayStoreType, key: SimpleLoadingKeysEnum) =>
  store.loading[key];

export const selectRemoveInviteLoaders = (store: OverlayStoreType) =>
  store.loading[NestedLoadingKeysEnum.removeInviteFromProject];

// Reducer
export const loadingReducer: Reducer<LoadingStoreType, LoadingActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getType(startLoadingActionCreator):
      return {
        ...state,
        [action.payload.key]: true,
      };
    case getType(stopLoadingActionCreator):
      return {
        ...state,
        [action.payload.key]: false,
      };
    case getType(startLoadingRemoveInvite):
      return {
        ...state,
        [NestedLoadingKeysEnum.removeInviteFromProject]: {
          ...state[NestedLoadingKeysEnum.removeInviteFromProject],
          [action.payload.inviteId]: true,
        },
      };
    case getType(stopLoadingRemoveInvite):
      return {
        ...state,
        [NestedLoadingKeysEnum.removeInviteFromProject]: {
          ...state[NestedLoadingKeysEnum.removeInviteFromProject],
          [action.payload.inviteId]: false,
        },
      };
    default:
      return state;
  }
};

export const withLoader = (saga: any, key: SimpleLoadingKeysEnum) =>
  function*(...args: any[]) {
    yield put(startLoadingActionCreator({ key }));
    yield call(saga, args[0], args[1], args[2], args[3], args[4], args[5], args[6]);
    yield put(stopLoadingActionCreator({ key }));
  };

export const withRemoveInviteLoader = (saga: any) =>
  function*(...args: any[]) {
    yield put(startLoadingRemoveInvite({ inviteId: args[0].inviteId }));
    yield call(saga, args[0], args[1], args[2], args[3], args[4], args[5], args[6]);
    yield put(stopLoadingRemoveInvite({ inviteId: args[0].inviteId }));
  };

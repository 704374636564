import React, { ChangeEventHandler, FormEventHandler, LegacyRef } from 'react';
import styles from './EditableText.module.scss';
import { ClassValue } from 'classnames/types';
import classnames from 'classnames';

interface IProps {
  onChange?: ChangeEventHandler;
  onBlur?: ChangeEventHandler;
  onSubmit?: FormEventHandler;
  className?: ClassValue;
  value?: string;
  inputRef: LegacyRef<HTMLInputElement>;
  theme?: string;
  required?: boolean;
  placeholder: string;
}

const EditableText: React.FunctionComponent<IProps> = ({
  onChange,
  onSubmit,
  onBlur,
  value,
  placeholder,
  className,
  inputRef,
}) => {
  return (
    <form onSubmit={onSubmit} className={styles.editableTextForm}>
      <input
        placeholder={placeholder}
        className={classnames(styles.editableText, className)}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        ref={inputRef}
      />
    </form>
  );
};

export default EditableText;

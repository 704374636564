import { call, takeLatest, put, fork } from 'redux-saga/effects';
import { withLoader } from 'modules/loading';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import { oldValidateAccount, validateAccount } from 'services/api';
import { ValidateAccountStoreType } from 'modules/validateAccount/types';
import { OverlayStoreType } from 'redux/types';
import { ActionType, createAction, getType, Reducer } from 'typesafe-actions';
import * as Sentry from '@sentry/react';

export const validationErrorsMapper: Record<string, string> = {
  '401': 'Invalid validation code',
  default: 'Unknown error',
};

const initialState: ValidateAccountStoreType = {
  validated: false,
  error: '',
};

export const oldValidateAccountRequestCreator = createAction(
  'VALIDATE_ACCOUNT/OLD_VALIDATE_ACCOUNT.REQUEST',
)<{
  token: string;
}>();

export const validateAccountRequestCreator = createAction(
  'VALIDATE_ACCOUNT/VALIDATE_ACCOUNT.REQUEST',
)<{
  code: string;
  email: string;
}>();

export const validateAccountSuccessCreator = createAction(
  'VALIDATE_ACCOUNT/VALIDATE_ACCOUNT.SUCCESS',
)();

export const validateAccountErrorCreator = createAction('VALIDATE_ACCOUNT/VALIDATE_ACCOUNT.ERROR')<{
  error: string;
}>();

type ValidateAccountActions =
  | ActionType<typeof validateAccountSuccessCreator>
  | ActionType<typeof validateAccountErrorCreator>;

export const isValidated = (store: OverlayStoreType) => store.validateAccount.validated;
export const getAccountValidationError = (store: OverlayStoreType) => store.validateAccount.error;

export const validateAccountReducer: Reducer<ValidateAccountStoreType, ValidateAccountActions> = (
  state: ValidateAccountStoreType = initialState,
  action,
): ValidateAccountStoreType => {
  switch (action.type) {
    case getType(validateAccountSuccessCreator):
      return { error: '', validated: true };
    case getType(validateAccountErrorCreator):
      return { ...state, error: action.payload.error };
    default:
      return state;
  }
};

export function* oldValidateAccountSaga(
  action: ReturnType<typeof oldValidateAccountRequestCreator>,
) {
  try {
    yield call(oldValidateAccount, action.payload.token);
    yield put(validateAccountSuccessCreator());
  } catch (e) {
    Sentry.captureException(e);
    yield put(
      validateAccountErrorCreator({
        error: validationErrorsMapper[e.status] || validationErrorsMapper.default,
      }),
    );
  }
}

export function* validateAccountSaga(action: ReturnType<typeof validateAccountRequestCreator>) {
  try {
    yield call(validateAccount, action.payload.code, action.payload.email);
    yield put(validateAccountSuccessCreator());
  } catch (e) {
    Sentry.captureException(e);
    yield put(
      validateAccountErrorCreator({
        error: validationErrorsMapper[e.status] || validationErrorsMapper.default,
      }),
    );
  }
}

function* watchOldAccountValidation() {
  yield takeLatest(
    getType(oldValidateAccountRequestCreator),
    withLoader(oldValidateAccountSaga, SimpleLoadingKeysEnum.validateAccount),
  );
}

function* watchValidateAccount() {
  yield takeLatest(
    getType(validateAccountRequestCreator),
    withLoader(validateAccountSaga, SimpleLoadingKeysEnum.validateAccount),
  );
}

// Saga export
export function* watchValidateAccountSagas() {
  yield fork(watchOldAccountValidation);
  yield fork(watchValidateAccount);
}

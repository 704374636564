import { connect } from 'react-redux';
import OverlayHeader from './OverlayHeader.component';
import { isAuthenticated, logoutRequestCreator } from 'modules/authentication';
import { OverlayStoreType } from 'redux/types';

const mapStateToProps = (state: OverlayStoreType) => ({
  isAuthenticated: isAuthenticated(state),
});

const mapDispatchToProps = {
  logout: logoutRequestCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(OverlayHeader);

import { actionTypes } from 'modules/authentication';
import { AuthenticationErrorStoreType } from 'modules/authenticationError/types';
import { OverlayStoreType } from 'redux/types';

const initialState: AuthenticationErrorStoreType = {
  signUpError: '',
  loginError: '',
  resendValidationError: '',
};

export const errorsMapper: Record<string, Record<string, string>> = {
  signUp: {
    '401': 'Invalid appsumo token',
    '403': 'This email is either invalid or already taken',
    '400': 'Invalid email',
    default: 'Unknown error',
  },
  login: {
    '401': 'Invalid email or password',
    '403': "Your account isn't validated yet.",
    default: 'Unknown error',
  },
  resendValidation: {
    '404': 'Email not found',
    default: 'Unknown error',
  },
};

// Action Creators
export const authenticationErrorCleaning = () => ({ type: actionTypes.ERROR_CLEANING });

// Selectors
export const selectSignUpError = (store: OverlayStoreType) => store.authenticationError.signUpError;

export const selectLoginError = (store: OverlayStoreType) => store.authenticationError.loginError;

export const selectResendValidationError = (store: OverlayStoreType) =>
  store.authenticationError.resendValidationError;

// Reducer
export const authenticationErrorReducer = (
  state: AuthenticationErrorStoreType = initialState,
  action: any,
) => {
  switch (action.type) {
    case actionTypes.LOGIN.FAILURE:
      return {
        ...state,
        loginError: action.error,
      };
    case actionTypes.SIGNUP.FAILURE:
      return {
        ...state,
        signUpError: action.error,
      };
    case actionTypes.RESEND_VALIDATION.FAILURE:
      return {
        ...state,
        resendValidationError: action.error,
      };
    case actionTypes.ERROR_CLEANING:
      return {
        initialState,
      };
    default:
      return state;
  }
};

import React, { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styles from './TypoCard.module.scss';
import classnames from 'classnames';
import RemoveButton from 'components/Atoms/RemoveButton';
import { ClassValue } from 'classnames/types';
import {
  FrontVariableChangeTypeEnum,
  FrontVariableInputType,
  FrontVariableStateEnum,
  VariableTypographyType,
} from 'modules/stylesheet/types';
import { isShowcaseApp } from 'services/featureToggling';

interface ITypoCardProps {
  typo: VariableTypographyType;
  classNames: ClassValue;
  onTypoChange: (typoId: number, newName: string) => void;
  onTypoRemove: () => void;
  typoChange: FrontVariableInputType;
}

const TypoCard: React.FunctionComponent<ITypoCardProps> = ({
  typoChange,
  typo,
  classNames,
  onTypoRemove,
  onTypoChange,
}) => {
  const [typoNameInput, setTypoNameInput] = useState('');
  const [textInputRef, setTextInputRef] = useState<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (typo.name) {
      setTypoNameInput(typo.name);
    }
  }, [typo.name]);

  const handleTypoNameChange = (event: any) => {
    const newName = event.target.value;
    setTypoNameInput(newName);

    onTypoChange(typo.id, newName);
  };

  const handleRemoveColor = () => {
    onTypoRemove();
  };

  const onSubmit = (event: any) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      if (null !== textInputRef) {
        // @ts-ignore
        textInputRef.blur();
      }
    }
  };

  const typoHasNotSupportedSize = typo.size > 90;
  const validationState = typoChange && typoChange.validationState;
  const willBeDeleted = !!typoChange && typoChange.type === FrontVariableChangeTypeEnum.DELETE;

  return (
    <div className={classnames(classNames, styles.typoSection, willBeDeleted && styles.deletable)}>
      <div className={styles.typoBloc}>
        <div className={styles.absoluteContainer}>
          <div className={styles.typoShowcase}>
            {typoHasNotSupportedSize && (
              <div className={styles.notSupportedContainer}>
                <p className={styles.notSupportedLabel}>*scaled to fit</p>
              </div>
            )}
            <p
              className={styles.typoShowcaseContent}
              style={{
                fontSize: typoHasNotSupportedSize ? 90 : typo.size,
                fontWeight: typo.weight,
              }}
            >
              Aa
            </p>
          </div>
          {!isShowcaseApp() && (
            <RemoveButton onClick={handleRemoveColor} className={styles.removeButton} />
          )}
        </div>
      </div>
      <div className={styles.fontInfoContainer}>
        <div className={styles.typoInfos}>
          {isShowcaseApp() ? (
            <p className={styles.typoName}>{typoNameInput}</p>
          ) : (
            <TextareaAutosize
              value={typoNameInput}
              onKeyDown={onSubmit}
              spellCheck="false"
              onChange={handleTypoNameChange}
              className={classnames(styles.typoName, willBeDeleted && styles.deletable)}
              inputRef={tag => setTextInputRef(tag)}
              disabled={willBeDeleted}
              maxRows={2}
              minRows={1}
            />
          )}
          <p className={styles.errorName}>
            {validationState === FrontVariableStateEnum.INVALID_NAME_ERROR && 'Invalid typo name'}
            {validationState === FrontVariableStateEnum.DUPLICATE_NAME_ERROR && 'Name already used'}
          </p>
          <div className={styles.fontCharacteristicsItem}>
            <p className={styles.fontPropertyLabel}>FAMILY</p>
            <p className={styles.fontPropertyValue}>{typo.family}</p>
          </div>
          <div className={styles.fontCharacteristicsItem}>
            <p className={styles.fontPropertyLabel}>WEIGHT</p>
            <p className={styles.fontWeightValue}>{typo.weight}</p>
            {numericWeightToText[typo.weight] && (
              <p className={styles.fontWeightValue}>({numericWeightToText[typo.weight]})</p>
            )}
          </div>
          <div className={styles.fontCharacteristicsItem}>
            <p className={styles.fontPropertyLabel}>SIZE</p>
            <p className={styles.fontPropertyValue}>{typo.size}px</p>
          </div>
          {typo.lineHeight && (
            <div className={styles.fontCharacteristicsItem}>
              <p className={styles.fontPropertyLabel}>LINE</p>
              <p className={styles.fontPropertyValue}>{typo.lineHeight}px</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const numericWeightToText: Record<string, string> = {
  '100': 'Thin',
  '300': 'Light',
  '400': 'Regular',
  '500': 'Book',
  '600': 'SemiBold',
  '700': 'Bold',
  '800': 'ExtraBold',
};

export default TypoCard;

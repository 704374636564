import React from 'react';
import sketchExplanation from 'assets/empty-project-sketch.png';
import figmaExplanation from 'assets/empty-project-figma.png';
import adobeXDBetaIcon from 'assets/adobeXD-beta.png';
import style from './EmptyState.module.scss';
import Button from 'components/Atoms/Button';
import { DesignToolEnum, ProjectType } from 'modules/projects/types';
import { updateProjectBetaUserRequestCreator } from 'modules/projects';

export interface IEmptyProjectProps {
  onSubscribeBeta: typeof updateProjectBetaUserRequestCreator;
  project: ProjectType;
  isLoading: boolean;
}

const EmptyProjectState: React.FunctionComponent<IEmptyProjectProps> = ({
  project,
  isLoading,
  onSubscribeBeta,
}) => {
  const subscribe = () => {
    onSubscribeBeta({
      projectUuid: project.uuid,
      betaUser: true,
    });
  };

  const renderForSketch = () => (
    <>
      <img src={sketchExplanation} className={style.imageExportComponent} alt="" />
      <p className={style.description}>
        You have no components yet. Start exporting components with our{' '}
        <span className={style.sketchEmphasis}>Sketch</span> plugin.
      </p>
      <a href={process.env.REACT_APP_DOWNLOAD_SKETCH_PLUGIN} rel="noreferrer noopener">
        <Button>Get the plugin</Button>
      </a>
    </>
  );

  const renderForFigma = () => (
    <>
      <img src={figmaExplanation} className={style.figmaBeta} alt="" />
      <p className={style.description}>
        You have no components yet. Start exporting components with our{' '}
        <span className={style.figmaEmphasis}>Figma</span> plugin.
      </p>
      <a href={process.env.REACT_APP_DOWNLOAD_FIGMA_PLUGIN} rel="noreferrer noopener">
        <Button>Get the plugin</Button>
      </a>
    </>
  );

  const renderForAdobeXd = () => (
    <>
      <img alt="" className={style.imageBeta} src={adobeXDBetaIcon} />
      <p className={style.description}>
        We are still in Beta for the AdobeXD version. But good news, you can apply here to be a Beta
        tester, just tell us :)
      </p>
      <div className={style.checkContainer}>
        {!project.betaUser && (
          <Button onClick={subscribe} isLoading={isLoading} className={style.checkboxSelected}>
            Join the beta
          </Button>
        )}
        {project.betaUser && (
          <p className={style.subscribed}>You are now registered in the AdobeXd beta.</p>
        )}
      </div>
    </>
  );

  return (
    <div className={style.emptyStateComponents}>
      {project.designTool === DesignToolEnum.SKETCH && renderForSketch()}
      {project.designTool === DesignToolEnum.ADOBE_XD && renderForAdobeXd()}
      {project.designTool === DesignToolEnum.FIGMA && renderForFigma()}
    </div>
  );
};

export default EmptyProjectState;

import React, { ReactNode } from 'react';
import cx from 'classnames';
import styles from './Toaster.module.scss';

interface IToaster {
  isVisible: boolean;
  children: ReactNode;
}

const Toaster: React.FunctionComponent<IToaster> = ({ children, isVisible }) => {
  return (
    <div className={cx(styles.toaster, isVisible ? styles.show : null)}>
      <div className={styles.toasterContainer}>{children}</div>
    </div>
  );
};

export default Toaster;

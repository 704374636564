import React, { useState } from 'react';
import styles from './DropDown.module.scss';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { ReactComponent as ArrowIcon } from 'assets/arrow.svg';
import classnames from 'classnames';

type Props = {
  onChange: (value: string) => void;
  options: Record<
    string,
    {
      key: string;
      value: string;
      disabled?: boolean;
    }
  >;
  value: string | null;
  emptyMessage: string;
  placeholder: string;
  className?: ClassValue;
  isLoading: boolean;
};

const DropDown: React.FunctionComponent<Props> = ({
  className,
  emptyMessage,
  isLoading,
  value,
  options,
  onChange,
  placeholder,
}) => {
  const [isDropDownOpen, changeDropDownSate] = useState(false);
  const toggleDropdown = () => {
    changeDropDownSate(!isDropDownOpen);
  };

  const onSelectItem = (key: string, disabled: boolean) => () => {
    if (disabled) {
      return;
    }
    onChange(key);
  };

  const optionList = Object.values(options);
  const optionListIsEmpty = optionList.length === 0;

  return (
    <div className={classnames(styles.dropdown, className)} onClick={toggleDropdown}>
      <div className={styles.inputContainer}>
        {isLoading ? (
          <p className={styles.selectedContent}>...</p>
        ) : (
          <>
            <p className={styles.selectedContent}>
              {value ? options[value].value : optionListIsEmpty ? emptyMessage : placeholder}
            </p>
            {!optionListIsEmpty && (
              <ArrowIcon
                className={classNames(styles.arrow, isDropDownOpen ? styles.open : null)}
              />
            )}
          </>
        )}
      </div>
      <div
        className={classNames(styles.dropdownList)}
        style={{
          height: isDropDownOpen ? 34 * (optionList.length > 5 ? 5 : optionList.length) : 0,
        }}
      >
        {optionList.map(option => (
          <div key={option.key}>
            <p
              className={classnames(styles.item, !!option.disabled && styles.disabled)}
              onClick={onSelectItem(option.key, !!option.disabled)}
            >
              {option.value}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DropDown;

import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  cancelTeamSubscription,
  dismissReview,
  getUserInfo,
  previewUpdateSubscription,
  qualifyUser,
  createTeamSubscription,
  updatePaymentInfo,
  updateTeamSubscription,
} from 'services/api';
import { CompanyEnum, JobEnum, OverlayPlanEnum, UserType, UserStoreType } from './types';
import { ActionType, createAction, getType, Reducer } from 'typesafe-actions';
import { selectToken, withAuthentication } from 'modules/authentication';
import stripeJs from '@stripe/stripe-js';
import { closeModalCreator, openModalCreator } from 'modules/modals';
import { ModalKeysEnum } from 'modules/modals/types';
import { OverlayStoreType } from 'redux/types';
import { withLoader } from 'modules/loading';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import { delay } from 'redux-saga';
import {
  Price,
  PromotionCode,
  resetPromotionCodeRequestCreator,
  selectPromotionCode,
  selectSeatNumber,
  selectSelectedPrice,
} from 'modules/subscription';
import * as Sentry from '@sentry/react';
import { displayErrorToaster } from 'modules/apiError';
import {
  getMyTeamRequestCreator,
  getMyTeamsSuccessCreator,
  getTeamInvoicesRequestCreator,
  selectSelectedTeam,
  selectSelectedTeamUuid,
} from 'modules/teams';
import { TeamType } from 'modules/teams/types';
import { normalizeTeam } from 'services/apiNormalizer';

const initialState: UserStoreType = {
  user: {
    email: '',
    subscriptionId: null,
    currentPlan: null,
    remainingExport: null,
    paymentRecurringInterval: null,
    cancelAtPeriodEnd: false,
    currentPeriodEnd: null,
    trialStartedAt: null,
    last4: null,
    expMonth: null,
    expYear: null,
    projectOwnedCount: null,
    companyName: null,
    billingEmail: null,
    hasAlreadyLogin: false,
    hasDismissReview: false,
    hasRemainingExport: true,
    extraBillingInfo: null,
  },
  subscriptionIsFailed: false,
  subscriptionIsSuccessful: false,
  pricePreview: null,
};

// Actions Creators
export const subscribeRequestCreator = createAction('USER/SUBSCRIBE.REQUEST')<{
  paymentMethodId: string;
  companyName: string;
  billingEmail: string;
  extraInfo: string | null;
  stripe: stripeJs.Stripe;
}>();

export const previewSubscribeUpdateRequestCreator = createAction(
  'USER/PREVIEW_UPDATE_SUBSCRIPTION.REQUEST',
)();

export const previewSubscribeUpdateSuccessCreator = createAction(
  'USER/PREVIEW_UPDATE_SUBSCRIPTION.SUCCESS',
)<{
  pricePreview: number;
}>();

export const updateSubscriptionRequestCreator = createAction('USER/UPDATE_SUBSCRIPTION.REQUEST')();

export const updatePaymentMethodRequestCreator = createAction(
  'USER/UPDATE_PAYMENT_METHOD.REQUEST',
)<{
  paymentMethodId: string;
}>();

export const subscribeResetCreator = createAction('USER/SUBSCRIBE.RESET')();

export const subscribeSuccessCreator = createAction('USER/SUBSCRIBE.SUCCESS')<{
  subscriptionId: string;
}>();

export const subscribeFailedCreator = createAction('USER/SUBSCRIBE.FAILED')();

export const cancelSubscribeRequestCreator = createAction('USER/CANCEL_SUBSCRIBE.SUCCESS')();

export const updateUserRequestCreator = createAction('USER/UPDATE.REQUEST')<{
  user: UserType;
}>();

export const qualifyUserRequestCreator = createAction('USER/QUALIFY_USER.REQUEST')<{
  job: JobEnum;
  company: CompanyEnum;
}>();

export const dismissReviewRequestCreator = createAction('USER/DISMISS_REVIEW.REQUEST')();

export const meRequestCreator = createAction('USER/ME.REQUEST')();

type UserActions =
  | ActionType<typeof subscribeSuccessCreator>
  | ActionType<typeof subscribeFailedCreator>
  | ActionType<typeof subscribeResetCreator>
  | ActionType<typeof previewSubscribeUpdateSuccessCreator>
  | ActionType<typeof updateUserRequestCreator>;

// Selectors
export const isAFreeTrialUser = (store: OverlayStoreType) =>
  store.user.user.currentPlan === OverlayPlanEnum.TRIAL ||
  store.user.user.currentPlan === OverlayPlanEnum.TRIAL_EXPIRED;
export const isAFreeTrialActiveUser = (store: OverlayStoreType) =>
  store.user.user.currentPlan === OverlayPlanEnum.TRIAL;
export const isAFreeTrialExpired = (store: OverlayStoreType) =>
  store.user.user.currentPlan === OverlayPlanEnum.TRIAL_EXPIRED;
export const selectPricePreview = (store: OverlayStoreType) => store.user.pricePreview;
export const selectSubscriptionSuccessFul = (store: OverlayStoreType) =>
  store.user.subscriptionIsSuccessful;
export const selectSubscriptionFailed = (store: OverlayStoreType) =>
  store.user.subscriptionIsFailed;
export const isLimitReached = (store: OverlayStoreType) => !store.user.user.hasRemainingExport;
export const selectHasDismissReview = (store: OverlayStoreType) => store.user.user.hasDismissReview;
export const selectUserInfo = (store: OverlayStoreType) => store.user.user;

// Reducer
export const userReducer: Reducer<UserStoreType, UserActions> = (state = initialState, action) => {
  switch (action.type) {
    case getType(subscribeSuccessCreator):
      return {
        ...state,
        user: {
          ...state.user,
          subscriptionId: action.payload.subscriptionId,
        },
        subscriptionIsSuccessful: true,
      };
    case getType(previewSubscribeUpdateSuccessCreator):
      return {
        ...state,
        pricePreview: action.payload.pricePreview,
      };
    case getType(subscribeFailedCreator):
      return {
        ...state,
        subscriptionIsFailed: true,
      };
    case getType(subscribeResetCreator):
      return {
        ...state,
        subscriptionIsFailed: false,
        subscriptionIsSuccessful: false,
      };
    case getType(updateUserRequestCreator):
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.user,
        },
      };
    default:
      return state;
  }
};

// Sagas
export function* subscribeSaga(action: ReturnType<typeof subscribeRequestCreator>) {
  try {
    const token = yield select(selectToken);
    const seatNumber: number = yield select(selectSeatNumber);
    const teamUuid = yield select(selectSelectedTeamUuid);
    const selectedPrice: Price = yield select(selectSelectedPrice);
    const promotionCode: PromotionCode | null = yield select(selectPromotionCode);
    const paymentMethodId = action.payload.paymentMethodId;
    const { body: subscription }: { body: any } = yield call(
      createTeamSubscription,
      token,
      action.payload.paymentMethodId,
      action.payload.companyName,
      action.payload.billingEmail,
      selectedPrice.priceId,
      teamUuid,
      seatNumber,
      action.payload.extraInfo,
      promotionCode ? promotionCode.id : null,
    );

    yield put(resetPromotionCodeRequestCreator());
    if (subscription && subscription.status === 'active') {
      // Subscription is active, no customer actions required.
      yield put(
        subscribeSuccessCreator({
          subscriptionId: subscription.id,
        }),
      );
      yield put(getMyTeamRequestCreator({ teamUuid }));
      yield put(getTeamInvoicesRequestCreator({ teamUuid }));
      return;
    }

    let paymentIntent: any = subscription.latest_invoice.payment_intent;

    if (paymentIntent.status === 'requires_action') {
      try {
        const result = yield call(
          action.payload.stripe.confirmCardPayment,
          paymentIntent.client_secret,
          {
            payment_method: paymentMethodId,
          },
        );
        if (result.error) {
          // Start code flow to handle updating the payment details.
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc).
          yield put(subscribeFailedCreator());
        } else {
          if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
            // We wait that the webhook update the user (can be improve by push / or polling)
            yield delay(5000);
            yield put(getMyTeamRequestCreator({ teamUuid }));
            yield put(getTeamInvoicesRequestCreator({ teamUuid }));
            yield put(
              subscribeSuccessCreator({
                subscriptionId: subscription.id,
              }),
            );
          }
        }
      } catch (e) {
        yield put(subscribeFailedCreator());
      }
    }

    if (paymentIntent.status === 'requires_payment_method') {
      yield put(subscribeFailedCreator());
    }
  } catch (e) {
    yield put(
      displayErrorToaster({
        errorMessage: "An error occurred while subscribing. Your account won't be charged",
      }),
    );
    Sentry.captureException(e);
    yield put(subscribeFailedCreator());
  }
}

export function* updatePaymentMethodSaga(
  action: ReturnType<typeof updatePaymentMethodRequestCreator>,
) {
  try {
    const token = selectToken(yield select());
    const selectedTeam = selectSelectedTeam(yield select());

    if (!selectedTeam || !selectedTeam.subscriptionId || !token) {
      return;
    }

    const { body: team }: { body: TeamType } = yield call(
      updatePaymentInfo,
      token,
      selectedTeam.uuid,
      action.payload.paymentMethodId,
    );

    const { entities } = normalizeTeam(team);
    yield put(
      getMyTeamsSuccessCreator({
        team: entities.team,
        selectedTeamUuid: selectedTeam.uuid,
      }),
    );
    yield put(closeModalCreator(ModalKeysEnum.UPDATE_PAYMENT_METHOD)());
  } catch (e) {
    yield put(
      displayErrorToaster({
        errorMessage: 'An error occurred while updating your payment method.',
      }),
    );
    Sentry.captureException(e);
    yield put(subscribeFailedCreator());
  }
}

export function* meSaga(action: ReturnType<typeof meRequestCreator>) {
  try {
    const token = yield select(selectToken);
    const { body }: { body: UserType } = yield call(getUserInfo, token);
    yield put(updateUserRequestCreator({ user: body }));
    Sentry.setUser({ email: body.email });
    Sentry.addBreadcrumb({
      category: 'auth',
      message: 'User logged in ' + body.email,
      level: Sentry.Severity.Info,
    });

    if (!body.hasAlreadyLogin) {
      yield put(openModalCreator(ModalKeysEnum.USER_QUALIFICATION)());
      return;
    }
  } catch (e) {
    yield put(
      displayErrorToaster({ errorMessage: 'An error occurred while getting your user info.' }),
    );
    Sentry.captureException(e);
  }
}

export function* qualifyUserSaga(action: ReturnType<typeof qualifyUserRequestCreator>) {
  try {
    const token = yield select(selectToken);
    const { body }: { body: UserType } = yield call(
      qualifyUser,
      token,
      action.payload.job,
      action.payload.company,
    );
    yield put(updateUserRequestCreator({ user: body }));
    yield put(closeModalCreator(ModalKeysEnum.USER_QUALIFICATION)());
  } catch (e) {
    yield put(
      displayErrorToaster({ errorMessage: 'An error occurred while sending your user info.' }),
    );
    yield put(closeModalCreator(ModalKeysEnum.USER_QUALIFICATION)());
    Sentry.captureException(e);
  }
}

export function* dismissReviewSaga(action: ReturnType<typeof dismissReviewRequestCreator>) {
  try {
    const token = yield select(selectToken);
    const { body }: { body: UserType } = yield call(dismissReview, token);
    yield put(updateUserRequestCreator({ user: body }));
  } catch (e) {
    yield put(
      displayErrorToaster({
        errorMessage: 'An error occurred while sending your review preferences.',
      }),
    );
    Sentry.captureException(e);
  }
}

export function* cancelSubscriptionSaga(action: ReturnType<typeof cancelSubscribeRequestCreator>) {
  try {
    const token = selectToken(yield select());
    const selectedTeam = selectSelectedTeam(yield select());

    if (!selectedTeam || !selectedTeam.subscriptionId || !token) {
      return;
    }

    const { body }: { body: TeamType } = yield call(
      cancelTeamSubscription,
      token,
      selectedTeam.uuid,
      selectedTeam.subscriptionId,
    );
    const { entities } = normalizeTeam(body);
    yield put(
      getMyTeamsSuccessCreator({
        team: entities.team,
        selectedTeamUuid: selectedTeam.uuid,
      }),
    );
    yield put(closeModalCreator(ModalKeysEnum.CANCEL_SUBSCRIPTION)());
  } catch (e) {
    yield put(
      displayErrorToaster({ errorMessage: 'An error occurred while canceling your subscription.' }),
    );
    Sentry.captureException(e);
  }
}

export function* previewUpdateSubscriptionSaga(
  action: ReturnType<typeof previewSubscribeUpdateRequestCreator>,
) {
  try {
    const token = selectToken(yield select());
    const price = selectSelectedPrice(yield select());
    const team = selectSelectedTeam(yield select());
    const seatNumber = selectSeatNumber(yield select());
    const promotionCode = selectPromotionCode(yield select());

    if (!token || !team) return;

    const promotionCodeId = promotionCode ? promotionCode.id : null;

    const { body } = yield call(
      previewUpdateSubscription,
      token,
      price.priceId,
      team.uuid,
      seatNumber,
      promotionCodeId,
    );
    yield put(previewSubscribeUpdateSuccessCreator({ pricePreview: Math.round(body.total) / 100 }));
  } catch (e) {
    Sentry.captureException(e);
  }
}

export function* updateSubscriptionSaga(
  action: ReturnType<typeof updateSubscriptionRequestCreator>,
) {
  try {
    const token = yield select(selectToken);
    const price = selectSelectedPrice(yield select());
    const seatNumber = selectSeatNumber(yield select());
    const selectedTeam = selectSelectedTeam(yield select());

    if (!token || !selectedTeam) return;

    const { body: subscription } = yield call(
      updateTeamSubscription,
      token,
      selectedTeam.uuid,
      price.priceId,
      seatNumber,
    );
    yield put(
      subscribeSuccessCreator({
        subscriptionId: subscription.id,
      }),
    );
    yield put(getMyTeamRequestCreator({ teamUuid: selectedTeam.uuid }));
    yield put(getTeamInvoicesRequestCreator({ teamUuid: selectedTeam.uuid }));
  } catch (e) {
    yield put(
      displayErrorToaster({
        errorMessage: 'An error occurred while updating your subscription. Try later',
      }),
    );
    Sentry.captureException(e);
  }
}

// Saga Watchers
function* watchSubscribe() {
  yield takeLatest(
    getType(subscribeRequestCreator),
    withLoader(withAuthentication(subscribeSaga), SimpleLoadingKeysEnum.subscribe),
  );
}

function* watchUpdatePaymentMethod() {
  yield takeLatest(
    getType(updatePaymentMethodRequestCreator),
    withLoader(
      withAuthentication(updatePaymentMethodSaga),
      SimpleLoadingKeysEnum.updatePaymentMethod,
    ),
  );
}

function* watchCancelSubscribe() {
  yield takeLatest(
    getType(cancelSubscribeRequestCreator),
    withLoader(
      withAuthentication(cancelSubscriptionSaga),
      SimpleLoadingKeysEnum.cancelSubscription,
    ),
  );
}

function* watchPreviewUpdateSubscription() {
  yield takeLatest(
    getType(previewSubscribeUpdateRequestCreator),
    withLoader(
      withAuthentication(previewUpdateSubscriptionSaga),
      SimpleLoadingKeysEnum.previewUpdateSubscription,
    ),
  );
}

function* watchUpdateSubscription() {
  yield takeLatest(
    getType(updateSubscriptionRequestCreator),
    withLoader(
      withAuthentication(updateSubscriptionSaga),
      SimpleLoadingKeysEnum.updateSubscription,
    ),
  );
}

function* watchMe() {
  yield takeLatest(
    getType(meRequestCreator),
    withLoader(withAuthentication(meSaga), SimpleLoadingKeysEnum.getUserInfo),
  );
}

function* watchQualifyUser() {
  yield takeLatest(
    getType(qualifyUserRequestCreator),
    withLoader(withAuthentication(qualifyUserSaga), SimpleLoadingKeysEnum.qualifyUser),
  );
}

function* watchDismissReview() {
  yield takeLatest(
    getType(dismissReviewRequestCreator),
    withLoader(withAuthentication(dismissReviewSaga), SimpleLoadingKeysEnum.dismissReview),
  );
}

// Saga export
export function* watchUserSagas() {
  yield fork(watchSubscribe);
  yield fork(watchQualifyUser);
  yield fork(watchUpdatePaymentMethod);
  yield fork(watchPreviewUpdateSubscription);
  yield fork(watchUpdateSubscription);
  yield fork(watchCancelSubscribe);
  yield fork(watchMe);
  yield fork(watchDismissReview);
}

import React, { useCallback, useEffect } from 'react';
import Button from 'components/Atoms/Button';

import styles from './StylesheetSection.module.scss';

import ColorCard from './ColorCard.component';
import TypoCard from './TypoCard.component';
import {
  FrontVariableStateEnum,
  FrontVariableInputType,
  StylesheetType,
  VariableColorType,
  VariableTypographyType,
} from 'modules/stylesheet/types';
import {
  changeColorNameCreator,
  changeTypoNameCreator,
  removeColorCreator,
  removeTypoCreator,
} from 'modules/stylesheet';
import Toaster from 'components/Atoms/Toaster';

interface IStylesheetSection {
  stylesheet: StylesheetType | null;
  colors: VariableColorType[];
  typos: VariableTypographyType[];
  textColors: VariableColorType[];
  colorChanges: Record<string, FrontVariableInputType>;
  typoChanges: Record<string, FrontVariableInputType>;
  removeTypo: typeof removeTypoCreator;
  removeColor: typeof removeColorCreator;
  changeColorName: typeof changeColorNameCreator;
  changeTypoName: typeof changeTypoNameCreator;
  updateStylesheet: () => void;
  resetStylesheetChanges: () => void;
  isCommitStylesheetChangesSuccessful: boolean;
  stylesheetHasInvalidChanges: boolean;
  isLoading: boolean;
}

const StylesheetSection: React.FunctionComponent<IStylesheetSection> = ({
  colors,
  textColors,
  stylesheet,
  colorChanges,
  typoChanges,
  removeTypo,
  removeColor,
  typos,
  changeColorName,
  changeTypoName,
  isCommitStylesheetChangesSuccessful,
  stylesheetHasInvalidChanges,
  updateStylesheet,
  resetStylesheetChanges,
  isLoading,
}) => {
  const onChangeColorNameProxy = useCallback(
    (colorId: number, newName: string) => {
      if (null === stylesheet) return null;
      changeColorName({ stylesheetUuid: stylesheet.uuid, colorId, newName });
    },
    [stylesheet],
  );

  const onChangeTypoNameProxy = useCallback(
    (typoId: number, newName: string) => {
      if (null === stylesheet) return null;
      changeTypoName({ stylesheetUuid: stylesheet.uuid, typoId, newName });
    },
    [stylesheet],
  );

  const onRemoveColorProxy = (colorId: number) => () => removeColor({ colorId });
  const onRemoveTypoProxy = (typoId: number) => () => removeTypo({ typoId });
  const stylesheetHasChanges =
    [...Object.values(typoChanges), ...Object.values(colorChanges)].filter(
      change => change.validationState !== FrontVariableStateEnum.NOT_MODIFIED,
    ).length > 0;

  const stylesheetHasColor = colors.length > 0;
  const stylesheetHasTextColor = textColors.length > 0;
  const stylesheetHasTypography = typos.length > 0;

  // Remove changes before leaving the page
  useEffect(() => {
    return () => {
      resetStylesheetChanges();
    };
  }, []);

  return (
    <div className={styles.stylesheetContainer}>
      {stylesheetHasColor && (
        <>
          <h3 className={styles.propertyTitle}>Colors</h3>
          <div className={styles.propertyContainer}>
            {colors.map(color => (
              <ColorCard
                classNames={styles.card}
                onColorChange={onChangeColorNameProxy}
                onColorRemove={onRemoveColorProxy(color.id)}
                colorChange={colorChanges[color.id]}
                color={color}
                key={color.id}
              />
            ))}
          </div>
        </>
      )}
      {stylesheetHasTextColor && (
        <>
          <h3 className={styles.propertyTitle}>Text Colors</h3>
          <div className={styles.propertyContainer}>
            {textColors.map(color => (
              <ColorCard
                classNames={styles.card}
                color={color}
                colorChange={colorChanges[color.id]}
                onColorChange={onChangeColorNameProxy}
                onColorRemove={onRemoveColorProxy(color.id)}
                key={color.id}
              />
            ))}
          </div>
        </>
      )}
      {stylesheetHasTypography && (
        <>
          <h3 className={styles.propertyTitle}>Typographies</h3>
          <div className={styles.propertyContainer}>
            {typos.map(typo => (
              <TypoCard
                classNames={styles.card}
                typo={typo}
                key={typo.id}
                typoChange={typoChanges[typo.id]}
                onTypoChange={onChangeTypoNameProxy}
                onTypoRemove={onRemoveTypoProxy(typo.id)}
              />
            ))}
          </div>
        </>
      )}
      <Toaster isVisible={stylesheetHasChanges}>
        <>
          <div className={styles.descriptionBloc}>
            <p className={styles.saveInformations}>
              Your changes will be implemented in the components code
            </p>
            <p className={styles.disclaimer}>To dismiss your changes, please reload your page.</p>
          </div>
          <Button
            type="submit"
            onClick={updateStylesheet}
            isDisabled={stylesheetHasInvalidChanges}
            isLoading={isLoading}
          >
            Save
          </Button>
        </>
      </Toaster>
      <Toaster isVisible={isCommitStylesheetChangesSuccessful}>
        <p className={styles.textToaster}>Your changes are saved!</p>
      </Toaster>
    </div>
  );
};

export default StylesheetSection;

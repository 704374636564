import React, { ReactNode } from 'react';
import cx from 'classnames';
import styles from './UserBadge.module.scss';

type Props = {
  variant?: number;
  title: string;
  children?: ReactNode;
};

class UserBadge extends React.Component<Props> {
  get className(): string {
    const classes = [styles.badge];
    switch (this.props.variant) {
      case 0:
        classes.push(styles.background1);
        break;
      case 1:
        classes.push(styles.background2);
        break;
      case 2:
        classes.push(styles.background3);
        break;
      default:
    }
    return cx(...classes);
  }

  render() {
    return (
      <div className={this.className} title={this.props.title}>
        <div className={styles.text}>{this.props.children}</div>
      </div>
    );
  }
}

export default UserBadge;

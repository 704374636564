export type LoadingStoreType = Record<SimpleLoadingKeysEnum, boolean> &
  Record<NestedLoadingKeysEnum, Record<string, boolean>>;

export enum SimpleLoadingKeysEnum {
  wideLoader = 'wideLoader',
  createCategory = 'createCategory',
  components = 'components',
  compilingFragment = 'compilingFragment',
  deleteComponent = 'deleteComponent',
  refreshComponent = 'refreshComponent',
  getComponent = 'getComponent',
  changeVariant = 'changeVariant',
  getComponentFonts = 'getComponentFonts',
  getComponentInstance = 'getComponentInstance',
  updateComponentName = 'updateComponentName',
  updateComponentInstanceName = 'updateComponentInstanceName',
  updateComponentInstanceProps = 'updateComponentInstanceProps',
  createComponentReview = 'createComponentReview',
  updateComponentReview = 'updateComponentReview',
  createReview = 'createReview',
  updateReview = 'updateReview',
  updateComponentProps = 'updateComponentProps',
  deleteProject = 'deleteProject',
  getProjects = 'getProjects',
  getProject = 'getProject',
  createProject = 'createProject',
  getStyleSheet = 'getStyleSheet',
  updateStyleSheet = 'updateStyleSheet',
  deleteStylesheetProperty = 'deleteStylesheetProperty',
  createStylesheetProperty = 'createStylesheetProperty',
  getUserInfo = 'getUserInfo',
  validatePromotionCode = 'validatePromotionCode',
  qualifyUser = 'qualifyUser',
  dismissReview = 'dismissReview',
  getTeams = 'getTeams',
  createTeam = 'createTeam',
  deleteTeam = 'deleteTeam',
  updateTeam = 'updateTeam',
  addTeamContributor = 'addTeamContributor',
  removeTeamContributor = 'removeTeamContributor',
  cancelSubscription = 'cancelSubscription',
  updateSubscription = 'updateSubscription',
  previewUpdateSubscription = 'previewUpdateSubscription',
  subscribe = 'subscribe',
  updatePaymentMethod = 'updatePaymentMethod',
  updateProjectSettings = 'updateProjectSettings',
  loggingIn = 'loggingIn',
  signingIn = 'signingIn',
  forgotPassword = 'forgotPassword',
  resetPassword = 'resetPassword',
  validateResetPasswordToken = 'validateResetPasswordToken',
  inviteUserToProject = 'inviteUserToProject',
  validateAccount = 'validateAccount',
  resendValidation = 'resendValidation',
  subscribeToBeta = 'subscribeToBeta',
}

export enum NestedLoadingKeysEnum {
  removeInviteFromProject = 'removeInviteFromProject',
}

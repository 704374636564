import { connect } from 'react-redux';
import AnonymousRoute from './AnonymousRoute.component';
import { isAuthenticated } from 'modules/authentication';
import { OverlayStoreType } from 'redux/types';

const mapStateToProps = (state: OverlayStoreType) => ({
  isAuthenticated: isAuthenticated(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AnonymousRoute);

import React from 'react';
import styles from './ProjectContributor.module.scss';
import { ProjectContributorType } from 'modules/projects/types';
import { UserType } from 'modules/user';
import { removeProjectContributorRequestCreator } from 'modules/projects';

interface IProjectContributorProps {
  projectUuid: string;
  me: UserType;
  contributor: ProjectContributorType;
  removeProjectContributor: typeof removeProjectContributorRequestCreator;
}

const ProjectContributor: React.FunctionComponent<IProjectContributorProps> = ({
  projectUuid,
  me,
  contributor,
  removeProjectContributor,
}) => {
  return (
    <div className={styles.memberItem}>
      <div className={styles.nameContainer}>
        <div className={styles.memberBadge}>
          <p className={styles.memberInitial}>{contributor.email.slice(0, 1)}</p>
        </div>
        <div className={styles.memberInfo}>
          <div className={styles.emailContainer}>
            <p className={styles.memberEmail}>
              {contributor.email}
              {me.email === contributor.email && ' (You)'}
            </p>
          </div>
          <p className={styles.memberRole}>{contributor.role}</p>
        </div>
      </div>
      {me.email !== contributor.email && (
        <p
          className={styles.buttonRemoveButton}
          onClick={() =>
            removeProjectContributor({ projectUuid, projectContributorUuid: contributor.uuid })
          }
        >
          Remove
        </p>
      )}
    </div>
  );
};

export default ProjectContributor;

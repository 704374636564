import React from 'react';
import style from './OverlayWideLoader.module.scss';
import classnames from 'classnames';

interface IWideLoaderProps {
  isLoading: boolean;
}

const OverlayWideLoader: React.FunctionComponent<IWideLoaderProps> = ({ isLoading }) => {
  return (
    <div className={classnames(style.slider, isLoading && style.loading)}>
      <div className={style.line} />
      <div className={classnames(style.subline, style.inc)} />
      <div className={classnames(style.subline, style.dec)} />
    </div>
  );
};

export default OverlayWideLoader;

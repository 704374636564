import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from 'components/Atoms/Button';
import styles from './ConfirmDeleteDialog.module.scss';
import { ReactComponent as TrashIcon } from 'assets/trash.svg';

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  onConfirmation?: () => void;
  onCloseModal: () => void;
  children?: React.ReactNode;
  cancelLabel?: string;
  confirmLabel?: string;
};

const ConfirmDeleteDialog: React.FunctionComponent<Props> = ({
  isOpen,
  isLoading,
  onCloseModal,
  onConfirmation,
  children,
  cancelLabel,
  confirmLabel,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: styles.dialogContainer }}
    >
      <div className={styles.contentContainer}>
        <div className={styles.titleContainer}>
          <TrashIcon className={styles.trashIcon} />
          {children}
        </div>
        <div className={styles.actionsContainer}>
          {!onConfirmation && (
            <div className={styles.okButton}>
              <Button onClick={onCloseModal}>Ok</Button>
            </div>
          )}
          {!!onConfirmation && (
            <>
              <Button
                isLoading={isLoading}
                className={styles.declineButton}
                onClick={onCloseModal}
                theme={'secondary'}
              >
                {cancelLabel ? cancelLabel : 'No'}
              </Button>
              <Button isLoading={isLoading} onClick={onConfirmation}>
                {confirmLabel ? confirmLabel : 'Yes'}
              </Button>
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;

import React from 'react';
import { match } from 'react-router-dom';
import ProjectSettings from 'components/Molecules/ProjectSettings';
import ProjectDetailLayout from 'components/Layout/ProjectDetailLayout';
import styles from './Settings.module.scss';
import { ProjectType } from 'modules/projects/types';
import { ProjectUuidMatchParams } from 'src/routes';

export interface ISettingsProps {
  project: ProjectType;
  projectUuid: string;
  match: match<ProjectUuidMatchParams>;
}

const Settings: React.FunctionComponent<ISettingsProps> = ({ project, projectUuid }) => {
  return (
    <ProjectDetailLayout projectId={projectUuid}>
      {project && (
        <div className={styles.settingsSection}>
          <ProjectSettings projectId={project.uuid} />
        </div>
      )}
    </ProjectDetailLayout>
  );
};

export default Settings;
